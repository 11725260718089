import styles from 'pages/Patient/FAQ/FAQ.module.scss';
import useBuiltInDocs from 'app/hooks/useBuiltInDocs';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { patientsUrls, providersUrls } from 'app/routing';
import { Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import CollapsableBlock from 'components/CollapsableBlock/CollapsableBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Heading } from 'components/Typography';
import FAQItem from 'pages/Patient/FAQ/components/FAQItem';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';

const FAQ: React.FC<Page> = ({ setPageHeader }) => {
  const { docs } = useBuiltInDocs();
  const sectionTitles = useMemo(() => [ 'Clinical Genetic Testing', 'Ancestry-aware PRS Report', 'Sample Collection Protocol' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  const sampleReport = useMemo(() => docs.find(doc => doc.documentType === 'FAQ_Clinical_Genetic_Testing'), [ docs ]);
  const prsReport = useMemo(() => docs.find(doc => doc.documentType === 'FAQ_PRS_Report'), [ docs ]);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title="Clinical Genetic Testing Questions" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.faq}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.faq__section}>

          <Heading variant="H1">Clinical Genetic Testing Questions</Heading>

          <CollapsableBlock title="What is genetic testing used for?">
            <Body variant="L">Genetic testing uncovers changes in chromosomes, genes, and proteins, used for a variety of purposes, including:</Body>

            <ol className={styles.faq__list}>
              <li>
                <b>Diagnosing Genetic Disorders:</b> testing can confirm or rule out a suspected genetic condition, helping to diagnose disorders such as
                cystic fibrosis, sickle cell anemia, or Huntington&apos;s disease.
              </li>
              <li>
                <b>Carrier Testing:</b> determines whether a person carries an abnormal gene for a genetic disorder that they could pass on to their children,
                even if they don&apos;t have the condition themselves.
              </li>
              <li>
                <b>Prenatal Testing:</b> During pregnancy, genetic tests can screen for certain genetic conditions in a developing fetus,
                such as Down syndrome or spina bifida.
              </li>
              <li>
                <b>Newborn Screening:</b> Shortly after birth, newborns are often screened for genetic disorders that could affect their health early in life.
                Early detection allows for prompt treatment.
              </li>
              <li>
                <b>Pharmacogenomics:</b> This form of genetic testing helps to understand how an individual&apos;s genetic makeup affects
                their response to certain medications, enabling more personalized and effective treatments.
              </li>
              <li>
                <b>Predictive and Presymptomatic Testing:</b> These tests identify genetic mutations that increase a person’s risk of developing
                certain conditions, such as certain types of cancer or Alzheimer&apos;s disease, before symptoms appear.
              </li>
              <li>
                <b>Ancestry and Personal Genomics:</b> Some genetic tests provide information about a person&apos;s ancestry, ethnicity,
                and even certain inherited traits, though these are generally for personal interest rather than medical reasons.
              </li>
            </ol>
          </CollapsableBlock>

          <CollapsableBlock title="How reliable are the results?">
            <Body variant="L" style={{ marginBottom: 10 }}>
              Based on validation study results, the test platform examines more than 95% of the targeted regions with analytical sensitivity of above 99%.
              Our assay achieves {'>'}99% specificity for point mutations.
            </Body>

            <Body variant="L" style={{ marginBottom: 10 }}>
              However, genetic testing is one of several considerations (such as lifestyle and co-morbidity) used by physicians to help with a
              diagnosis and treatment plan, but it is not a diagnosis itself.
            </Body>

            <Body variant="L" style={{ marginBottom: 36 }}>
              The laboratory performing the assay is regulated under the Clinical Laboratory Improvement Act (CLIA) as qualified to perform
              high-complexity clinical tests (CLIA ID:10D2252757). This test is used for clinical purposes.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="How do I interpret my genetic test results?">
            <Body variant="L" style={{ marginBottom: 10 }}>
              Interpreting genetic test results can be complex and involves an in depth understanding of genomics. Therefore, your Physician will help you
              navigate your report and help you determine what implications said results can have on your health, your family, and potential future risks.
            </Body>

            <Body variant="L" style={{ marginBottom: 33 }}>
              As an included feature, we also offer optional Genetic Counseling services should your physician find it necessary for your particular case.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="On PGx Tests, what does “strength of the recommendations” mean?">
            <Body variant="L" style={{ marginBottom: 10 }}>
              Results in the PGx report are a conversion from the genotype to phenotype leveraging the results from many properly designed scientific studies
              and summarized in the&nbsp;
              <Link to="https://cpicpgx.org/guidelines/" target="_blank" rel="noopener noreferrer">CPIC guidelines</Link>
              . The Clinical Pharmacogenetics Implementation Consortium (CPIC) assigns levels to the evidence linking
              genotype to phenotype. The literature-based evidence summarized in each guideline’s supplement is graded on a scale of high, moderate and weak,
              defined as follows:
            </Body>

            <Body variant="L" style={{ marginBottom: 19 }}>
              As an included feature, we also offer optional Genetic Counseling services should your physician find it necessary for your particular case.
            </Body>

            <FAQItem icon="up" title="Standard Precaution" text="Evidence includes consistent results from well-designed, well-conducted studies." />

            <FAQItem
              icon="menu-circle"
              title="Use with Caution"
              text="Evidence is sufficient to determine effects, but the strength of the evidence is limited by the number, quality or consistency of the individual studies, generalizability to routine practice, or indirect nature of evidence."
            />

            <FAQItem
              icon="down"
              title="Consider Alternatives"
              text="Evidence is insufficient to assess the effects on health outcomes because of limited number or power of studies, important flaws in their design or conduct, gaps in the chain of evidence, or lack of information."
            />
          </CollapsableBlock>

          <CollapsableBlock title="What do genetic counselors do?">
            <Body variant="L" style={{ marginBottom: 10 }}>
              Genetic counselors are healthcare professionals, and medical genetics specialists, who are trained to guide and support individuals
              and families who are dealing with genetic conditions or considering genetic testing.
              They can help you navigate intricate genetic concepts, assist you in understanding the implications of
              genetic test results and analyze family history to assess disease risk.
            </Body>

            <Body variant="L" style={{ marginBottom: 19 }}>
              After your results become available, with your Physician’s guidance you may schedule a genetic counseling visit with our partners:&nbsp;
              <Link to="https://dnavisit.com/about-us" target="_blank" rel="noopener noreferrer">DNAvisit</Link>.
              Our partners offer robust counseling expertise including Cancer Genetics, Direct-to-consumer Genetics, Pharmacogenomics,
              Reproductive Genetics, Cardiac Genetics, Adult and Pediatric Genetics, Neurogenetics, Whole Genome and Exome Sequencing, and more.
              Please note that our genetic counselors do not provide counseling for PRS report analysis.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="How much will genetic counseling cost me?">
            <Body variant="L" style={{ marginBottom: 10 }}>
              There is no cost to the patient for genetic counseling provided through a credentialed Galatea Bio genetic counselor.
              Patient’s are also encouraged to discuss genetic counseling referrals with their physician.
              Your physician may also recommend counseling if they find it medically necessary.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="How long does it take to get my results?">
            <Body variant="L" style={{ marginBottom: 16 }}>
              Once Galatea Bio receives your sample, on average your healthcare provider will receive the results in:
            </Body>

            <FAQItem icon="clock" title="Panel testing" text="15-30 Calendar days" />

            <FAQItem icon="clock" title="Whole Exome testing" text="3-4 weeks on average" />

            <FAQItem icon="clock" title="Whole Genome testing" text="3-4 weeks on average" />

            <Body variant="L" style={{ marginBottom: 16 }}>
              Your physician has access to view where your sample is in the testing process.
              You will also be emailed your results once your doctor has reviewed and released them.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="How do I discuss my results with my family?">
            <Body variant="L" style={{ marginBottom: 16 }}>
              It is strongly recommended that when reviewing your genetic test results with your provider, you discuss not only your treatment plan
              but how you can share this information with your family. Your doctor may help facilitate those discussions or they may refer you to
              a genetic counselor who can assist discussions with your family and the results,
              as well as the potential clinical implications for your relatives.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="How can I order from Galatea Bio’s catalog?">
            <Body variant="L">
              Our tests and reports need to be ordered by a licensed physician.
              If you are interested in genetic testing with Galatea Bio please encourage your doctor to&nbsp;
              <Link to={providersUrls.howItWorksAccessForm}>fill out this form</Link>&nbsp;
              and a specialist from our team will onboard them to our platform to start ordering tests through our catalog.
            </Body>

            {sampleReport && (
              <div className={cn(styles.faq__download, styles.faq__download_nested)} data-cy="sample-report">
                <Link to={sampleReport.documentUrl} target="_blank" rel="noopener noreferrer">
                  <Button size="L">Download Sample Report</Button>
                </Link>
              </div>
            )}
          </CollapsableBlock>

        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.faq__section}>

          <Heading variant="H1">Ancestry-aware Polygenic Risk Scores Report</Heading>

          <CollapsableBlock title="What is an aaPRS Report used for?">
            <Body variant="L" style={{ marginBottom: 11 }}>
              Polygenic Risk Scores (PRS) are used to estimate an individual’s genetic predisposition to certain diseases or traits based on
              the combined effects of multiple genetic variants. They offer valuable insights into an individual’s genetic risk for complex diseases,
              aiding in more precise risk prediction, prevention, and personalized healthcare strategies.
            </Body>

            <Body variant="L" style={{ marginBottom: 16 }}>
              Galatea Bio’s ancestry-aware Polygenic Risk Scores (PRS) Report enhances genetic risk prediction by incorporating information about your ancestry,
              thus improving accuracy and applicability for your unique genetic composition. By including genetic variants that are relevant
              to different ancestral groups and using statistical methods to account for population structure,
              ancestry-aware PRS offer more personalized and precise risk evaluations.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="How long does it take to gain access to my PRS Report?">
            <Body variant="L" style={{ marginBottom: 16 }}>
              Once Galatea Bio receives your sample, on average your healthcare provider will receive you ancestry-aware Polygenic Risk Scores Report
              within 4 weeks on average
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="Will my insurance cover the cost of my PRS Report?">
            <Body variant="L">
              Most insurances will not cover PRS analyses. Please consult with your Physician on what your final out-of-pocket cost
              will look like for your report.
            </Body>
          </CollapsableBlock>

          {prsReport && (
            <div className={styles.faq__download} data-cy="prs-sample-report">
              <Link to={prsReport.documentUrl} target="_blank" rel="noopener noreferrer">
                <Button size="L">Download Sample Report</Button>
              </Link>

              <Body variant="M">
                Did you know that you can also explore your genetic heritage with our World Ancestry Report?<br />
                Find out more information on this&nbsp;
                <Link to="https://sequencing.com/marketplace/world-ancestry-report" target="_blank" rel="noopener noreferrer">non-clinical report</Link>&nbsp;
                sold directly via sequencing.com
              </Body>
            </div>
          )}

        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.faq__section}>

          <Heading variant="H1">Sample Collection Protocol</Heading>

          <CollapsableBlock title="What does it mean if my sample “fails” quality control?">
            <Body variant="L" style={{ marginBottom: 11 }}>
              Sample quality control failure may occur due to a variety of reasons. The most likely cause is insufficient DNA
              due to an improperly collected buccal swab. Another source of quality control failure may be due to contaminating DNA.
              This can occur if food was consumed too close to the time of collection. In any of the above cases, the laboratory will
              communicate with the physician whether a fresh sample needs to be collected. This re-processing may impact turnaround time.
            </Body>
          </CollapsableBlock>

          <CollapsableBlock title="What does it mean that there is insufficient material for DNA extraction?">
            <Body variant="L" style={{ marginBottom: 11 }}>
              The assay does not require large amounts of DNA; however, if an improper buccal swab is collected there may not be enough cells
              to isolate sufficient DNA for genetic testing. The laboratory may be able to conduct additional extractions on the existing
              sample or may request a fresh buccal swab.
            </Body>

            <Body variant="L">
              If you follow our&nbsp;
              <Link to={patientsUrls.submitSample} target="_blank" rel="noopener noreferrer">collection protocol</Link>&nbsp;
              to the tee, you shouldn’t have any issues collecting sufficient DNA for your test.
            </Body>
          </CollapsableBlock>
        </div>
      </PageWithSidebar>
    </>
  );
};

export default FAQ;
