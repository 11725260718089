import { postSubmitFormRequest } from 'api/Hubspot/api';
import { HubspotFormField } from 'api/Hubspot/types';
import { getHubspotFields } from 'components/Forms/helpers';
import { AccessFormValues } from 'components/Forms/AccessForm';
import { EventFormValues } from 'components/Forms/EventForm';

import { SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';

const useSubmitForm = (url: string) => {
  const [ isError, setIsError ] = useState<boolean>(false);
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => setIsSuccess(false), 3000);

      return () => clearTimeout(timer);
    }
  }, [ isSuccess ]);

  const resetState = () => {
    setIsError(false);
    setIsSuccess(false);
    setIsLoading(false);
  };

  const submitForm: SubmitHandler<AccessFormValues | EventFormValues> = (data) => {
    setIsError(false);
    setIsSuccess(false);
    setIsLoading(true);

    const fields: HubspotFormField[] = getHubspotFields(data);

    postSubmitFormRequest(url, fields)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { submitForm, isError, isSuccess, isLoading, resetState };
};

export default useSubmitForm;
