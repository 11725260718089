import styles from 'pages/Provider/Resources/Resources.module.scss';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { providersUrls } from 'app/routing';
import { Page } from 'app/types/ProductCatalog';
import DocumentsList from 'components/DocumentsList/DocumentsList';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Heading } from 'components/Typography';
import useResources from 'pages/Provider/Resources/hooks/useResources';

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Resources: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const { fetchResources, resources, isLoading } = useResources();
  const sectionTitles = useMemo(() => [ 'Physician Guides' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  useEffect(() => {
    const insuranceItem = sidebarItems.find(i => i.text === t('menuItems.insurance'));

    if (!insuranceItem) {
      sidebarItems.push({ text: t('menuItems.insurance'), inView: false, link: providersUrls.insurance });
    }
  }, [ sidebarItems, t ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchResources(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchResources ]);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title="Resources" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.resources}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref}>

          <Heading variant="H3" className={styles.resources__title}>{sectionTitles[0]}</Heading>

          <DocumentsList documents={resources.guides} />
        </div>
      </PageWithSidebar>
    </>
  );
};

export default Resources;
