import { getBuiltInDocsRequest } from 'api/Documents/api';
import { Document } from 'api/Documents/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useEffect, useState } from 'react';

const useBuiltInDocs = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ error, setError ] = useState<string>();
  const [ docs, setDocs ] = useState<Document[]>([]);

  const fetchDocs = useCallback((abortController: AbortController) => {
    setError(undefined);

    getBuiltInDocsRequest(abortController)
      .then(({ data }) => {
        setDocs(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    fetchDocs(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchDocs ]);

  return { docs, isLoading, error, fetchDocs };
};

export default useBuiltInDocs;
