const listUrl = (sortBy: string | null, search: string, url: string) => {
  return [
    `${url}?`,
    sortBy && `&tags=${sortBy}`,
    search && `&search=${search}`,
  ].join('');
};

const publicationsUrl = 'publications/';
const publicationsListUrl = (sortBy: string | null, search: string) => listUrl(sortBy, search, publicationsUrl);
const publicationsDetailUrl = (slug: string) => `${publicationsUrl}${slug}/`;

const newsUrl = 'news/';
const newsListUrl = (sortBy: string | null, search: string) => listUrl(sortBy, search, newsUrl);
const newsDetailUrl = (slug: string) => `${newsUrl}${slug}/`;

export default { publicationsListUrl, publicationsDetailUrl, newsListUrl, newsDetailUrl };
