import { getCertificatesRequest } from 'api/Certificates/api';
import { Certificate } from 'api/Certificates/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useCertificates = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ certificates, setCertificates ] = useState<Certificate[]>([]);

  const fetchCertificates = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getCertificatesRequest(abortController)
      .then(({ data }) => {
        setCertificates(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { certificates, isLoading, error, fetchCertificates };
};

export default useCertificates;
