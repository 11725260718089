import styles from 'pages/GalateaDifference/About/About.module.scss';
import useEvents from 'app/hooks/useEvents';
import useNews from 'app/hooks/useNews';
import usePublications from 'app/hooks/usePublications';
import { Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import AboutUs from 'pages/GalateaDifference/About/components/AboutUs/AboutUs';
import LatestEvents from 'pages/GalateaDifference/About/components/LatestEvents/LatestEvents';
import OurNews from 'pages/GalateaDifference/About/components/OurNews/OurNews';
import OurPublications from 'pages/GalateaDifference/About/components/OurPublications/OurPublications';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const About: React.FC<Page> = ({ setPageHeader }) => {
  const { fetchNews, news, isLoading: showNewsLoader } = useNews();
  const { fetchPublications, publications, isLoading: showPubsLoader } = usePublications();
  const { fetchEvents, events, isLoading: showEventsLoader } = useEvents();

  useEffect(() => setPageHeader(undefined));

  useEffect(() => {
    const abortController = new AbortController();
    fetchNews('', '', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchNews ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchPublications('', '', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchPublications ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchEvents('', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchEvents ]);

  return (
    <>
      <Helmet title="The Galatea Difference" />

      <div className={styles.about}>

        <AboutUs />

        <div className={styles.about__bottomPanel}>
          {(showEventsLoader || showNewsLoader || showPubsLoader) ? <Loader /> :
            (
              <>
                <OurNews news={news} />

                <OurPublications publications={publications} />

                <LatestEvents events={events} />
              </>
            )}
        </div>

      </div>
    </>
  );
};

export default About;
