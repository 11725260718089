import { TestingPanelCatalogItem } from 'api/TestingPanels/types';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { catalogUrls } from 'app/routing';
import { BreadcrumbItem, Option, Page } from 'app/types/ProductCatalog';
import CatalogPanel from 'pages/ProductCatalog/components/PanelsCatalog/components/CatalogPanel/CatalogPanel';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions';
import useTestingPanels from 'pages/ProductCatalog/components/PanelsCatalog/hooks/useTestingPanels';

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

const PanelsCatalog: React.FC<Page> = ({ setPageHeader }) => {
  const { searchParam, sortByParam } = usePaginationParams();
  const { fetchTestingPanels, testingPanels, isLoading } = useTestingPanels();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: catalogUrls.products },
    { text: 'Catalog', link: searchParam ? catalogUrls.panels : undefined },
  ], [ searchParam ]);

  const options: Option[] = useMemo(() => [
    { label: 'Full Catalog', value: '' },
    { label: 'Next Generation Sequencing', value: 'NGS' },
    { label: 'Health Risk Scoring', value: 'PRS' },
  ], []);

  const helmetTitle = useMemo(() => {
    switch (sortByParam) {
      case 'NGS':
        return 'Next Generation Sequencing Catalog';
      case 'PRS':
        return 'Health Risk Scoring Catalog';
      default:
        return 'Full Catalog';
    }
  }, [ sortByParam ]);

  useEffect(() => {
    setPageHeader(<PageHeader title="Panels Catalog" breadcrumbItems={breadcrumbItems} rightColumn={<PageHeaderActions sortByOptions={options} />} />);
  }, [ setPageHeader, breadcrumbItems, options ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchTestingPanels(sortByParam, searchParam, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchTestingPanels, sortByParam, searchParam ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={helmetTitle} />
      {testingPanels.items.map((item: TestingPanelCatalogItem) => <CatalogPanel panel={item} key={item.id} />)}
    </>
  );
};

export default PanelsCatalog;
