import { getPieceOfNewsRequest } from 'api/Publications/api';
import { PublicationResponse } from 'api/Publications/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const usePieceOfNews = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ pieceOfNews, setPieceOfNews ] = useState<PublicationResponse>({
    id: 0,
    title: '',
    description: '',
    publicationDate: '',
    authors: '',
    tags: [],
    body: [],
    meta: {
      slug: '',
      locale: 'en',
    },
  });

  const fetchPieceOfNews = useCallback((pieceOfNewsSlug: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getPieceOfNewsRequest(pieceOfNewsSlug, abortController)
      .then(({ data }) => {
        setPieceOfNews(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { pieceOfNews, isLoading, error, fetchPieceOfNews };
};

export default usePieceOfNews;
