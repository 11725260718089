import styles from 'pages/Legal/LegalPage.module.scss';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { Page } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Heading } from 'components/Typography';
import { ReactComponent as Chrome } from 'pages/Legal/CookiePolicy/assets/chrome.svg';
import { ReactComponent as Edge } from 'pages/Legal/CookiePolicy/assets/edge.svg';
import { ReactComponent as Firefox } from 'pages/Legal/CookiePolicy/assets/firefox.svg';
import { ReactComponent as Safari } from 'pages/Legal/CookiePolicy/assets/safari.svg';
import Browser from 'pages/Legal/CookiePolicy/components/Browser/Browser';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const CookiePolicy: React.FC<Page> = ({ setPageHeader }) => {
  const sectionTitles = useMemo(
    () => [ 'Last Updates', 'Cookie disclosure', 'Categories of cookies', 'Managing cookies on your device' ],
    [],
  );
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title="Cookie Policy" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <Heading variant="H1">Galatea Bio Cookie Policy</Heading>

        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.legalPage__section}>
          <Heading variant="H4">Last Updated: October 1, 2024</Heading>

          <Heading variant="H5">Introduction</Heading>

          <Body variant="L">
            Our Cookie Policy (this “Policy”) explains how Galatea Bio, including its affiliates and subsidiaries (“Galatea Bio,” “we” or “us”),
            uses cookies and similar tracking technology when you access or use our websites, or any other websites, pages, features,
            or content owned or operated by Galatea Bio (collectively, our “Services”).
            Please read this Policy carefully so that you can be informed about our cookie-related privacy practices when accessing or using our Services.
          </Body>

          <Heading variant="H5">Amendments to this Policy</Heading>

          <Body variant="L">
            We may update this Policy at any time. You will be notified if we make any material changes to this Policy when you first visit our Services
            after the change or via email. In the event we send you an email notice, we will use the email address we have on file.
            We will update the Last Updated date at the top of this Policy when we make changes to this Policy.
          </Body>

          <Heading variant="H5">What is a cookie?</Heading>

          <Body variant="L">
            A cookie is a small piece of data (text file) that a website, when visited by a user, asks your browser to store on your device in order
            to remember information about you, such as your language preference or login information.
            “First-party cookies” are cookies supplied by Galatea Bio and that Galatea Bio places on your device.
            “Third-party cookies” are cookies that another party places on your device through our site.
            We allow selected third parties to place cookies through our Services to provide us with better insights into the use of the websites and more.
          </Body>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{sectionTitles[1]}</Heading>

          <Body variant="L">
            The following section explains the types, categories and purpose of cookies on websites.
            See the Managing cookies on your device section below to update your cookie preferences
          </Body>

          <Heading variant="H5">Use of cookies on Galatea Bio websites</Heading>

          <Body variant="L">
            Cookies can serve a variety of purposes. For example, cookies can save your website preferences, tell us which pages are most popular,
            alert us when certain pages are receiving error messages, or provide you with a secure browsing session on our Services.
            Such information helps us improve and optimize our Services. In addition, Galatea Bio also uses marketing cookies because they
            help us with marketing our products and services to you based on your interests and preferences.
            Marketing cookies collect information about your browsing habits to provide us with insights about your interests and preferences.
            For example, you may see advertisements about Galatea Bio when you leave our Services.
          </Body>

          <Heading variant="H5">Does Galatea Bio use other tracking technologies?</Heading>

          <Body variant="L">
            We use other tracking technologies similar to cookies, such as flash cookies, web beacons, or pixels.
            These technologies also help us understand how you use our Services in the following ways:
          </Body>

          <ul>
            <li>
              <b>“Flash Cookies”</b> (also known as “Local Shared Objects” or “LSOs”) to collect information about your use of our Services.
              Flash cookies are commonly used for advertisements and videos.
            </li>
            <li>
              <b>“Web Beacons”</b> (also known as “clear gifs”) are tiny graphics with a unique identifier, similar in function to cookies.
              Web beacons are embedded invisibly on web pages and do not store information on your device like cookies.
              We use web beacons to help us better manage content on our Services and other similar reasons to cookies.
            </li>
            <li>
              <b>“Pixels”</b> track your interactions with our Services. We often use pixels in combination with cookies.
            </li>
          </ul>

          <Body variant="L">
            We generally refer to cookies, web beacons, flash cookies, and pixels as “cookies” in this Policy.
          </Body>

          <Heading variant="H5">How long does Galatea Bio store cookie data?</Heading>

          <Body variant="L">
            Our cookie data retention periods vary on the type of cookie. A “session cookie” collects and stores information about your interactions
            with our Services and is deleted after you close your browser. A “persistent cookie” saves information about you for longer periods of time,
            such as your registration ID and login password for future logins to our Services.
            The retention period for persistent cookies vary and are dependent on the purpose of the cookie collection.
          </Body>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.legalPage__section}>
          <Heading variant="H4">Categories of cookies collected by Galatea Bio</Heading>

          <Heading variant="H5">Must have</Heading>

          <Body variant="L">
            These cookies help make our website usable by enabling basic functions like page navigation and access to secure areas of the website.
            The website cannot function properly without these cookies.
          </Body>

          <Heading variant="H5">Preferences</Heading>

          <Body variant="L">
            These cookies allow the website to remember choices you make to give you better functionality and personal features.
            For example, these cookies allow our website to remember your country selection or your display name as you navigate through our Services.
            Without these cookies, parts of our Services cannot be provided to you.
          </Body>

          <Heading variant="H5">Analytics</Heading>

          <Body variant="L">
            These cookies provide us information about your interactions on our website to help us improve and maintain our Services.
            For example, they tell us whether you visited certain parts of our website, how long you spent there, or what you clicked on.
          </Body>

          <Heading variant="H5">Marketing</Heading>

          <Body variant="L">
            Our marketing cookies allow us to be more informed about your interests and preferences by collecting data about your interactions with
            our marketing activities, such as parts of our website and our emails. Marketing cookies also allow us to measure how effective
            a marketing campaign is.
          </Body>
        </div>

        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{sectionTitles[3]}</Heading>

          <Body variant="L">
            You can control and manage your Galatea Bio cookie preference by contacting us at&nbsp;
            <Link to="mailto:compliance@galatea.bio">compliance@galatea.bio</Link>.
            Please note that removing or blocking cookies can impact your user experience and some functionality may no longer be available.
          </Body>

          <Heading variant="H5">Using your browser to control cookies</Heading>

          <Body variant="L">
            Most browsers allow you to view, manage, delete and block cookies for a website. Be aware that if you delete all cookies then any preferences
            you have set will be lost, including the ability to opt-out from cookies as this function itself requires placement of an opt out cookie
            on your device. Guidance on how to control cookies for common browsers is linked below.
          </Body>

          <div className={styles.legalPage__browsers}>
            <Browser
              link="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
              title={'Google\nChrome'}
              icon={<Chrome />}
            />

            <Browser
              link="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              title={'Mozilla\nFirefox'}
              icon={<Firefox />}
            />

            <Browser
              link="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              title={'MacOS\nSafari'}
              icon={<Safari />}
            />

            <Browser
              link="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              title={'Microsoft\nEdge'}
              icon={<Edge />}
            />
          </div>

          <Heading variant="H5">Contact Us</Heading>

          <Body variant="L">
            If you have any questions or concerns regarding this Policy, please review our Privacy Policy or Terms of Use.
            If you do not find the answers there, please contact us at&nbsp;
            <Link to="mailto:compliance@galatea.bio">compliance@galatea.bio</Link>.
          </Body>
        </div>
      </PageWithSidebar>
    </>
  );
};

export default CookiePolicy;
