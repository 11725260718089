import { getComparingRequest } from 'api/TestingPanels/api';
import { ComparingResponse, ComparingSubset, ComparingPanel } from 'api/TestingPanels/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useComparing = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const subset: ComparingSubset = {
    id: 0,
    title: '',
    description: '',
    specifications: [],
    genesAmount: 0,
    genes: [],
    methodologies: [],
    limitations: '',
  };
  const testingPanel: ComparingPanel = { ...subset, slug: '', productCategory: null };
  const [ comparing, setComparing ] = useState<ComparingResponse>({
    subset,
    testingPanel,
  });

  const fetchComparing = useCallback((panelSlug: string, subsetSlug: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getComparingRequest(panelSlug, subsetSlug, abortController)
      .then(({ data }) => {
        setComparing(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { comparing, isLoading, error, fetchComparing };
};

export default useComparing;
