import 'pages/ProductCatalog/components/Panel/components/Specifications/Specifications.scss';
import { Methodology, Specification } from 'api/TestingPanels/types';
import { renderIcon } from 'app/helpers/icons';
import { Body, Caption, Heading } from 'components/Typography';

import React from 'react';

interface Props {
  specifications: Specification[];
  methodologies: Methodology[];
}

const Specifications: React.FC<Props> = ({ specifications, methodologies }) => {
  const renderSpecification = () => {
    if (specifications[0]) {
      const { turnaroundTime, turnaroundTimeDescription, newYorkApproved, alternateSpecimen, preferredSpecimen } = specifications[0].value;
      return (
        <>
          <Heading variant="H5">Turnaround time</Heading>
          <div className="specification">
            {renderIcon('lab')}
            <Caption variant="XS" className="specification__turnaroundTime">
              {turnaroundTime}
            </Caption>
            <Body variant="S">{turnaroundTimeDescription}</Body>
          </div>

          {newYorkApproved && (
            <>
              <Heading variant="H5">New York approved</Heading>
              <div className="specification">
                {renderIcon('check')}
                <Body variant="S">Yes</Body>
              </div>
            </>
          )}

          <Heading variant="H5">Preferred Specimen</Heading>
          <div className="specification">
            {renderIcon('lab')}
            <Body variant="S">{preferredSpecimen}</Body>
          </div>

          <Heading variant="H5">Alternate Specimen</Heading>
          <div className="specification">
            {renderIcon('lab')}
            <Body variant="S">{alternateSpecimen}</Body>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const renderMethodologies = () => {
    if (methodologies.length > 0) {
      return (
        <>
          <Heading variant="H5">Methodologies</Heading>
          {methodologies.map(m => (
            <div className="specification" key={m.name}>
              {renderIcon('cogs')}
              <Body variant="S">{m.name}</Body>
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="specifications">
      {renderSpecification()}
      {renderMethodologies()}
    </div>
  );
};

export default Specifications;
