import styles from 'pages/Legal/LegalPage.module.scss';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Caption, Heading } from 'components/Typography';
import arabic from 'pages/GalateaDifference/Compliance/components/Nondiscrimination/assets/arabic.png';
import farsi from 'pages/GalateaDifference/Compliance/components/Nondiscrimination/assets/farsi.png';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Nondiscrimination: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const sectionTitles = useMemo(
    () => [ 'General', 'Galatea’s Grievance Procedure', 'Notice of Language Assistance Services' ],
    [],
  );
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);
  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.compliance'), link: galateaDifferenceUrls.compliance },
    { text: 'Nondiscrimination and Access to Services Notice' },
  ], [ t ]);

  useEffect(
    () => setPageHeader(
      <PageHeader sidebarItems={sidebarItems} breadcrumbItems={breadcrumbItems} title="Nondiscrimination and Access to Services Notice" />,
    ),
    [ setPageHeader, sidebarItems, breadcrumbItems ],
  );

  return (
    <>
      <Helmet title="Nondiscrimination and Access to Services Notice" />

      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.legalPage__section}>
          <Body variant="L">
            Galatea Bio, Inc. (“Galatea”) complies with applicable federal civil rights laws and does not discriminate, exclude or treat individuals
            differently on the basis of race, color, national origin, age, disability, or sex or any other basis prohibited by federal, state or local laws.
          </Body>

          <Body variant="L">
            Galatea provides free aids and services to people with disabilities or limited English proficiency to communicate effectively with us,
            such as TTY/TDD calls or written information in suitable formats. Galatea provides free language services to people whose primary language
            is not English through qualified interpreters. These resources may be obtained by contacting the 504 Coordinator or by calling Galatea’s
            customer service directly (or by first dialing 711 for hearing impaired individuals to be connected through a relay service operator)
            at 1-754-233-3350.
          </Body>

          <Body variant="L">
            If you have questions regarding access to services or need to report a concern regarding discrimination in access to services or
            because of a disability, please contact Galatea’s 504 Coordinator. Galatea has developed an internal written procedure for managing
            grievances to ensure prompt and equitable resolution.
          </Body>

          <Body variant="L">
            You may file a grievance in person, by mail or by email. Galatea will make appropriate arrangements to ensure that individuals with
            disabilities and individuals with limited English proficiency are provided Auxiliary Aids and Services or language assistance services,
            respectively, if needed to participate in this grievance process.
          </Body>

          <Body variant="L">
            If you need assistance in filing a grievance, please contact Galatea’s 504 Coordinator at: 1-754-233-3350 or at&nbsp;
            <Link to="mailto:compliance@galatea.bio">compliance@galatea.bio</Link> (Attention: 504 Coordinator).
          </Body>

          <Body variant="L">
            The availability and use of this Myriad grievance procedure does not prevent an individual from pursuing other legal or administrative remedies.
            You may also file a civil rights complaint with the Office of Civil Rights at:&nbsp;
            <Link to="https://www.hhs.gov/" target="_blank" rel="noopener noreferrer">
              U.S. Department of Health & Human Services – Office for Civil Rights
            </Link>. Complaints may also be mailed to OCR Headquarters at the following address:
          </Body>

          <Body variant="L">
            Office for Civil Rights<br />
            U.S. Department of Health and Human Services<br />
            200 Independence Avenue, SW. H.H.H. Building, Room 509-F<br />
            Washington, D.C. 20201<br />
            Phone: 1-800-368-1019<br />
            TDD: 1-800-537-7697<br />
            Email: <Link to="mailto:ocrmail@hhs.gov">ocrmail@hhs.gov</Link>
          </Body>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{sectionTitles[1]}:</Heading>

          <ol>
            <li>
              Any person who believes someone has been subjected to discrimination by Galatea on the basis of race, color, national origin, sex,
              age or disability may file a grievance with Galatea. It is against the law for Galatea to retaliate against anyone who opposes discrimination,
              files a grievance, or participates in the investigation of a grievance.
            </li>
            <li>
              Grievances must be submitted within 60 days of the date the person filing the grievance becomes aware of the alleged discriminatory action.
            </li>
            <li>
              The complaint must be in writing, containing the name and address of the person filing it. The complaint must state the problem or action alleged
              to be discriminatory and the remedy or relief sought.
            </li>
            <li>
              Galatea will conduct an investigation of the complaint. This investigation may be informal, but it will be thorough, affording all
              interested persons an opportunity to submit evidence relevant to the complaint. Galatea will maintain the files and records relating to
              such grievances. To the extent possible, and in accordance with applicable law, Galatea will take appropriate steps to preserve the
              confidentiality of files and records relating to grievances and will share them only with those who have a need to know.
            </li>
            <li>
              Galatea will issue a written decision on the grievance, based on a preponderance of the evidence, no later than 30 days after its filing,
              including a notice to the complainant of their right to pursue further administrative or legal remedies.
            </li>
            <li>
              The person filing the grievance may appeal Galatea’s decision in writing to the CEO of Galatea Bio, Inc. within 15 days of receiving Galatea’s
              initial decision. The CEO will issue a written decision in response to the appeal no later than 30 days after its filing.
            </li>
          </ol>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.legalPage__section}>
          <Heading variant="H4">Galatea Bio Notice of Language Assistance Services – Taglines</Heading>

          <Body variant="L">
            Galatea Bio, Inc. complies with applicable federal civil rights laws and does not discriminate on the basis of race, color,
            national origin, age, disability, or sex.<br />
            ATTENTION: If you speak _____, free language assistance services are available to you. Call 1-754-233-3350.
          </Body>

          <Caption variant="M">Español (Spanish)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. cumple con las leyes federales de derechos civiles aplicables y no discrimina por motivos de raza, color, nacionalidad, edad,
            discapacidad o sexo. ATENCIÓN: si habla español, tiene a su disposición servicios gratuitos de asistencia lingüística. Llame al 1-754-233-3350.
          </Body>

          <Caption variant="M">繁體中文 (Chinese)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. 遵守適用的聯邦民權法律規定，不因種族、膚色、民族血統、年齡、殘障或性別而歧視任何人。 注意：如果您使用繁體中文，您可以免費獲得語言援助服務。
            請致電1-754-233-3350.
          </Body>

          <Caption variant="M">한국어 (Korean)</Caption>

          <Body variant="L">
            Galatea Bio, Inc.은(는) 관련 연방 공민권법을 준수하며 인종, 피부색, 출신 국가, 연령, 장애 또는 성별을 이유로 차별하지 않습니다. 주의: 한국어를 사용하시는 경우,
            언어 지원 서비스를 무료로 이용하실 수 있습니다. 1-754-233-3350. 번으로 전화해 주십시오
          </Body>

          <Caption variant="M">Tagalog (Tagalog – Filipino)</Caption>

          <Body variant="L">
            Sumusunod ang Galatea Bio, Inc. sa mga naaangkop na Pederal na batas sa karapatang sibil at hindi nandidiskrimina batay sa lahi,
            kulay, bansang pinagmulan, edad, kapansanan o kasarian. PAUNAWA: Kung nagsasalita ka ng Tagalog, maaari kang gumamit ng mga serbisyo
            ng tulong sa wika nang walang bayad. Tumawag sa 1-754-233-3350.
          </Body>

          <Caption variant="M">Русский (Russian)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. соблюдает применимое федеральное законодательство в области гражданских прав и не допускает дискриминации по признакам расы,
            цвета кожи, национальной принадлежности, возраста, инвалидности или пола. ВНИМАНИЕ: Если вы говорите на русском языке, то вам доступны бесплатные
            услуги перевода. Звоните 1-754-233-3350.
          </Body>

          <Caption variant="M">ال عرب ية (Arabic)</Caption>

          <img alt="Arabic" src={arabic} style={{ marginBottom: 24, maxWidth: '100%' }} />

          <Caption variant="M">Kreyòl Ayisyen (French Creole)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. konfòm ak lwa sou dwa sivil Federal ki aplikab yo e li pa fè diskriminasyon sou baz ras, koulè, peyi orijin, laj,
            enfimite oswa sèks. ATANSYON: Si w pale Kreyòl Ayisyen, gen sèvis èd pou lang ki disponib gratis pou ou. Rele 1-754-233-3350.
          </Body>

          <Caption variant="M">Français (French)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. respecte les lois fédérales en vigueur relatives aux droits civiques et ne pratique aucune discrimination basée
            sur la race, la couleur de peau, l’origine nationale, l’âge, le sexe ou un handicap.
            ATTENTION: Si vous parlez français, des services d’aide linguistique vous sont proposés gratuitement. Appelez le 1-754-233-3350.
          </Body>

          <Caption variant="M">Português (Portuguese)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. cumpre as leis de direitos civis federais aplicáveis e não exerce discriminação com base na raça, cor,
            nacionalidade, idade, deficiência ou sexo. ATENÇÃO: Se fala português, encontram-se disponíveis serviços linguísticos, grátis.
            Ligue para 1-754-233-3350.
          </Body>

          <Caption variant="M">Italiano (Italian)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. è conforme a tutte le leggi federali vigenti in materia di diritti civili e non pone in essere discriminazioni
            sulla base di razza, colore, origine nazionale, età, disabilità o sesso. ATTENZIONE: In caso la lingua parlata sia l’italiano,
            sono disponibili servizi di assistenza linguistica gratuiti. Chiamare il numero 1-754-233-3350.
          </Body>

          <Caption variant="M">Deutsch (German)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. erfüllt geltenden bundesstaatliche Menschenrechtsgesetze und lehnt jegliche Diskriminierung aufgrund von Rasse,
            Hautfarbe, Herkunft, Alter, Behinderung oder Geschlecht ab. ACHTUNG: Wenn Sie Deutsch sprechen, stehen Ihnen kostenlos sprachliche
            Hilfsdienstleistungen zur Verfügung. Rufnummer: 1-754-233-3350.
          </Body>

          <Caption variant="M">Polski (Polish)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. postępuje zgodnie z obowiązującymi federalnymi prawami obywatelskimi i nie dopuszcza się dyskryminacji ze względu na rasę,
            kolor skóry, pochodzenie, wiek, niepełnosprawność bądź płeć. UWAGA: Jeżeli mówisz po polsku, możesz skorzystać
            z bezpłatnej pomocy językowej. Zadzwoń pod numer 1-754-233-3350.
          </Body>

          <Caption variant="M">日本語 (Japanese)</Caption>

          <Body variant="L">
            Galatea Bio, Inc. は適用される連邦公民権法を遵守し、人種、肌の色、出身国、年齢、障害または性別に基づく差別をいた しません。 注意事項：
            日本語を話される場合、無料の言語支援をご利用いただけます。1-754-233-3350.
          </Body>

          <Caption variant="M">ف ار سی (Farsi – Persian)</Caption>

          <img alt="Farsi" src={farsi} style={{ maxWidth: '100%' }} />
        </div>
      </PageWithSidebar>
    </>
  );
};

export default Nondiscrimination;
