import styles from 'pages/Legal/LegalPage.module.scss';
import useIntersectionProps from 'app/hooks/useIntersectionProps';
import { legalUrls } from 'app/routing';
import { Page, SectionItem, SidebarItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Caption, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const HIPAANotice: React.FC<Page> = ({ setPageHeader }) => {
  const props = useIntersectionProps();

  const { ref: ref1, inView: ref1InView } = useInView(props);
  const { ref: ref2, inView: ref2InView } = useInView(props);
  const { ref: ref3, inView: ref3InView } = useInView(props);
  const { ref: ref4, inView: ref4InView } = useInView(props);
  const { ref: ref5, inView: ref5InView } = useInView(props);
  const { ref: ref6, inView: ref6InView } = useInView(props);
  const { ref: ref7, inView: ref7InView } = useInView(props);
  const { ref: ref8, inView: ref8InView } = useInView(props);
  const { ref: ref9, inView: ref9InView } = useInView(props);
  const menuText1 = 'Last Updates';
  const menuText2 = '1. WHAT IS PROTECTED HEALTH INFORMATION?';
  const menuText3 = '2. OUR RESPONSIBILITIES';
  const menuText4 = '3. HOW WE MAY USE AND SHARE YOUR PROTECTED HEALTH INFORMATION WITHOUT AUTHORIZATION';
  const menuText5 = '4. WHEN WE MUST OBTAIN YOUR AUTHORIZATION TO USE OR SHARE PROTECTED HEALTH INFORMATION';
  const menuText6 = '5. YOUR RIGHTS REGARDING YOUR PROTECTED HEALTH INFORMATION';
  const menuText7 = '6. QUESTIONS AND COMPLAINTS';
  const menuText8 = '7. CHANGES TO OUR NOTICE OF PRIVACY PRACTICES';
  const menuText9 = '8. CONTACT INFORMATION';
  const sectionItems: SectionItem[] = [
    { id: menuText1, ref: ref1 },
    { id: menuText2, ref: ref2 },
    { id: menuText3, ref: ref3 },
    { id: menuText4, ref: ref4 },
    { id: menuText5, ref: ref5 },
    { id: menuText6, ref: ref6 },
    { id: menuText7, ref: ref7 },
    { id: menuText8, ref: ref8 },
    { id: menuText9, ref: ref9 },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: menuText1, inView: ref1InView },
    { text: menuText2, inView: ref2InView && !ref1InView },
    { text: menuText3, inView: ref3InView && !ref2InView },
    { text: menuText4, inView: ref4InView && !ref3InView },
    { text: menuText5, inView: ref5InView && !ref4InView },
    { text: menuText6, inView: ref6InView && !ref5InView },
    { text: menuText7, inView: ref7InView && !ref6InView },
    { text: menuText8, inView: ref8InView && !ref7InView },
    { text: menuText9, inView: ref9InView && !ref8InView },
  ], [
    ref1InView,
    ref2InView,
    ref3InView,
    ref4InView,
    ref5InView,
    ref6InView,
    ref7InView,
    ref8InView,
    ref9InView,
  ]);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title="HIPPA Notice of Privacy Practices" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <Heading variant="H1">Galatea Bio HIPPA Notice of Privacy Practices</Heading>

        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.legalPage__section}>
          <Heading variant="H4">Notice Issuance Date: October 1, 2024</Heading>

          <Heading variant="H5">Introduction</Heading>

          <Body variant="L">
            <b>
              THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION.
              PLEASE REVIEW IT CAREFULLY.
            </b>
          </Body>

          <Body variant="L">
            Galatea Bio (“Galatea Bio,” “we” or “us”) is committed to protecting the privacy of your health information.
            We are required by law to give you notice of our legal duties and privacy practices concerning your &quot;Protected Health Information.&quot;
            This Notice describes your rights, as well as our rights and obligations, with respect to your Protected Health Information under HIPAA.
            Where the terms of our Privacy Policy either increase the commitment Galatea Bio has made to an individual’s privacy or grant additional
            rights to patients greater than those listed in this Notice, the terms of the Privacy Policy will control how we use and disclose your PHI.
          </Body>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText2}</Heading>

          <Body variant="L">
            Protected Health Information includes your name and date of birth, medical history, laboratory results, insurance information and
            other health information that we collect, generate, use, and share to produce genetic testing results, bill for our testing services,
            and for other purposes allowed or required by law.
          </Body>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText3}</Heading>

          <Body variant="L">
            We are required by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) to protect the privacy of your
            Protected Health Information and notify you of any breach of unsecured Protected Health Information. We must follow the terms of this
            Notice when we use or share your Protected Health Information. We will not use or share your Protected Health Information other
            than as described in this Notice unless you give us written authorization to do so.
          </Body>
        </div>

        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText4}</Heading>

          <Body variant="L">
            <b>We may use or share your Protected Health Information in the following ways without your written authorization as permitted by law:</b>
          </Body>

          <Caption variant="M">For Your Treatment</Caption>

          <Body variant="L">
            We may use or share your Protected Health Information to provide treatment. For example, we may use your Protected Health Information
            to perform our testing services and disclose your genetic testing results to your physician.
          </Body>

          <Caption variant="M">To Collect Payment for Our Services</Caption>

          <Body variant="L">
            We may use or share your Protected Health Information to obtain payment for healthcare services. For example, we may use and share your information
            to send a bill to your insurance company to receive payment for the services we provided to you.
          </Body>

          <Caption variant="M">For Our Health Care Operations</Caption>

          <Body variant="L">
            We may use and share your Protected Health Information for our internal health care operations. For example, we may use your
            Protected Health Information to monitor the quality of our testing services, make sure our testing systems are up-to-date,
            and review the competence and qualifications of our laboratory professionals.
          </Body>

          <Caption variant="M">To Your Personal Representative or Legal Guardian</Caption>

          <Body variant="L">
            If you have an authorized personal representative, such as an attorney-in-fact under a health care power of attorney,
            then we may share your Protected Health Information to your personal representative. If you are a minor, then we may share your
            Protected Health Information with your parent or legal guardian.
          </Body>

          <Caption variant="M">To Persons Involved in Your Care or Payment for Your Care</Caption>

          <Body variant="L">
            We may share your Protected Health Information to persons involved in your care or payment for your care, such as a family member, relative,
            or close personal friend, unless you ask us not to do so.
          </Body>

          <Caption variant="M">To Contact You About Our Galatea Bio Products and Services</Caption>

          <Body variant="L">
            We may use and share your Protected Health Information to contact you about other Galatea Bio products and services which
            we believe may be of interest to you.
          </Body>

          <Caption variant="M">To Our Business Associates</Caption>

          <Body variant="L">
            We may share your Protected Health Information with our “business associates,” which are companies or individuals that provide services to us.
            For example, we may use a company to perform billing services for us. Our business associates are required to protect
            the privacy and security of your Protected Health Information.
          </Body>

          <Caption variant="M">As Required by Law</Caption>

          <Body variant="L">
            We must share your Protected Health Information when required to do so by any applicable federal, state, or local law.
          </Body>

          <Caption variant="M">For Public Health</Caption>

          <Body variant="L">
            We may share your Protected Health Information for public health and safety activities. For example, we may share your
            Protected Health Information when we report to public health authorities, cooperate with public health investigations,
            or notify a manufacturer of a product regulated by the U.S. Food and Drug Administration of a possible problem.
          </Body>

          <Caption variant="M">To Health Oversight Agencies</Caption>

          <Body variant="L">
            We may share your Protected Health Information to a healthcare oversight agency for activities that are authorized by law,
            such as audits, investigations, inspections and licensure activities. For example, we may share your Protected Health Information
            with agencies responsible for ensuring compliance with Medicare or Medicaid program rules.
          </Body>

          <Caption variant="M">For Research</Caption>

          <Body variant="L">
            We may use or disclose your Protected Health Information for research purposes, such as to better understand genetic conditions, develop new tests,
            add new genes to our tests, engage in research collaborations with third parties, or support third parties’ research activities.
            We may make these research uses and disclosures of your Protected Health Information if (1) an institutional review board or privacy board
            has determined the research meets certain criteria, (2) under certain circumstances if the Protected Health Information is about patients
            who are deceased, or (3) by using a limited data set as described further below. In addition, in preparation for research when permitted by law,
            we may review Protected Health Information to draft research protocols, identify or contact prospective research participants,
            or for similar purposes provided that legal conditions designed to protect your privacy are met. All other uses and disclosures of
            Protected Health Information for research will require your written authorization.
          </Body>

          <Caption variant="M">To Create De-identified Information and Limited Data Sets</Caption>

          <Body variant="L">
            We may use Protected Health Information to create de-identified health information and limited data sets. De-identified health information
            is health information that cannot reasonably be used to identify you. Once health information has been appropriately de-identified under
            HIPAA and other applicable law, we may use and share the de-identified health information for any purpose, such as to help advance medical
            care and the clinical practice of genetics. Limited data sets are Protected Health Information that do not include certain direct identifiers
            about you, such as your name or phone number. We may use and share limited data sets for purposes of research, health care operations,
            or public health activities as described in this Notice after entering into a HIPAA-compliant agreement with the recipient.
          </Body>

          <Caption variant="M">During Judicial and Administrative Proceedings</Caption>

          <Body variant="L">
            We may share your Protected Health Information during the course of a judicial or administrative proceeding in response to a court order,
            subpoena, or other lawful process.
          </Body>

          <Caption variant="M">To Law Enforcement</Caption>

          <Body variant="L">
            We may share your Protected Health Information with the police or other law enforcement officials as required by law or in compliance
            with a court order, warrant, subpoena, summons, or other legal process for locating a suspect, fugitive, witness, missing person,
            or victim of a crime.
          </Body>

          <Caption variant="M">To Respond to Threats to Health or Safety</Caption>

          <Body variant="L">
            We may share Protected Health Information to prevent or reduce the risk of a serious and imminent threat to the health or safety of
            an individual or the general public.
          </Body>

          <Caption variant="M">To Report Suspected Abuse, Neglect, or Violence</Caption>

          <Body variant="L">
            We may share Protected Health Information with a government agency, such as social services or a protective services agency,
            if we reasonably believe that an adult or child is the victim of abuse, neglect, or domestic violence.
          </Body>
        </div>

        <div id={sectionItems[4].id} ref={sectionItems[4].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText5}</Heading>

          <Body variant="L">
            We will ask for your written authorization before using or sharing your Protected Health Information for any purpose not described above.
            For example, we will request your written authorization before using or sharing Protected Health Information to send you “marketing” communications
            as defined by HIPAA. In addition, we will not sell your Protected Health Information to third parties unless you provide written authorization
            that specifically authorizes the sale of your Protected Health Information. You may revoke your authorization, in writing, at any time,
            except to the extent that we have already acted upon your authorization. You may submit your revocation to the Privacy Officer by using
            the contact information provided at the end of this Notice.
          </Body>
        </div>

        <div id={sectionItems[5].id} ref={sectionItems[5].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText6}</Heading>

          <Body variant="L">
            You have the following rights with respect to your Protected Health Information. To exercise any of these rights, please send our
            Privacy Officer a written request by using the contact information provided at the end of this Notice.
          </Body>

          <Caption variant="M">Access to Protected Health Information</Caption>

          <Body variant="L">
            You may ask us to let you inspect or copy the Protected Health Information we maintain. We may deny access to certain information
            for specific reasons—for example, if the access requested is reasonably likely to endanger the life or safety of you or another person.
            If we deny your request, you may ask us to review the denial.
          </Body>

          <Caption variant="M">Restrictions on How We Use or Share Your Protected Health Information</Caption>

          <Body variant="L">
            You may ask us to restrict how we use or share your Protected Health Information. While we will consider all requests for restrictions carefully,
            we typically are not required to agree to your request. However, we must agree if you ask us not to share your Protected Health Information
            to a health plan for certain purposes, we are not legally required to share your Protected Health Information with the health plan,
            and your request relates to an item for which “out-of pocket” payment has been made in full.
          </Body>

          <Caption variant="M">Confidential Communications</Caption>

          <Body variant="L">
            You may ask that we communicate with you about your Protected Health Information in a specific way (for example, home or office phone)
            or send you mail to a specific address, such as your work address. We will agree to reasonable requests for confidential communications.
          </Body>

          <Caption variant="M">Correct or Update Information</Caption>

          <Body variant="L">
            If you believe the Protected Health Information we maintain about you contains an error, you may request that we correct or update your information.
            We may deny your request under certain circumstances and will explain the denial.
          </Body>

          <Caption variant="M">Accounting of Disclosures</Caption>

          <Body variant="L">
            You may request a list, or accounting, of the instances in which we or our business associates have shared your Protected Health Information
            for purposes other than treatment, payment, health care operations and certain other purposes. The list will only include disclosures we
            or our business associates made within the six years before we received your request.
          </Body>
        </div>

        <div id={sectionItems[6].id} ref={sectionItems[6].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText7}</Heading>

          <Body variant="L">
            If you have questions or concerns about our privacy practices, would like a more detailed explanation about your privacy rights, or would like
            a paper or electronic copy of this Notice, please contact our Privacy Office using the contact information below. If you believe that we may
            have violated your privacy rights, you may submit a complaint to our Privacy Office. You also may submit a written complaint to the U.S.
            Department of Health and Human Services (“HHS”). We will provide you with the address to file your complaint with HHS upon request.
            Galatea Bio will not take retaliatory action against you and you will not be penalized in any way if you choose to file a complaint
            with us or with HHS.
          </Body>
        </div>

        <div id={sectionItems[7].id} ref={sectionItems[7].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText8}</Heading>

          <Body variant="L">
            We have the right to change our privacy practices and the terms of this Notice at any time, provided such changes are permitted by applicable law.
            If we change this Notice, we may apply its updated terms to all Protected Health Information we maintain, including any Protected Health Information
            we received or created before we issued the updated Notice. We will promptly post any changes to this Notice on our website at&nbsp;
            <Link to={legalUrls.privacyPolicy}>Galatea Bio Privacy Policy</Link>.
            Please review this website periodically to ensure that you are aware of any updates.
          </Body>
        </div>

        <div id={sectionItems[8].id} ref={sectionItems[8].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText9}</Heading>

          <Body variant="L">
            To communicate with us regarding this Notice, our privacy practices, or your privacy rights, please use the following contact information:
          </Body>

          <Body variant="L">
            Galatea Bio<br />
            Attention: Privacy Officer<br />
            14350 Commerce Way<br />
            Miami, Florida 33016<br />
            <Link to="mailto:compliance@galatea.bio">compliance@galatea.bio</Link>
          </Body>
        </div>
      </PageWithSidebar>
    </>
  );
};

export default HIPAANotice;
