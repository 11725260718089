const providers = '/providers';
const howItWorks = `${providers}/how-it-works`;
const howItWorksAccessForm = `${howItWorks}#Credentials & access to our catalog`;
const clinicalGeneticTesting = `${howItWorks}/clinical-genetic-testing`;
const orderTest = `${providers}/order-test`;
const resources = `${providers}/resources`;
const insurance = `${resources}/insurance`;

const urls = {
  providers,
  howItWorks,
  howItWorksAccessForm,
  orderTest,
  resources,
  clinicalGeneticTesting,
  insurance,
};

export default urls;
