import { ProductCategory } from 'api/Products/types';

const products = '/products';
const productDetail = `${products}/:productSlug`;
const panels = `${products}/panels`;
const panelsWithSort = (sortBy: ProductCategory) => `${panels}/?sortBy=${sortBy}`;
const panelDetail = `${panels}/:panelSlug`;
const panelDetailUrl = (panelSlug: string) => `${panels}/${panelSlug}`;
const subsetComparing = `${products}/comparing/:panelSlug/:subsetSlug`;
const subsetComparingUrl = (panelSlug: string, subsetSlug: string) => `${products}/comparing/${panelSlug}/${subsetSlug}/`;
const nextGenerationSequencing = `${products}/next-generation-sequencing-ngs`;
const healthRiskScoring = `${products}/health-risk-scoring`;

const urls = {
  products,
  productDetail,
  panels,
  panelsWithSort,
  panelDetail,
  panelDetailUrl,
  subsetComparing,
  subsetComparingUrl,
  nextGenerationSequencing,
  healthRiskScoring,
};

export default urls;
