import styles from 'pages/Patient/HowItWorks/components/HowItWorksItem/HowItWorksItem.module.scss';

import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  image: ReactNode;
}

const HowItWorksItem: React.FC<Props> = ({ children, image }) => {
  return (
    <div className={styles.howItWorksItem}>

      <div className={styles.howItWorksItem__image}>
        {image}
      </div>

      {children}

    </div>
  );
};

export default HowItWorksItem;
