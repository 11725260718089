import styles from 'pages/Legal/LegalPage.module.scss';
import useIntersectionProps from 'app/hooks/useIntersectionProps';
import { Page, SectionItem, SidebarItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Table, TableRow } from 'components/Table';
import { Body, Heading } from 'components/Typography';
import appendix from 'pages/Legal/DataGovernance/assets/appendix.png';
import policy_framework from 'pages/Legal/DataGovernance/assets/policy_framework.png';

import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';

const DataGovernance: React.FC<Page> = ({ setPageHeader }) => {
  const props = useIntersectionProps();

  const { ref: ref1, inView: ref1InView } = useInView(props);
  const { ref: ref2, inView: ref2InView } = useInView(props);
  const { ref: ref3, inView: ref3InView } = useInView(props);
  const { ref: ref4, inView: ref4InView } = useInView(props);
  const { ref: ref5, inView: ref5InView } = useInView(props);
  const { ref: ref6, inView: ref6InView } = useInView(props);
  const { ref: ref7, inView: ref7InView } = useInView(props);
  const { ref: ref8, inView: ref8InView } = useInView(props);
  const { ref: ref9, inView: ref9InView } = useInView(props);
  const { ref: ref10, inView: ref10InView } = useInView(props);
  const { ref: ref11, inView: ref11InView } = useInView(props);
  const menuText1 = 'Last Updates';
  const menuText2 = 'Policy Statement';
  const menuText3 = '1. Background Information';
  const menuText4 = '2. Policy Framework and Principles';
  const menuText5 = '2.1. Governance and Ownership';
  const menuText6 = '2.2. Quality and Integrity';
  const menuText7 = '2.3. Classification and Security';
  const menuText8 = '2.4. Terms and Definitions';
  const menuText9 = '3. Policy Review';
  const menuText10 = '4. Further Assistance';
  const menuText11 = 'APPENDIX 1 - DATA MANAGEMENT LIFE CYCLE';
  const sectionItems: SectionItem[] = [
    { id: menuText1, ref: ref1 },
    { id: menuText2, ref: ref2 },
    { id: menuText3, ref: ref3 },
    { id: menuText4, ref: ref4 },
    { id: menuText5, ref: ref5 },
    { id: menuText6, ref: ref6 },
    { id: menuText7, ref: ref7 },
    { id: menuText8, ref: ref8 },
    { id: menuText9, ref: ref9 },
    { id: menuText10, ref: ref10 },
    { id: menuText11, ref: ref11 },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: menuText1, inView: ref1InView },
    { text: menuText2, inView: ref2InView && !ref1InView },
    { text: menuText3, inView: ref3InView && !ref2InView },
    { text: menuText4, inView: ref4InView && !ref3InView },
    { text: menuText5, inView: ref5InView && !ref4InView },
    { text: menuText6, inView: ref6InView && !ref5InView },
    { text: menuText7, inView: ref7InView && !ref6InView },
    { text: menuText8, inView: ref8InView && !ref7InView },
    { text: menuText9, inView: ref9InView && !ref8InView },
    { text: menuText10, inView: ref10InView && !ref9InView },
    { text: menuText11, inView: ref11InView && !ref10InView },
  ], [
    ref1InView,
    ref2InView,
    ref3InView,
    ref4InView,
    ref5InView,
    ref6InView,
    ref7InView,
    ref8InView,
    ref9InView,
    ref10InView,
    ref11InView,
  ]);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title="Data Governance Policy" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <Heading variant="H1" style={{ marginBottom: 26 }}>Data Governance Policy</Heading>

        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={cn(styles.legalPage__section, styles.legalPage__section_lastChildIsTable)}>
          <Table id="dataGovernance" columnsWidth={[ '20%', '20%', '20%', '20%', '20%' ]}>
            <TableRow style={{ paddingTop: 13 }} small>
              <div style={{ paddingBottom: 24 }}>
                <Heading variant="H6">Version</Heading>
                <Body variant="S">1.0</Body>
              </div>

              <div>
                <Heading variant="H6">Approved by</Heading>
                <Body variant="S">Compliance Officer</Body>
              </div>

              <div>
                <Heading variant="H6">Approval Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Effective Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Next Review</Heading>
                <Body variant="S">May 2027</Body>
              </div>
            </TableRow>
          </Table>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText2}</Heading>

          <Table id="policyStatement" columnsWidth={[ '22%', '78%' ]}>
            <TableRow style={{ padding: '11px 0 13px' }}>
              <div>
                <Heading variant="H5">Purpose</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data policies are a collection of principles that describe the rules to control the integrity, security, quality,
                  and usage of data during its lifecycle.<br />
                  The policy also defines the roles and responsibilities of Galatea Bio (GB) staff, contractors, and consultants with internal and external
                  parties in relation to data access, retrieval, storage, disposal, and backup of GB data assets.<br />
                  The purpose of the Data Governance Policy is to:
                </Body>

                <ul style={{ marginBottom: 0 }}>
                  <li>
                    Define the roles and responsibilities for different data creation and usage types, cases and/or situations,
                    and to establish clear lines of accountability.
                  </li>
                  <li>
                    Develop best practices for effective data management and protection.
                  </li>
                  <li>
                    Protect GB’s data against internal and external threats (e.g. breach of privacy and confidentiality, or security breach)
                  </li>
                  <li>
                    Ensure that GB complies with applicable laws, regulations, exchange and standards
                  </li>
                  <li style={{ marginBottom: 0 }}>
                    Ensure that a data trail is effectively documented within the processes associated with accessing, retrieving, exchanging,
                    reporting, managing and storing of data.
                  </li>
                </ul>
              </div>
            </TableRow>

            <TableRow style={{ padding: '11px 0 13px' }}>
              <div>
                <Heading variant="H5">Scope</Heading>
              </div>

              <div>
                <Body variant="L">
                  This policy applies to all institutional data used in the administration of GB and all of its departments, affiliates, and subsidiaries.
                  This policy covers, but is not limited to, institutional data in any form, including print, electronic, audio visual,
                  backup and archived data.<br />
                  This policy applies to all GB staff, contractors and consultants.
                </Body>
              </div>
            </TableRow>
          </Table>

          <Heading variant="H4" style={{ marginBottom: 0 }}>Policy Provisions</Heading>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText3}</Heading>

          <Body variant="L">
            Institutional data is a strategic asset of Galatea Bio (GB) and the appropriate governance for management and use of data is critical
            to GB’s operations. Lack of governance can lead to operational inefficiencies and could expose GB to unwanted risks.
          </Body>

          <Body variant="L">
            The Data Governance Framework (DGF) was established to improve the oversight, guidance and quality of data.
            The framework focuses on People, Process, Technology and Governance to improve the management of data assets from a
            strategic and operational perspective. It allows GB to better leverage its data quality activities, business processes and capabilities.
          </Body>
        </div>

        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText4}</Heading>

          <div className={styles.legalPage__policyFramework}>
            <img src={policy_framework} alt="PolicyFramework" />

            <Body variant="L">
              The following framework outlines the principles and minimum standards that guide GB’s data governance procedures and must be adhered
              to by all GB staff.
            </Body>
          </div>
        </div>

        <div id={sectionItems[4].id} ref={sectionItems[4].ref} className={cn(styles.legalPage__section, styles.legalPage__section_lastChildIsTable)}>
          <Heading variant="H4">{menuText5}</Heading>

          <Table id="privacyPolicy" columnsWidth={[ '22%', '78%' ]}>
            <TableRow highlighted>
              <div>
                <Heading variant="H5">Data Governance Role</Heading>
              </div>

              <div>
                <Heading variant="H5">Data Governance Responsibility</Heading>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Custodian</Heading>
              </div>

              <div>
                <Body variant="L">
                  GB, rather than any individual or department, is the Custodian of the data and any information derived from the data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Chief Data Officer</Heading>
              </div>

              <div>
                <Body variant="L">
                  The Chief Data Officer is responsible for the overall management of GB’s Data and Information Governance.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Governance Steering Committee</Heading>
              </div>

              <div>
                <Body variant="L">
                  The Data Governance Steering Committee is responsible for the overall management of GB’s Data Governance.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Executive</Heading>
              </div>

              <div>
                <Body variant="L">
                  A Data Executive supported by a Data Owner has the responsibility for the management of data assigned within their portfolio.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Owner</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data Owners are delegated by a Data Executive and are responsible for ensuring effective local protocols are in place to guide the
                  appropriate use of their data asset. Access to, and use of, institutional data will generally be administered by the appropriate Data Owner.
                  Data Owners (or a delegated Data Steward) are also responsible for ensuring that all legal, regulatory, and policy requirements
                  are met in relation to the specific data or information asset. This includes responsibility for the classification of data in
                  accordance with the Data Classification Standard.
                </Body>

                <Body variant="L">
                  Data Owners are responsible for ensuring that data conforms to legal, regulatory, and operational standards.
                </Body>

                <Body variant="L">
                  The Data Owner must ensure the process for the administration of data is in accordance with the Data Management Life Cycle (refer Appendix 1).
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Stewards</Heading>
              </div>

              <div>
                <Body variant="L">
                  Every data area must have one or more Data Stewards, who are responsible for the quality and integrity, implementation, and enforcement
                  of data management within their department, team, or research project.
                </Body>

                <Body variant="L">
                  The Data Steward will classify and approve the access, under delegation from a Data Owner, based upon the appropriateness of the User’s
                  role and the intended use. Where necessary, approval from the Data Executive/Data Owner may be required prior to authorization of access.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Creators</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data Creators are data science, research, and clinical laboratory staff who create original research, clinical, or genomics data during
                  the course of their work with and for GB.
                </Body>

                <Body variant="L">
                  Data Creators are responsible for the creation and ownership of research data and primary materials. Original research data and primary
                  materials generated in the conduct of research at GB is owned and retained by GB, subject to any contractual, statutory, ethical,
                  or funding body requirements.
                </Body>

                <Body variant="L">
                  Researchers are permitted to retain a copy of the research data and primary materials for future use, subject to any contractual, statutory,
                  ethical or funding body requirements.
                </Body>
              </div>
            </TableRow>

            <TableRow style={{ paddingBottom: 13 }}>
              <div>
                <Heading variant="H5">Data Specialists</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data Specialists are business and technical subject matter experts in relation to the data or information asset.
                  The Subject Matter Experts (SME’s) are Business or Information Technology specialists who will be responsible for providing ongoing
                  support to GB Operational systems, data or informational assets.
                </Body>
              </div>
            </TableRow>
          </Table>
        </div>

        <div id={sectionItems[5].id} ref={sectionItems[5].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText6}:</Heading>

          <ul>
            <li>
              Data Creators and Data Users must ensure appropriate procedures are followed to uphold the quality and integrity of the data they access.
            </li>
            <li>
              Data records must be kept up-to-date throughout every stage of the business workflow (GB operations) and in an auditable and traceable manner.
              Data should only be collected for legitimate uses and to add value to GB. Extraction, manipulation and reporting of data must be done only
              to perform GB business, including diagnostic testing or research.
            </li>
            <li>
              Where appropriate, before any data (other than publicly available data) is used or shared outside GB, verification with the Data Steward
              is required to ensure the quality, integrity and security of data will not be compromised.
            </li>
            <li>
              Data shall be retained and disposed of in an appropriate manner in accordance with GB’s <i>Records and Data Retention Policy, </i>
              applicable privacy laws and regulations, and governing contractual terms.
            </li>
          </ul>
        </div>

        <div id={sectionItems[6].id} ref={sectionItems[6].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText7}:</Heading>
          <ul>
            <li>
              Staff, contractors and consultants should refer to the <i>Data Classification Standard</i> and the
              Data Handling Guideline for further information.
            </li>
            <li>
              Appropriate data security measures (see <i>Data Classification Standard</i>) must be adhered to at all times to assure the safety,
              quality and integrity of GB data.
            </li>
            <li>
              Personal use of institutional data, including derived data, in any format and at any location, is prohibited.
            </li>
            <li>
              Records stored in an electronic format must be protected by appropriate electronic safeguards and/or physical access controls that restrict
              access only to authorized user(s) Similarly, data in the Galatea Bio Data repository (Databases etc.) must also be stored in a manner that will
              restrict access only to authorized user(s).
            </li>
            <li>
              This Policy applies to records in all formats (paper, digital or audio-visual) whether registered files, working papers, electronic documents,
              emails, online transactions, data held in databases or on tape or disks, maps, plans, photographs, sound and video recordings, or microforms.
            </li>
          </ul>
        </div>

        <div id={sectionItems[7].id} ref={sectionItems[7].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText8}</Heading>

          <ul>
            <li>
              The definition and terms used to describe different types of data should be defined consistently or referred to the relevant
              Business Glossary maintained by the Compliance and Privacy department.
            </li>
          </ul>
        </div>

        <div id={sectionItems[8].id} ref={sectionItems[8].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText9}</Heading>

          <Body variant="L">
            This Policy will be reviewed and updated every three (3) years from the approval date, or more frequently if appropriate. In this regard,
            any staff members who wish to make any comments about the Policy may forward their suggestions to the Responsible Officer.
          </Body>
        </div>

        <div id={sectionItems[9].id} ref={sectionItems[9].ref} className={cn(styles.legalPage__section, styles.legalPage__section_lastChildIsTable)}>
          <Heading variant="H4">{menuText10}</Heading>

          <Body variant="L">
            Any staff member who requires assistance in understanding this Policy should first consult their nominated supervisor who is responsible
            for the implementation and operation of these arrangements in their work area. Should further assistance be needed,
            the staff member should contact the Responsible Officer for clarification.
          </Body>

          <Table columnsWidth={[ '22%', '78%' ]} style={{ marginBottom: 0 }} id="furtherAssistance">
            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Accountabilities</Heading>
              </div>
              <div />
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Responsible Officer</Heading>
              </div>

              <div>
                <Body variant="L">
                  Chief Administrative Officer
                </Body>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Supporting Information</Heading>
              </div>
              <div />
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Regulatory Compliance</Heading>
              </div>

              <div>
                <Body variant="L">
                  This Policy supports GB’s compliance with the following legislation: HIPAA, the Common Rule, Genetic Information Nondiscrimination Act
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Superseded Documents</Heading>
              </div>

              <div>
                <Body variant="L">
                  None
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">File Number</Heading>
              </div>

              <div>
                <Body variant="L">
                  [Policy ID]
                </Body>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Definitions and Acronyms</Heading>
              </div>
              <div />
            </TableRow>

            <TableRow>
              <div style={{ width: '100%' }}>
                <Body variant="L">To establish operational definitions and facilitate ease of reference, the following terms are defined:</Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Access</Heading>
              </div>

              <div>
                <Body variant="L">
                  The right to read, copy, or query data
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Business SME</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refer to Data Specialist
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Business/Division Area</Heading>
                <Body variant="S">Data Area</Body>
              </div>

              <div>
                <Body variant="L">
                  A Data area is a term used to denote a subset of institutional data that is the responsibility of a team including Data Owner and
                  Data Stewards. This could include an entire IT system (e.g. Human Resources system) or a business area such as Identity
                  and Access Management, or a Research project. It may include data that is the responsibility of GB divisions, such as Finance, HR,
                  BioBank, Clinical Laboratory, etc. and Research.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Chief Data Officer</Heading>
                <Body variant="S">CDO</Body>
              </div>

              <div>
                <Body variant="L">
                  Senior officer of GB responsible for Data and Information Governance.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data</Heading>
                <Body variant="S">Institutional Data</Body>
              </div>

              <div>
                <Body variant="L">
                  The representation of facts, concepts or instructions in a formalized (consistent and agreed) manner suitable for communication,
                  interpretation or processing by human or automatic means. Typically comprised of numbers, words or images. The format and presentation
                  of data may vary with the context in which it is used.
                </Body>

                <Body variant="L">
                  Data is not Information until it is used in a particular context for a particular purpose. Data is typically considered to be conceptually
                  at the lowest level of abstraction.
                </Body>

                <Body variant="L">
                  In the context of this policy this term includes all institutional data including research, administrative, genomic,
                  clinical, and diagnostic artefacts.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Creator</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data Creators are responsible for the Ownership of research data and primary materials. Original research data and primary materials
                  generated in the conduct of operations or research at GB. will be owned and retained by GB, subject to any contractual,
                  statutory, ethical, or funding body requirements.
                </Body>

                <Body variant="L">
                  Researchers are permitted to retain a copy of the research data and primary materials for future use, subject to any contractual,
                  statutory, ethical or funding body requirements.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Executive</Heading>
              </div>

              <div>
                <Body variant="L">
                  Is a Senior Executive with planning and decision-making authority for part or all of GB’s institutional data.
                  The Data Executives, as a group, are responsible for overseeing the continuous improvement of GB’s data governance and management.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Governance roles and responsibilities</Heading>
              </div>

              <div>
                <Body variant="L">
                  Outlines the access rights, roles and responsibilities of GB staff, contractors and consultants in relation to the management
                  and protection of data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Governance Steering Committee</Heading>
                <Body variant="S">DGSC</Body>
              </div>

              <div>
                <Body variant="L">
                  Is a company wide committee, with members consisting of Data Executives, Data Stewards and designated Data Users and professional staff.
                  The DGSC has oversight of the Data Governance Program and is responsible for approving the procedures related to the
                  <i>Data Governance Policy.</i> The DGSC also assures appropriate data processes are used in all of GB’s data-driven decisions.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Management Life Cycle</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refers to the process for planning, creating, managing, storing, implementing, protecting, improving and disposing
                  of all institutional data of GB.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Owner</Heading>
              </div>

              <div>
                <Body variant="L">
                  Has operational responsibilities in assisting Data Stewards with day-to-day data administration activities; including, but not limited to:
                  develop, maintain, distribute, and secure institutional data. Data Owners are expected to have high-level knowledge and expertise
                  in the content of data within their responsible area. This role is also the organizational Data Custodian. GB, rather than any individual
                  or department, is the Custodian of the data and any information derived from the data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Quality</Heading>
                <Body variant="S">Quality</Body>
              </div>

              <div>
                <Body variant="L">
                  Refers to the validity, relevancy and currency of data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Specialist</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data Specialists are business and technical subject matter experts in relation to the data or information asset. The Subject Matter Experts
                  (SME’s) are Business or Information Technology specialists who will be responsible for providing ongoing support to GB’s operational systems,
                  data or informational assets.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Steward</Heading>
              </div>

              <div>
                <Body variant="L">
                  Every department, team, or research project must have one or more Data Stewards, who are responsible for the quality and integrity,
                  implementation and enforcement of data management within their department or research project.
                </Body>

                <Body variant="L">
                  The Data Steward will classify and approve the access, under delegation from a Data Owner, based upon the appropriateness of
                  the User’s role and the intended use. Where necessary, approval from the Data Executive/Data Owner may be
                  required prior to authorization of access. Data Stewards are responsible for assuring the requirements of the Data Governance Policy
                  and the Data Governance Procedures are followed within their department, team, or research project.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data User</Heading>
              </div>

              <div>
                <Body variant="L">
                  Is any staff member, contractor, consultant or authorized agent who accesses, inputs, amends, deletes, extracts, and analyses data in
                  GB’s IT systems to carry out their day- to-day duties. Data Users are not generally involved in the governance process but are
                  responsible for the quality assurance of data. Appropriate security and approval is required from Data Stewards to maintain the
                  quality and integrity of the Data. Any member of GB or its affiliates or subsidiaries that has access to institutional data, and thus
                  is entrusted with the protection of that data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Information Security Management System</Heading>
                <Body variant="S">ISMS</Body>
              </div>

              <div>
                <Body variant="L">
                  In response to GB’s Data Classification and Handling requirements, The ISMS provides Information Security governance and sets out people,
                  process and technology related controls to assure the confidentiality, integrity and availability of GB data.
                  The ISMS is a response to GB’s Data Classification and Handling requirements.<br />
                  Moreover, the deployment and measurement of ISMS controls provides input into the risk management process enabling
                  informed business decisions.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Integrity or data integrity</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refers to the accuracy and consistency of data over its entire life cycle.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Management Board</Heading>
                <Body variant="S">MB</Body>
              </div>

              <div>
                <Body variant="L">
                  The senior executive team of GB.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Record</Heading>
                <Body variant="S">Institutional Record</Body>
              </div>

              <div>
                <Body variant="L">
                  Metadata records stored in any digital format
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Record</Heading>
                <Body variant="S">Institutional Record</Body>
              </div>

              <div>
                <Body variant="L">
                  Metadata records stored in any digital formats any document or other source of information compiled, recorded or stored in written form or
                  on film, or by electronic process, or in any other manner or by any other means.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Security</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refers to the safety of GB data in relation to the following criteria:
                </Body>

                <ul style={{ marginBottom: 0 }}>
                  <li>Access control;</li>
                  <li>authentication;</li>
                  <li>effective incident detection, reporting and solution;</li>
                  <li>physical and virtual security;</li>
                  <li style={{ marginBottom: 0 }}>and change management and version control.</li>
                </ul>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Revision History</Heading>
              </div>
              <div />
            </TableRow>
          </Table>

          <Table id="revisionHistory" style={{ borderColor: 'transparent' }} columnsWidth={[ '20%', '20%', '20%', '20%', '20%' ]}>
            <TableRow small>
              <div>
                <Heading variant="H6">Version</Heading>
                <Body variant="S">1.0</Body>
              </div>

              <div>
                <Heading variant="H6">Approved by</Heading>
                <Body variant="S">Compliance Officer</Body>
              </div>

              <div>
                <Heading variant="H6">Approval Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Effective Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Sections modified</Heading>
                <Body variant="S">New Policy</Body>
              </div>
            </TableRow>
          </Table>
        </div>

        <div id={sectionItems[10].id} ref={sectionItems[10].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText11}</Heading>

          <Body variant="L">
            Data Management Life Cycle refers to the process for planning, creating, managing, storing, implementing, protecting,
            improving and disposing of all institutional data of Galatea Bio.
          </Body>

          <img src={appendix} alt="Data Management Life Cycle" className={styles.legalPage__lifeCycle} />
        </div>
      </PageWithSidebar>
    </>
  );
};

export default DataGovernance;
