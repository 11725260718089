import { getLeadershipRequest } from 'api/Leadership/api';
import { LeadershipResponse } from 'api/Leadership/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useLeadership = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ leadership, setLeadership ] = useState<LeadershipResponse>({
    leadershipTeam: [],
    directors: [],
    scientificAdvisors: [],
  });

  const fetchLeadership = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getLeadershipRequest(abortController)
      .then(({ data }) => {
        setLeadership(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { leadership, isLoading, error, fetchLeadership };
};

export default useLeadership;
