import styles from 'pages/Legal/CookiePolicy/components/Browser/Browser.module.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  link: string;
  title: string;
  icon: React.ReactNode;
}

const Browser: React.FC<Props> = ({ link, title, icon }) => {
  const isPatientVersion = useIsPatientVersion();

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to={link}
      className={cn(styles.browser, { [styles.browser_patients]: isPatientVersion })}
    >

      {icon}

      <Heading variant="H6" className={styles.browser__title}>{title}</Heading>

    </Link>
  );
};

export default Browser;
