import { getNewsRequest } from 'api/Publications/api';
import { PublicationsResponse } from 'api/Publications/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useNews = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ news, setNews ] = useState<PublicationsResponse>({
    meta: { totalCount: 0 },
    items: [],
    allTags: [],
  });

  const fetchNews = useCallback((sortBy: string | null, search: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getNewsRequest(sortBy, search, abortController)
      .then(({ data }) => {
        setNews(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { news, isLoading, error, fetchNews };
};

export default useNews;
