import { axiosInstance } from 'api/instance';
import { HubspotFormField } from 'api/Hubspot/types';

import { AxiosResponse } from 'axios';

export const postSubmitFormRequest = (url: string, fields: HubspotFormField[]): Promise<AxiosResponse> => {
  return axiosInstance.post(url, {
    fields,
    context: {
      pageUri: window.location.toString(),
      pageName: document.title,
    },
  });
};
