import { useEffect, useState } from 'react';

const useIntersectionProps = () => {
  const [ props, setProps ] = useState({ rootMargin: '' });
  const headerHeight = document.querySelector('.headerWrapper')?.clientHeight;

  useEffect(() => {
    if (headerHeight) {
      setProps({ rootMargin: `${-headerHeight}px 0px 0px 0px` });
    }
  }, [ headerHeight ]);

  return props;
};

export default useIntersectionProps;
