import 'components/Subset/Subset.scss';
import { Subset as SubsetType } from 'api/TestingPanels/types';
import { grayscale400 } from 'app/consts/colors';
import { renderIcon } from 'app/helpers/icons';
import { pluralize } from 'app/helpers/pluralize';
import { catalogUrls } from 'app/routing';
import Button from 'components/Button/Button';
import { Body, Caption, Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  subset: SubsetType;
  panelSlug: string;
}

const Subset: React.FC<Props> = ({ subset, panelSlug }) => {
  const { slug, title, genes, icons, shortDescription } = subset;

  return (
    <div className="subset">

      <div className="subset__left">

        {icons.map(icon => renderIcon(icon))}

        <div className="subset__info">

          <Heading variant="H6" className="subset__title">{title}</Heading>

          {shortDescription && <Body style={{ color: grayscale400, marginBottom: 6 }}>{shortDescription}</Body>}

          {genes.length > 0 && <Caption variant="XS" style={{ color: grayscale400 }}>{pluralize(genes.length, 'gene')}</Caption>}

        </div>

      </div>

      <Link to={catalogUrls.subsetComparingUrl(panelSlug, slug)}>
        <Button size="S" type="ghost" className="subset__button">Compare</Button>
      </Link>

    </div>
  );
};

export default Subset;
