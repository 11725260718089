import 'components/Header/Header.scss';
import useScrollingEvents from 'app/hooks/useScrollingEvents';
import { ReactComponent as Hamburger } from 'components/Header/assets/Hamburger.svg';
// import LanguageSwitcher from 'components/Header/components/LanguageSwitcher/LanguageSwitcher';
import Logo from 'components/Header/components/Logo/Logo';
import MobileMenu from 'components/Header/components/MobileMenu/MobileMenu';
import SiteVersionSwitcher from 'components/Header/components/SiteVersionSwitcher/SiteVersionSwitcher';
import TopMenu from 'components/Header/components/TopMenu/TopMenu';

import React, { useState } from 'react';
import cn from 'classnames';

interface Props {
  pageHeader?: React.ReactNode;
}

const Header: React.FC<Props> = ({ pageHeader }) => {
  const [ isMenuOpened, setIsMenuOpened ] = useState<boolean>(false);
  const openMenu = () => setIsMenuOpened(true);
  const closeMenu = () => setIsMenuOpened(false);
  const { isPageAtTop } = useScrollingEvents();
  const headerWrapperClassName = cn('headerWrapper', { headerWrapper_inMotion: !isPageAtTop });
  const headerWrapperPosition = window.Cypress ? 'relative' : 'sticky';

  return (
    <>
      <div className={headerWrapperClassName} style={{ position: headerWrapperPosition }}>
        <header className="header">

          <div className="header__content">

            <Logo />

            <SiteVersionSwitcher />

            <TopMenu />

            <Hamburger className="header__hamburger" onClick={openMenu} />

          </div>

          {/* hide it until we have spanish translations */}
          {/* <LanguageSwitcher /> */}

        </header>

        {pageHeader}
      </div>

      <MobileMenu opened={isMenuOpened} onClose={closeMenu} />
    </>
  );
};

export default Header;
