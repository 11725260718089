import styles from 'pages/Provider/HowItWorks/HowItWorks.module.scss';
import { getIconByType } from 'app/helpers/icons';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { providersUrls } from 'app/routing';
import { Page } from 'app/types/ProductCatalog';
import BorderedLink from 'components/BorderedLink/BorderedLink';
import AccessForm from 'components/Forms/AccessForm';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Heading } from 'components/Typography';
import HowItWorksItem from 'pages/Provider/HowItWorks/components/HowItWorksItem/HowItWorksItem';

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

export const HowItWorks: React.FC<Page> = ({ setPageHeader }) => {
  const sectionTitles = useMemo(() => [ 'How it works', 'Verification Process', 'Credentials & access to our catalog' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title={sectionTitles[0]} />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.howItWorks}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref}>
          <Heading variant="H1">{sectionTitles[0]}</Heading>

          <div className={styles.howItWorks__items}>
            <HowItWorksItem title="Get onboarded to: ELIS/LIMS" icon="document-validation" />
            <HowItWorksItem title="Create a new order" icon="shopping-cart-add" />
            <HowItWorksItem title="Get DNA Kit sent to you or your patient directly" icon="package" />
            <HowItWorksItem title="Access your patients results on ELIS" icon="finger-access" />
            <HowItWorksItem title="Post-test genetic counseling session" icon="user-story" />
          </div>

          <div className={styles.howItWorks__searchAtLink}>
            <BorderedLink title="Galatea Bio’s high complexity molecular lab" link={providersUrls.clinicalGeneticTesting} icon="lab" />
          </div>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref}>
          <Heading variant="H3">{sectionTitles[1]}</Heading>

          <Body variant="L" style={{ marginTop: 23 }}>
            ELIS is a HIPAA compliant Cloud Based Laboratory Information System (LIMS) where you can access and order from our full product suite,
            including our genetics tests and polygenic risk scores (PRS) reports. To establish log-in credentials and access our catalog,
            please fill out our form here and someone from our onboarding team will reach out to schedule your tutorial to get you started.
          </Body>

          <Body variant="L" style={{ marginTop: 23 }}>
            For your convenience, upon placing an order, we will automatically send a DNA kit directly to your patient using the patient postal address
            used when creating the requisition. If you prefer to administer the buccal collection at your center, please communicate that request to
            your account manager or contact us at <a href="mailto:info@galatea.bio">info@galatea.bio</a> after submitting your order, referencing your order ID in
            the subject line.
          </Body>

          <Body variant="L" style={{ marginTop: 23 }}>
            After your patient’s results become available on the portal you will receive a notification, and your patient will have the option to
            schedule their genetic counseling session with a DNAvisit counselor. Our partners offer robust counseling expertise including Cancer Genetics,
            Direct-to-consumer Genetics, Pharmacogenomics, Reproductive Genetics, Cardiac Genetics, Adult and Pediatric Genetics, Neurogenetics,
            Whole Genome and Exome Sequencing, and more. Please note that our genetic counselors do not provide counseling for PRS report analysis.
          </Body>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} style={{ marginTop: 35 }}>
          <Heading variant="H3">{sectionTitles[2]}</Heading>

          <div className={styles.howItWorks__credentials}>

            <AccessForm />

            <div className={styles.howItWorks__notification}>
              <div className={styles.howItWorks__notificationBlock}>

                {getIconByType('alert')}

                <div>

                  <Body variant="M" style={{ fontWeight: 700, marginBottom: 24 }}>Please do not share your credentials with anyone else.</Body>

                  <Body variant="M">
                    Each unique user will require their own log-in. Should other members of your team be authorized to place new orders or view reports,
                    please provide their Names & Emails, as well as the level of access they need under the section &quot;Other Users&quot;.
                  </Body>

                </div>

              </div>

              <div className={styles.howItWorks__notificationBlock}>

                {getIconByType('mail')}

                <Body variant="M">
                  If you need to add more new users in the future, please reach out to us at<br />
                  <a href="mailto:info@galatea.bio">info@galatea.bio</a>
                </Body>

              </div>

            </div>

          </div>

        </div>
      </PageWithSidebar>
    </>
  );
};

export default HowItWorks;
