import styles from 'pages/Provider/Insurance/Insurance.module.scss';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { providersUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Insurance: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const sectionTitles = useMemo(() => [ 'Overview', 'Medicare Patients', 'Out-of-Network non Contracted Plan Patients' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.resources'), link: providersUrls.resources },
    { text: t('menuItems.insurance') },
  ], [ t ]);

  useEffect(
    () => setPageHeader(<PageHeader title={t('menuItems.insurance')} breadcrumbItems={breadcrumbItems} sidebarItems={sidebarItems} />),
    [ setPageHeader, breadcrumbItems, t, sidebarItems ],
  );

  return (
    <>
      <Helmet title={t('menuItems.insurance')} />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.insurance}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.insurance__section}>
          <Heading variant="H6" className={styles.insurance__date}>As of September 1, 2024</Heading>

          <Body variant="M" style={{ marginBottom: 15 }}>
            Health care services to include genetic laboratory testing can be expensive.
            Galatea Bio has established the following arrangements with several different types of healthcare insurers to help assist patients with the cost.
            The insurance types accepted by Galatea Bio today include:
          </Body>

          <ul style={{ marginBottom: 15 }}>
            <li>Medicare</li>
            <li>Out-of-Network Non Contracted Plans</li>
          </ul>

          <Body variant="M">
            Patients obtaining Polygenic Risk Score (PRS) testing through Galatea Bio and patients residing outside the United States will be
            financially responsible for the full amount of your testing costs.
            Payment arrangements can be made by contacting our Services Team:<br />
            <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>
          </Body>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.insurance__section}>
          <Heading variant="H3" style={{ marginBottom: 15 }}>{sectionTitles[1]}</Heading>

          <Body variant="M" style={{ marginBottom: 16 }}>
            Galatea Bio is enrolled as Medicare provider for genetic testing laboratory services and will bill Medicare directly for your laboratory services.
            When medically necessary and ordered by your treating provider as a part of your ongoing care or treatment of a medical condition,
            Medicare coverage may cover some or all of the costs associated with the tests furnished by Galatea Bio.
          </Body>

          <Body variant="M" style={{ marginBottom: 16 }}>
            <b>Notice to Medicare Beneficiaries:</b> Medicare does not pay for everything, even some care that you or your health care provider have good reason
            think you need. Genetic testing may not be covered by Medicare in all circumstances and you will be financially responsible
            for payment should Medicare not pay. Medicare will notify you by way of a Medicare Summary Notice (MSN) as to whether the laboratory
            services furnished to you by Galatea Bio were paid or not. You have the right to appeal to Medicare by following the instructions
            included in the MSN you receive.
          </Body>

          <Body variant="M">
            This notice gives the opinion of Galatea Bio, and is not an official Medicare decision.
            If you have other questions on this notice or Medicare billing, call 1-800-MEDICARE (1-800-633-4227/TTY: 1-877-486-2048).
          </Body>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.insurance__section}>
          <Heading variant="H3" style={{ marginBottom: 15 }}>{sectionTitles[2]}</Heading>

          <Body variant="M" style={{ marginBottom: 15 }}>
            Galatea Bio is an out-of-network laboratory for most insurance companies currently, and coverage for Galatea Bio’s tests varies by insurer.
            You are encouraged to consult your healthcare insurance company to determine if the tests your provider has ordered would be covered,
            what pre-authorization requirements you may need to meet, and what financial obligation you may have if you proceed with the testing.
          </Body>

          <Body variant="M">
            We are here to help. If you have any questions about your financial responsibility, please contact our Services Team:&nbsp;
            <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>&nbsp;
            today.
          </Body>
        </div>
      </PageWithSidebar>
    </>
  );
};

export default Insurance;
