import 'pages/ProductCatalog/components/Panel/components/PanelContent/PanelContent.scss';
import { Subset as SubsetType } from 'api/TestingPanels/types';
import Button from 'components/Button/Button';
import DocumentsList from 'components/DocumentsList/DocumentsList';
import GenesList from 'components/GenesList/GenesList';
import RightSidebar from 'components/RightSidebar/RightSidebar';
import Subset from 'components/Subset/Subset';
import { Body, Heading } from 'components/Typography';
import AssayInformation from 'pages/ProductCatalog/components/Panel/components/AssayInformation/AssayInformation';
import Specifications from 'pages/ProductCatalog/components/Panel/components/Specifications/Specifications';
import { PanelPage } from 'pages/ProductCatalog/components/Panel/components/PanelContent/types';

import React, { useState } from 'react';

const CommonPanelContent: React.FC<PanelPage> = ({ sectionItems, panel }) => {
  const {
    description,
    specifications,
    assayInformation,
    subsets,
    genes,
    genesAmount,
    disordersTested,
    physiciansGuide,
    methodologies,
    meta: { slug },
  } = panel;
  const [ isSidebarOpened, setIsSidebarOpened ] = useState<boolean>(false);
  const openSidebar = () => setIsSidebarOpened(true);
  const closeSidebar = () => setIsSidebarOpened(false);

  return (
    <div className="panelContent">

      <div id={sectionItems[0].id} ref={sectionItems[0].ref}>
        <div className="panelContent__section">
          <Heading variant="H4">Description</Heading>

          <Body variant="M">{description}</Body>
        </div>

        {disordersTested && (
          <div className="panelContent__section">
            <Heading variant="H4">Disorders tested</Heading>

            <Button onClick={openSidebar} type="ghost" size="L">Show all disorders tested</Button>
          </div>
        )}
      </div>

      {!sectionItems[1].hide && (
        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className="panelContent__section">
          <Heading variant="H4">Genes ({genesAmount})</Heading>

          <GenesList genes={genes} />
        </div>
      )}

      <div id={sectionItems[2].id} ref={sectionItems[2].ref} className="panelContent__section">
        <Heading variant="H4">Specifications</Heading>
        <Specifications specifications={specifications} methodologies={methodologies} />
      </div>

      {!sectionItems[3].hide && (
        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className="panelContent__section">
          <Heading variant="H4">Physician’s Guide</Heading>

          <DocumentsList documents={physiciansGuide} />
        </div>
      )}

      <div id={sectionItems[4].id} ref={sectionItems[4].ref} className="panelContent__section">
        <AssayInformation assayInformation={assayInformation} />
      </div>

      <div id={sectionItems[5].id} ref={sectionItems[5].ref} className="panelContent__section">
        <Heading variant="H4">Subsets ({subsets.length})</Heading>

        {subsets.map((subset: SubsetType) => <Subset subset={subset} panelSlug={slug} key={subset.title} />)}
      </div>

      {disordersTested && (
        <RightSidebar title="Disorders tested" content={<Body variant="S">{disordersTested}</Body>} opened={isSidebarOpened} onClose={closeSidebar} />
      )}

    </div>
  );
};

export default CommonPanelContent;
