import 'components/Forms/Form.scss';
import urls from 'api/Hubspot/urls';
import { COUNTRIES_NAMES, STATES, USA } from 'app/consts/countries';
import ActionButton from 'components/Forms/components/ActionButton/ActionButton';
import FormInput from 'components/Forms/components/FormControls/FormInput';
import FormSelect from 'components/Forms/components/FormControls/FormSelect';
import FormTextArea from 'components/Forms/components/FormControls/FormTextArea';
import useSubmitForm from 'components/Forms/hooks/useSubmitForm';
import { Heading } from 'components/Typography';

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export interface AccessFormValues {
  firstname: string;
  lastname: string;
  health_care_professional_npi: string;
  institute: string;
  country: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  other_users: string;
}

const AccessForm: React.FC = () => {
  const {
    submitForm,
    isSuccess,
    isError,
    isLoading,
    resetState,
  } = useSubmitForm(urls.accessCatalogFormUrl);
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<AccessFormValues>();
  const watchCountry = watch('country');
  const isUSA = watchCountry === USA;

  useEffect(() => {
    if (!isUSA) {
      setValue('state', '');
    }
  }, [ isUSA, setValue ]);

  return (
    <form className="form">
      <div className="form__block">
        <Heading variant="H6">Basic information</Heading>

        <div className="form__line">
          <FormInput name="firstname" label="First Name" register={register} errors={errors} />

          <FormInput name="lastname" label="Last Name" register={register} errors={errors} />
        </div>

        <FormInput name="health_care_professional_npi" label="Health Care Professional NPI" register={register} errors={errors} />

        <FormInput name="institute" label="Institute / Practice Name" register={register} errors={errors} />
      </div>

      <div className="form__block">
        <Heading variant="H6">Contact details</Heading>

        <FormSelect name="country" label="Country" register={register} errors={errors} options={COUNTRIES_NAMES} required />

        <FormInput name="address" label="Address" register={register} errors={errors} />

        <div className="form__line">
          <FormInput name="city" label="City" register={register} errors={errors} />

          <FormSelect name="state" label="State" register={register} errors={errors} options={STATES} className="form__stateField" required={isUSA} />

          <FormInput name="zip" label="Zip Code" register={register} errors={errors} className="form__zipField" />
        </div>

        <FormInput name="email" label="Email" register={register} errors={errors} />

        <FormInput name="phone" label="Phone number" register={register} errors={errors} />
      </div>

      <div className="form__block">
        <Heading variant="H6">Other Users</Heading>

        <FormTextArea name="other_users" register={register} errors={errors} />
      </div>

      <ActionButton isLoading={isLoading} isSuccess={isSuccess} isError={isError} onClick={handleSubmit(submitForm)} onTryAgainClick={resetState} />
    </form>
  );
};

export default AccessForm;
