import 'components/Table/Table.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';

import React, { CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
  highlighted?: boolean;
  small?: boolean;
}

const Table: React.FC<Props> = ({ children, style, highlighted = false, small = false }) => {
  const isPatientVersion = useIsPatientVersion();

  return (
    <div
      className={cn(
        'table__row',
        {
          table__row_patients: isPatientVersion,
          table__row_highlighted: highlighted,
          table__row_small: small,
        },
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default Table;
