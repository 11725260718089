import styles from 'pages/GalateaDifference/Events/components/Event/Event.module.scss';
import { getDateInfo } from 'app/helpers/dateTime';
import { renderIcon } from 'app/helpers/icons';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import BodyItem from 'components/BodyItem/BodyItem';
import EventForm from 'components/Forms/EventForm';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Heading } from 'components/Typography';
import AddressAndDate from 'pages/GalateaDifference/Events/components/Event/components/AddressAndDate';
import useEvent from 'pages/GalateaDifference/Events/components/Event/hooks/useEvent';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { Helmet } from 'react-helmet-async';

const Event: React.FC<Page> = ({ setPageHeader }) => {
  const { eventSlug } = useParams<{ eventSlug: string }>();
  const { t } = useTranslation();
  const { fetchEvent, event, isLoading } = useEvent();
  const startDateInfo = getDateInfo(event.startDate);
  const endDateInfo = getDateInfo(event.endDate);
  const hideScheduleSection = useMemo(() => {
    return event.passed === 'true' ? [ 2 ] : [];
  }, [ event.passed ]);

  const sectionTitles = useMemo(() => [ 'About', 'Location and date', 'Schedule a meeting' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles, hideScheduleSection);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.events'), link: galateaDifferenceUrls.events },
    { text: event.title },
  ], [ t, event.title ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (eventSlug) {
      fetchEvent(eventSlug, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchEvent, eventSlug ]);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader title={event.title} breadcrumbItems={breadcrumbItems} rightColumn={<AddressAndDate event={event} />} />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, isLoading, event ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={event.title ? `Events - ${event.title}` : ' Events'} />
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
        <PageWithSidebar sidebarItems={sidebarItems} className={styles.event}>

          <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.event__section}>
            <Heading variant="H2" className={styles.event__title}>{event.title}</Heading>
            {event.body.map(item => <BodyItem bodyItem={item} key={item.id} />)}
          </div>

          <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.event__section}>

            <Heading variant="H4" style={{ marginBottom: 34 }}>{sectionTitles[1]}</Heading>

            <div className={styles.event__contacts}>

              <div className={styles.event__locationAndDate}>

                <div className={styles.event__info}>

                  <div className={styles.event__column}>
                    {renderIcon('calendar', styles.event__icon)}
                    <Body variant="S">{startDateInfo.day} - {endDateInfo.day}, {startDateInfo.month} {startDateInfo.year}</Body>
                  </div>

                  {event.eventUrl && (
                    <div className={styles.event__column}>
                      {renderIcon('computer', styles.event__icon)}
                      <Body variant="S">
                        <Link to={event.eventUrl} target="_blank" rel="noopener noreferrer">Event reference</Link>
                      </Body>
                    </div>
                  )}

                </div>

                <div>
                  {event.addressPlace && <Heading variant="H5" style={{ marginBottom: 13 }}>{event.addressPlace}</Heading>}
                  <Body variant="M">{event.address}</Body>
                </div>

              </div>

              <div className={styles.event__mapWrapper}>
                <Map
                  className={styles.event__map}
                  defaultCenter={event.location}
                  defaultZoom={event.zoom}
                  gestureHandling="cooperative"
                  disableDefaultUI
                >
                  <Marker position={event.location} />
                </Map>
              </div>

            </div>

          </div>

          {!sectionItems[2].hide && (
            <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.event__section}>
              <Heading variant="H4" style={{ marginBottom: 41 }}>{sectionTitles[2]}</Heading>
              <EventForm />
            </div>
          )}

        </PageWithSidebar>
      </APIProvider>
    </>
  );
};

export default Event;
