const patients = '/patients';
const howItWorks = `${patients}/how-it-works`;
const submitSample = `${patients}/submit-sample`;
const faq = `${patients}/faq`;

const urls = {
  patients,
  howItWorks,
  submitSample,
  faq,
};

export default urls;
