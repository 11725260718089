import 'components/Typography/Caption/Caption.scss';

import React, { CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  variant?: 'L' | 'M' | 'S' | 'XS';
  style?: CSSProperties;
  className?: string;
}

const Caption: React.FC<Props> = ({ children, style, variant = 'S', className }) => {
  const classes = cn('caption', `caption_${variant}`, className);

  return (
    <span className={classes} style={style}>{children}</span>
  );
};

export default Caption;
