import styles from 'pages/GalateaDifference/About/components/AboutUs/AboutUs.module.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { galateaDifferenceUrls } from 'app/routing';
import Button from 'components/Button/Button';
import { Body, Heading } from 'components/Typography';
import AboutLink from 'pages/GalateaDifference/About/components/AboutLink/AboutLink';

import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  const isPatientVersion = useIsPatientVersion();
  const [ isOpened, setIsOpened ] = useState<boolean>(false);

  const onReadFullClick = () => setIsOpened(true);

  return (
    <div className={cn(styles.aboutUs, { [styles.aboutUs_patients]: isPatientVersion, [styles.aboutUs_opened]: isOpened })}>

      <div>
        <Heading variant="H2">{t('menuItems.aboutUs')}</Heading>

        <div className={styles.aboutUs__links}>
          <AboutLink text={t('menuItems.leadershipTeam')} icon="user-group" linkTo={galateaDifferenceUrls.leadership} />
          <AboutLink text={t('menuItems.publications')} icon="news" linkTo={galateaDifferenceUrls.publications} />
          <AboutLink text={t('menuItems.events')} icon="calendar" linkTo={galateaDifferenceUrls.events} />
          <AboutLink text={t('menuItems.news')} icon="news" linkTo={galateaDifferenceUrls.news} />
          <AboutLink text={t('menuItems.compliance')} icon="contracts" linkTo={galateaDifferenceUrls.compliance} />
          <AboutLink text={t('menuItems.licenses')} icon="check" linkTo={galateaDifferenceUrls.licenses} />
        </div>
      </div>

      <div className={styles.aboutUs__rightBlock}>
        <Body variant="L">
          Galatea Bio is a biotechnology company committed to driving groundbreaking discoveries in healthcare by pairing genetic diversity
          with cutting-edge data analytics. Our passion is fueled by a focus on populations historically underrepresented in genetics research.
          We achieve this by leveraging our world-class clinical network and biobank, cutting-edge algorithms, and innovative analyses to discover
          and validate new tests and treatments that will improve the lives of all.
        </Body>

        <Body variant="L">
          At Galatea Bio, we are fortunate to have access to the latest technology in the field, thanks to our partnerships with Stanford University
          and the Chan Zuckerberg Initiative. While staying at the forefront of genetic research and analysis, we are relentless in our quest to innovate
          in methods to interrogate genomes.
        </Body>

        <Body variant="L">
          A future where healthcare is accessible to all and tailored to each individual&apos;s genetic makeup is not just possible, but essential.
          We are dedicated to helping realize this future by identifying and overcoming barriers to progress and to stay ahead of the curve.
        </Body>

        <Body variant="L">
          Genetic diversity is a key catalyst to unlocking the secrets of the genome. By analyzing a wide range of genetic data from diverse populations,
          we are already gaining new insights into the biological processes that underlie health and disease—a process we are focused at accelerating.
          This is a critical step towards realizing a future where healthcare is truly personalized and equitable.
        </Body>

        <Body variant="L">
          We invite you to join us on this mission to revolutionize healthcare through genetic diversity and data analytics.
          You can choose to opt-in to participate in our research when selecting a genetic testing product from our catalog.
          Together we look forward to contributing to the discovery of new tests, treatments and cures that will provide a healthier future to all.
        </Body>

        {!isOpened && <Button size="L" onClick={onReadFullClick} className={styles.aboutUs__button}>{t('general.readFull')}</Button>}
      </div>

      {!isOpened && <div className={styles.aboutUs__blur} />}

    </div>
  );
};

export default AboutUs;
