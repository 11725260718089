import styles from 'pages/GalateaDifference/About/components/LatestEvents/LatestEvents.module.scss';
import { EventItem, EventsResponse } from 'api/Events/types';
import { galateaDifferenceUrls } from 'app/routing';
import { Heading } from 'components/Typography';
import LatestEvent from 'pages/GalateaDifference/About/components/LatestEvents/components/LatestEvent/LatestEvent';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  events: EventsResponse;
}

const LatestEvents: React.FC<Props> = ({ events }) => {
  const { t } = useTranslation();

  const eventList: EventItem[] = useMemo(() => {
    let eventItems: EventItem[];
    eventItems = events.upcomingEvents.slice(0, 3);

    if (eventItems.length < 3) {
      eventItems = [ ...eventItems, ...events.pastEvents.slice(0, 3 - eventItems.length) ];
    }

    return eventItems;
  }, [ events ]);

  return (
    <div className={styles.latestEvents} data-cy="latest-events">

      <div className={styles.latestEvents__topLine}>
        <Heading variant="H4">{t('galateaDifference.latestEvents')}</Heading>

        <Link to={galateaDifferenceUrls.events}>
          <Heading variant="H6">{t('welcomePage.showAll')}</Heading>
        </Link>
      </div>

      {eventList.map(event => <LatestEvent event={event} key={event.id} />)}

    </div>
  );
};

export default LatestEvents;
