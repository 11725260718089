import styles from 'pages/GalateaDifference/LeadershipTeam/components/TeamMember/TeamMember.module.scss';
import { ReactComponent as Logo } from 'app/assets/Logo.svg';
import { TeamMember as TeamMemberType } from 'api/Leadership/types';
import { Body, Caption, Heading } from 'components/Typography';

import React from 'react';

interface Props {
  teamMember: TeamMemberType
}

const TeamMember: React.FC<Props> = ({ teamMember }) => {
  return (
    <div className={styles.teamMember}>

      <div className={styles.teamMember__header}>

        <div className={styles.teamMember__leftColumn}>
          <div className={styles.teamMember__photo}>
            <div style={{ backgroundImage: `url(${teamMember.photo})` }} />
          </div>

          <div className={styles.teamMember__logo}>
            <Logo />
          </div>
        </div>

        <div className={styles.teamMember__rightColumn}>
          <Heading variant="H4" className={styles.teamMember__title}>{teamMember.title}</Heading>

          <Caption variant="L" className={styles.teamMember__jobTitle}>{teamMember.jobTitle}</Caption>
        </div>

      </div>

      <Body variant="L" className={styles.teamMember__text}>{teamMember.description}</Body>

    </div>
  );
};

export default TeamMember;
