import styles from 'pages/GalateaDifference/Licenses/Licenses.module.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import { Body, Heading } from 'components/Typography';
import Certificate from 'pages/GalateaDifference/Licenses/component/Certificate/Certificate';
import useCertificates from 'pages/GalateaDifference/Licenses/hooks/useCertificates';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Helmet } from 'react-helmet-async';

const Licenses: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const isPatientVersion = useIsPatientVersion();
  const { fetchCertificates, certificates, isLoading } = useCertificates();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.licenses') },
  ], [ t ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCertificates(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchCertificates ]);

  useEffect(
    () => setPageHeader(<PageHeader title={t('menuItems.licenses')} breadcrumbItems={breadcrumbItems} />),
    [ setPageHeader, breadcrumbItems, t ],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title="Licenses and accreditations" />

      <div className={cn(styles.licenses, { [styles.licenses_patients]: isPatientVersion })}>

        <Body variant="L" className={styles.licenses__text}>
          Galatea Bio is certified under the Clinical Laboratory Improvement Amendments (CLIA) and accredited
          by the College of American Pathologists (CAP).
        </Body>

        <div className={styles.licenses__content}>
          <Heading variant="H3" className={styles.licenses__title}>National Certificates</Heading>

          {certificates.map(c => <Certificate certificate={c} key={c.title} />)}
        </div>

      </div>
    </>
  );
};

export default Licenses;
