import 'components/Loader/Loader.scss';
import blue from 'components/Loader/assets/spinner_blue.png';
import white from 'components/Loader/assets/spinner_white.png';

import React, { useEffect, useState } from 'react';

interface Props {
  color?: 'blue' | 'white';
}

const Loader: React.FC<Props> = ({ color }) => {
  const [ height, setHeight ] = useState<number>(80);

  useEffect(() => {
    const wrapper = document.querySelector('.headerWrapper');
    if (wrapper) {
      setHeight(wrapper.clientHeight);
    }
  }, []);

  return (
    <div className="loader" style={{ top: height }}>
      <div
        className="loader__circle"
        style={{ backgroundImage: `url(${color === 'white' ? white : blue})`, transform: `translate(-50%, calc(-50% - ${height}px))` }}
      />
    </div>
  );
};

export default Loader;
