const legal = '/legal';
const cookiePolicy = `${legal}/cookie-policy`;
const privacyPolicy = `${legal}/privacy-policy`;
const hipaaNotice = `${legal}/hipaa-notice`;
const dataGovernance = `${legal}/data-governance`;
const researchDataGovernance = `${legal}/research-data-governance`;

const urls = {
  cookiePolicy,
  privacyPolicy,
  hipaaNotice,
  dataGovernance,
  researchDataGovernance,
};

export default urls;
