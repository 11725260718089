import 'components/CardiometabolicConditions/CardiometabolicConditions.scss';
import { renderIcon } from 'app/helpers/icons';
import { Body } from 'components/Typography';

import React from 'react';

const CardiometabolicConditions: React.FC = () => {
  return (
    <div className="cardiometabolicConditions">

      <div className="cardiometabolicConditions__item">
        {renderIcon('atrial-fibrillation')}
        <Body variant="S">Atrial Fibrillation</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('coronary-artery')}
        <Body variant="S">Coronary Artery Disease</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('hypertension')}
        <Body variant="S">Hypertension</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('diabetes-1')}
        <Body variant="S">Type 1 Diabetes</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('diabetes-2')}
        <Body variant="S">Type 2 Diabetes</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('elevated-triglycerides')}
        <Body variant="S">Elevated Triglycerides</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('decreased-hdl')}
        <Body variant="S">Decreased High-Density Lipoprotein</Body>
      </div>

      <div className="cardiometabolicConditions__item">
        {renderIcon('increased-ldl')}
        <Body variant="S">Elevated Low-Density Lipoprotein</Body>
      </div>

    </div>
  );
};

export default CardiometabolicConditions;
