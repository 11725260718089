import styles from 'pages/GalateaDifference/LeadershipTeam/LeadershipTeam.module.scss';
import { TeamMember as TeamMemberType } from 'api/Leadership/types';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import RightSidebar from 'components/RightSidebar/RightSidebar';
import { Heading } from 'components/Typography';
import TeamMember from 'pages/GalateaDifference/LeadershipTeam/components/TeamMember/TeamMember';
import TeamMemberListItem from 'pages/GalateaDifference/LeadershipTeam/components/TeamMemberListItem/TeamMemberListItem';
import useLeadership from 'pages/GalateaDifference/LeadershipTeam/hooks/useLeadership';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const LeadershipTeam: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const { fetchLeadership, leadership, isLoading } = useLeadership();
  const [ viewedTeamMember, setViewedTeamMember ] = useState<null | TeamMemberType>(null);
  const openSidebar = (member: TeamMemberType) => setViewedTeamMember(member);
  const closeSidebar = () => setViewedTeamMember(null);

  const sectionTitles = useMemo(() => [
    t('galateaDifference.leadershipTeam'),
    t('galateaDifference.boardOfDirectors'),
    t('galateaDifference.scientificAdvisors'),
  ], [ t ]);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('galateaDifference.leadershipTitle') },
  ], [ t ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchLeadership(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchLeadership ]);

  useEffect(() => {
    setPageHeader(<PageHeader title={t('galateaDifference.leadershipTitle')} breadcrumbItems={breadcrumbItems} />);
  }, [ setPageHeader, breadcrumbItems, isLoading, t ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title="Leadership Team" />

      <PageWithSidebar sidebarItems={sidebarItems} className={styles.leadershipTeam}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.leadershipTeam__section}>
          <Heading variant="H3" className={styles.event__title}>{sectionTitles[0]}</Heading>

          <div className={styles.leadershipTeam__list}>
            {leadership.leadershipTeam.map(i => <TeamMemberListItem teamMember={i} key={i.title} onClick={openSidebar} />)}
          </div>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.leadershipTeam__section}>
          <Heading variant="H3" className={styles.event__title}>{sectionTitles[1]}</Heading>

          <div className={styles.leadershipTeam__list}>
            {leadership.directors.map(i => <TeamMemberListItem teamMember={i} key={i.title} onClick={openSidebar} />)}
          </div>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.leadershipTeam__section}>
          <Heading variant="H3" style={{ marginBottom: 34 }}>{sectionTitles[2]}</Heading>

          <div className={styles.leadershipTeam__list}>
            {leadership.scientificAdvisors.map(i => <TeamMemberListItem teamMember={i} key={i.title} onClick={openSidebar} />)}
          </div>
        </div>
      </PageWithSidebar>

      <RightSidebar
        title=""
        content={viewedTeamMember ? <TeamMember teamMember={viewedTeamMember} /> : ''}
        opened={!!viewedTeamMember}
        onClose={closeSidebar}
      />
    </>
  );
};

export default LeadershipTeam;
