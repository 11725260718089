import 'components/Header/components/Logo/Logo.scss';
import { ReactComponent as ColoredLogo } from 'app/assets/ColoredLogo.svg';
import { ReactComponent as GalateaBio } from 'app/assets/GalateaBio.svg';
import { ReactComponent as LogoIcon } from 'app/assets/Logo.svg';
import { getIconByType } from 'app/helpers/icons';
import { Caption } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  onClick?: () => void;
}

const Logo: React.FC<Props> = ({ onClick }) => {
  const logoMouseOver = () => {
    const galatea = document.querySelector('.logo__galatea');
    const backTo = document.querySelector('.logo__backTo');
    const clinicalTesting = document.querySelector('.logo__clinicalTesting');
    if (galatea && backTo && clinicalTesting) {
      galatea.classList.add('logo__galatea_hovered');
      backTo.classList.add('logo__backTo_hovered');
      clinicalTesting.classList.add('logo__clinicalTesting_hovered');
    }
  };

  const logoMouseOut = () => {
    const galatea = document.querySelector('.logo__galatea');
    const backTo = document.querySelector('.logo__backTo');
    const clinicalTesting = document.querySelector('.logo__clinicalTesting');
    if (galatea && backTo && clinicalTesting) {
      galatea.classList.remove('logo__galatea_hovered');
      backTo.classList.remove('logo__backTo_hovered');
      clinicalTesting.classList.remove('logo__clinicalTesting_hovered');
    }
  };

  return (
    <div className="logo__wrapper" onClick={onClick}>

      <Link to="/" className="logo__mobileIcon">
        <LogoIcon className="logo__icon" />
      </Link>

      <Link
        className="logo__icons"
        to="https://galatea.bio/"
        onMouseOver={logoMouseOver}
        onMouseOut={logoMouseOut}
      >

        <ColoredLogo className="logo__icon logo__icon_colored" />

        <LogoIcon className="logo__icon" />

        <span className="logo__arrow">{getIconByType('chevron-left')}</span>

      </Link>

      <Link className="logo__text" to="/">

        <Caption variant="M" className="logo__backTo">Back to</Caption>

        <GalateaBio className="logo__galatea" />

        <Caption variant="L" className="logo__clinicalTesting">Clinical Testing</Caption>

        <Caption variant="M" className="logo__homepage">Homepage</Caption>

      </Link>

    </div>
  );
};

export default Logo;
