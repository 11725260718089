import 'pages/ProductCatalog/ProductCatalog.scss';
import { ProductCategoryItem } from 'api/Products/types';
import { catalogUrls } from 'app/routing';
import { renderIcon } from 'app/helpers/icons';
import { Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import ProductBlock from 'pages/ProductCatalog/components/ProductBlock/ProductBlock';
import { Caption, Heading } from 'components/Typography';
import useProductCategories from 'pages/ProductCatalog/hooks/useProductCategories';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const ProductCatalog: React.FC<Page> = ({ setPageHeader }) => {
  const { fetchProductCategories, productCategories, isLoading } = useProductCategories();

  useEffect(() => {
    setPageHeader(undefined);
  }, [ setPageHeader ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchProductCategories(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchProductCategories ]);

  const GeneticTestingBottomContent = (
    <>
      <div className="productCatalog__genes">
        <Heading variant="H1">{productCategories.commonInfo.genesTotal}</Heading>
        <Caption variant="XS" className="productCatalog__total">Genes in total</Caption>
      </div>
      <div className="productCatalog__icons">
        {productCategories.commonInfo.commonIcons.map(icon => renderIcon(icon))}
      </div>
    </>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="productCatalog">

      <Helmet title="Product Catalog" />

      <ProductBlock
        title={productCategories.commonInfo.commonTitle}
        description={productCategories.commonInfo.commonDescription}
        primaryButton={{ text: 'Show Catalog', url: catalogUrls.panels }}
        bottomContent={GeneticTestingBottomContent}
        background={productCategories.commonInfo.commonImage}
      />

      {productCategories.items.map((product: ProductCategoryItem) => (
        <ProductBlock
          key={product.meta.slug}
          title={product.title}
          description={product.categoryDetails[0].value.detailsInfo}
          primaryButton={{ text: product.primaryButtonText, url: product.meta.slug }}
          secondaryButton={{ text: product.secondaryButtonText, url: catalogUrls.panelsWithSort(product.categoryName) }}
          background={product.categoryDetails[0].value.detailsImage}
        />
      ))}
    </div>
  );
};

export default ProductCatalog;
