import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollingEvents = () => {
  const { pathname, hash } = useLocation();
  const [ isPageAtTop, setIsPageAtTop ] = useState<boolean>(true);

  // scroll to top when a navigation event happens
  useEffect(() => {
    if (!hash) {
      const timer = setTimeout(() => window.scrollTo(0, 0), 50);

      return () => clearTimeout(timer);
    } else {
      const decodedHash = decodeURI(hash).replace('#', '');
      const section = document.getElementById(decodedHash);
      if (section) {
        window.scrollTo(0, section.offsetTop + 2);
      }
    }
  }, [ pathname, hash ]);

  useEffect(() => {
    const handleScroll = () => {
      setIsPageAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isPageAtTop };
};

export default useScrollingEvents;
