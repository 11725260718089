import styles from 'pages/GalateaDifference/Publications/Publications.module.scss';
import { createOptionsFromTags } from 'app/helpers/catalog';
import { formatDate } from 'app/helpers/dateTime';
import usePaginationParams from 'app/hooks/usePaginationParams';
import usePublications from 'app/hooks/usePublications';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import Tag from 'components/Tag/Tag';
import { Heading } from 'components/Typography';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Publications: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const { searchParam, sortByParam } = usePaginationParams();
  const { fetchPublications, publications, isLoading } = usePublications();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.publications'), link: searchParam ? galateaDifferenceUrls.publications : undefined },
  ], [ t, searchParam ]);

  const sortByOptions = useMemo(() => {
    const options = createOptionsFromTags(publications.allTags);
    options.unshift({ value: '', label: 'All publications' });

    return options;
  }, [ publications.allTags ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchPublications(sortByParam, searchParam, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchPublications, sortByParam, searchParam ]);

  useEffect(() => {
    setPageHeader(<PageHeader
      title={t('menuItems.publications')}
      breadcrumbItems={breadcrumbItems}
      rightColumn={<PageHeaderActions sortByOptions={sortByOptions} />}
    />);
  }, [ setPageHeader, breadcrumbItems, t, sortByOptions ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title="Publications" />

      <div className={styles.publications}>
        {publications.items.map(item => (
          <div className={styles.publications__item} key={item.id}>
            <Link to={galateaDifferenceUrls.publicationsDetailUrl(item.meta.slug)} className={styles.publications__itemTitle}>
              <Heading variant="H3">{item.title}</Heading>
            </Link>

            <div className={styles.publications__meta}>
              <Heading variant="H5">{formatDate(item.publicationDate)}</Heading>

              <div className={styles.publications__tagsList}>
                {item.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Publications;
