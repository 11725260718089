import styles from 'pages/Legal/LegalPage.module.scss';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import { Body, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const CCCPS: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.compliance'), link: galateaDifferenceUrls.compliance },
    { text: 'California Comprehensive Compliance Program Statement' },
  ], [ t ]);
  const year = new Date().getFullYear();
  const month = year >= 2025 ? 'January' : 'October';

  useEffect(
    () => setPageHeader(
      <PageHeader breadcrumbItems={breadcrumbItems} title="California Comprehensive Compliance Program Statement" />,
    ),
    [ setPageHeader, breadcrumbItems ],
  );

  return (
    <>
      <Helmet title="California Comprehensive Compliance Program Statement" />

      <div className={styles.legalPage}>
        <Body variant="L">
          Galatea Bio, Inc.<br />
          Statement of Compliance with the California Comprehensive Compliance Program
        </Body>

        <Body variant="L">
          Galatea Bio, Inc. (“Galatea”) provides this statement as required by the California Health and Safety Code §§ 119400-119402 (“Code”).
          The Code requires drug and certain medical device manufacturers to adopt and attest to having a Comprehensive Compliance Program that is in
          accordance with HHS and OIG guidance.
        </Body>

        <Body variant="L">
          Galatea has a robust and fully integrated Comprehensive Compliance Program (“Program”) that adheres to the AdvaMed Code of Ethics on Interactions
          with US Health Care Professionals, HHS-OIG General Compliance Program Guidance and Model Compliance Plan for Clinical Laboratories.
          The Program applies to all employees, officers, directors, contractors, and board members (“associates”). The California Code references 
          required adherence to the PhRMA Code on Interactions with Health Care Professionals and the 2003 OIG Compliance Guidance for Pharmaceutical
          Manufacturers, but because these documents do not directly apply to the clinical laboratory business conducted by Galatea, Galatea has instead
          adopted the more appropriately focused AdvaMed Code and noted HHS-OIG guidance.
        </Body>
        
        <Body variant="L">
          The Galatea Compliance Program encompasses the seven elements of an effective compliance program including a published code of conduct
          and policies and procedures that establish the company’s expectations and standards. The Chief Compliance Officer, members of the Compliance
          Department and Corporate Compliance Committee work together to implement, monitor, audit, train and educate Galatea associates on these requirements.
          Galatea provides multiple avenues for reporting potential violations of these standards without fear of retaliation.
          The Compliance Department responds promptly to suspected violations of these standards and provides appropriate corrective and disciplinary actions.
        </Body>

        <Body variant="L">
          Galatea has clear policies that outline the limits or exclusion of the provision of gifts or incentives to Health Care Professionals (“HCPs”)
          to ensure compliance with the noted guidance as well as the Federal Anti-kickback Statute, Physician Self-Referral Law (Stark Law),
          and other fraud and abuse laws. These policies follow the annual published non-monetary compensation dollar limits imposed by the federal
          government to ensure allowable expenditures are reported as required under the Physician Payments Sunshine Act.
        </Body>

        <Body variant="L">
          Galatea certifies to the best of its knowledge that its Program meets the expectations of the Code and shall annually attest as such.
          Any required changes to the Program will be made within 6 months of published changes to applicable HHS-OIG or AdvaMed guidance.
          A copy of this statement and other published compliance related documents may be obtained by calling +1 754 233 3350.
        </Body>

        <Heading variant="H5">*Attested annually, {month} 1, {year}.</Heading>
      </div>
    </>
  );
};

export default CCCPS;
