import Context from 'app/consts/context';

import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PatientRoot: React.FC = () => {
  const { setSiteVersion } = useContext(Context);

  useEffect(() => {
    setSiteVersion('patients', false);
  });

  return (
    <>
      <Helmet titleTemplate="Galatea Bio - Patients - %s" />
      <Outlet />
    </>
  );
};

export default PatientRoot;
