import 'components/Select/Select.scss';
import { ProductCategory } from 'api/Products/types';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import usePaginationParams from 'app/hooks/usePaginationParams';
import { Option } from 'app/types/ProductCatalog';
import calculateWidth from 'components/Select/helpers';
import { Body } from 'components/Typography';

import React, { useEffect } from 'react';
import cn from 'classnames';

export interface Props {
  sortByOptions: Option[];
}

const Select: React.FC<Props> = ({ sortByOptions }) => {
  const { searchParam, sortByParam, setFilter } = usePaginationParams();
  const isPatientVersion = useIsPatientVersion();

  useEffect(() => {
    const select = document.querySelector('#sort-by')!;
    const tmpSelect = document.querySelector('#tmp-select')!;
    calculateWidth(select, tmpSelect);
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target, currentTarget } = e;
    const tmpSelect = document.querySelector('#tmp-select')!;

    calculateWidth(target, tmpSelect);
    setFilter({ sortBy: currentTarget.value as ProductCategory, search: searchParam });
  };

  return (
    <div className="select">
      <Body variant="S">Sort by</Body>

      <select value={sortByParam || ''} name="sort-by" id="sort-by" className={cn({ patients: isPatientVersion })} onChange={onChange}>
        {sortByOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
      </select>

      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <select id="tmp-select"><option /></select>
    </div>
  );
};

export default Select;
