import styles from 'pages/Legal/LegalPage.module.scss';
import useIntersectionProps from 'app/hooks/useIntersectionProps';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page, SectionItem, SidebarItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Table, TableRow } from 'components/Table';
import { Body, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const ESG: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const props = useIntersectionProps();

  const { ref: ref1, inView: ref1InView } = useInView(props);
  const { ref: ref2, inView: ref2InView } = useInView(props);
  const { ref: ref3, inView: ref3InView } = useInView(props);
  const { ref: ref4, inView: ref4InView } = useInView(props);
  const { ref: ref5, inView: ref5InView } = useInView(props);
  const { ref: ref6, inView: ref6InView } = useInView(props);
  const { ref: ref7, inView: ref7InView } = useInView(props);
  const { ref: ref8, inView: ref8InView } = useInView(props);
  const { ref: ref9, inView: ref9InView } = useInView(props);
  const menuText1 = 'Introduction';
  const menuText2 = 'ESG at Galatea Bio';
  const menuText3 = 'ESG Integration in Galatea Bio’s Business Processes';
  const menuText4 = 'ESG Monitoring and Engagement';
  const menuText5 = 'Focus Areas';
  const menuText6 = 'Engagement';
  const menuText7 = 'Reporting';
  const menuText8 = 'Roles and Responsibilities';
  const menuText9 = 'Scope';
  const sectionItems: SectionItem[] = [
    { id: menuText1, ref: ref1 },
    { id: menuText2, ref: ref2 },
    { id: menuText3, ref: ref3 },
    { id: menuText4, ref: ref4 },
    { id: menuText5, ref: ref5 },
    { id: menuText6, ref: ref6 },
    { id: menuText7, ref: ref7 },
    { id: menuText8, ref: ref8 },
    { id: menuText9, ref: ref9 },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: menuText1, inView: ref1InView },
    { text: menuText2, inView: ref2InView && !ref1InView },
    { text: menuText3, inView: ref3InView && !ref2InView },
    { text: menuText4, inView: ref4InView && !ref3InView },
    { text: menuText5, inView: ref5InView && !ref4InView },
    { text: menuText6, inView: ref6InView && !ref5InView },
    { text: menuText7, inView: ref7InView && !ref6InView },
    { text: menuText8, inView: ref8InView && !ref7InView },
    { text: menuText9, inView: ref9InView && !ref8InView },
  ], [
    ref1InView,
    ref2InView,
    ref3InView,
    ref4InView,
    ref5InView,
    ref6InView,
    ref7InView,
    ref8InView,
    ref9InView,
  ]);
  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.compliance'), link: galateaDifferenceUrls.compliance },
    { text: 'Environmental, Social, and Governance (ESG) Policy' },
  ], [ t ]);

  useEffect(
    () => setPageHeader(
      <PageHeader sidebarItems={sidebarItems} title="Environmental, Social, and Governance (ESG) Policy" breadcrumbItems={breadcrumbItems} />,
    ),
    [ setPageHeader, sidebarItems, breadcrumbItems ],
  );

  return (
    <>
      <Helmet title="Environmental, Social, and Governance (ESG) Policy" />

      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText1}</Heading>

          <Body variant="L">
            Our mission at Galatea Bio is to fulfill our fiduciary duty by creating long-term value for our investors and shareholders.
            Accordingly, Galatea Bio is committed to integrating Environmental, Social, and Governance (ESG) principles into our clinical, scientific,
            and business processes and operating philosophy where these efforts can contribute to value creation.
          </Body>

          <Body variant="L">
            This ESG Policy outlines our firm-wide approach to integrating ESG in our business and activities.
          </Body>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText2}</Heading>

          <Body variant="L">
            Galatea Bio is committed to integrating certain ESG factors throughout its own corporate operations with a focus on matters that are meaningful
            to our employees and investors. We seek to apply our insights to drive value across our portfolio by:
          </Body>

          <ul>
            <li>
              Fostering diverse talent recruitment and retention
            </li>
            <li>
              Providing career and other opportunities in communities where we operate
            </li>
            <li>
              Training applicable full-time employees and certain other temporary personnel, consultants, and advisers through Annual Compliance Training,
              which includes topics such as data protection and privacy, our Code of Ethics, and fiduciary duties / conflicts of interest, among others,
              in addition to an initial training during their on-boarding
            </li>
            <li>
              Engaging the entire firm annually through our cybersecurity awareness program to educate our employee population to recognize suspicious
              activities and report them for investigation
            </li>
          </ul>

          <Body variant="L">
            Galatea Bio seeks to adhere to applicable laws and regulations, including on modern slavery and forced labor.
          </Body>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={cn(styles.legalPage__section, styles.legalPage__section_lastChildIsTable)}>
          <Heading variant="H4">{menuText3}</Heading>

          <Body variant="L">
            The integration of material ESG factors 1 into our business and operational decisions is an important part of fulfilling our fiduciary duty.
          </Body>

          <Body variant="L">
            Based on our experience, we think that consideration of ESG factors not only enhances our assessment of risk but also helps us identify
            opportunities for value creation. We believe that our targeted ESG program can help strengthen the company, drive value, enhance returns,
            and create better outcomes.
          </Body>

          <Body variant="L">
            As applicable and material to any given business or operational decision, the ESG factors that may be incorporated into our evaluation
            and monitoring processes include, but are not limited to, the following:
          </Body>

          <Table columnsWidth={[ '33.3%', '33.3%', '33.3%' ]} id="furtherAssistance">
            <TableRow highlighted>
              <div>
                <Heading variant="H5" style={{ marginBottom: 0 }}>Environmental Considerations</Heading>
              </div>

              <div>
                <Heading variant="H5" style={{ marginBottom: 0 }}>Social Considerations</Heading>
              </div>

              <div>
                <Heading variant="H5" style={{ marginBottom: 0 }}>Governance Considerations</Heading>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <ul style={{ marginBottom: 0 }}>
                  <li>Greenhouse gas emissions</li>
                  <li>Air pollution</li>
                  <li>Waste management (including land and water impact)</li>
                  <li>Energy management and efficiency</li>
                  <li>Land use</li>
                  <li>Climate risk</li>
                </ul>
              </div>

              <div>
                <ul style={{ marginBottom: 0 }}>
                  <li>Diversity, Equity & Inclusion (including anti-discrimination)</li>
                  <li>Human rights and modern slavery</li>
                  <li>Employee health and safety</li>
                  <li>Labor relations and practices</li>
                  <li>Customer privacy and security</li>
                  <li>Product quality and safety</li>
                </ul>
              </div>

              <div>
                <ul style={{ marginBottom: 0 }}>
                  <li>Corporate governance and oversight</li>
                  <li>Risk management</li>
                  <li>Conflicts of interest</li>
                  <li>Transparency (including financial and operational reporting)</li>
                  <li>Fraud, anti-bribery, and anti-corruption controls</li>
                </ul>
              </div>
            </TableRow>
          </Table>
        </div>

        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText4}</Heading>

          <Heading variant="H5">Survey & Data Collection</Heading>

          <Body variant="L">
            We generally use an annual ESG survey and data collection process to monitor certain ESG initiatives and measures central to our operations.
            We use this data to report to our board regarding ESG on a regular basis, with respect to priority ESG topics that are considered
            to be material to our business.
          </Body>

          <Heading variant="H5">Company-Wide Engagement</Heading>

          <Body variant="L">
            We strive to implement best practices through offering tools, training, and expertise; manage material ESG factors;
            implement Galatea Bio-specific initiatives; and measure progress company-wide.
          </Body>

          <Body variant="L">
            We also have firm-wide programs, including:
          </Body>

          <ul>
            <li>
              Resources to help broaden the pool of talent from which operating units recruit at all levels—including their leadership teams—as well as
              how they develop and advance that talent.
            </li>
          </ul>

          <Body variant="L">
            As owners and operators, we seek to encourage and implement appropriate governance structures, policies, controls, and processes at Galatea Bio
            to strengthen our company and thereby enhance returns.
          </Body>
        </div>

        <div id={sectionItems[4].id} ref={sectionItems[4].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText5}</Heading>

          <Body variant="L">
            Across our corporate activities, we have identified priority ESG topics that we believe can most affect our ability
            to build a strong company of enduring value.
          </Body>

          <Heading variant="H5">Climate Change Mitigation, Resiliency, and Adaptation</Heading>

          <Body variant="L">
            We recognize that climate change is a systemic issue, affecting all sectors and geographies. Our goal is to drive value through a range
            of strategic environmental considerations and initiatives. Over time we intend to assess physical and transition risks to certain operations
            from climate change, and develop resiliency responses to these risks, where applicable, as part of our overall commitment to environmental
            and climate priorities.
          </Body>

          <Heading variant="H5">Diversity, Equity, and Inclusion (DEI)</Heading>

          <Body variant="L">
            We aim to drive value by supporting our recruitment, development, and advancement of top talent at all levels from a broad range of talent networks.
            Galatea Bio is committed to develop and advance that talent through a culture of inclusivity.
          </Body>

          <Heading variant="H5">Good Governance</Heading>

          <Body variant="L">
            We believe that good corporate governance is essential for financially sound companies. We seek to implement good governance practices
            spanning board governance to controls. Specifically, we prioritize an experienced, active, and engaged board of directors with the skill
            to properly oversee and direct management and encourage protocols to enhance transparency and good governance.
          </Body>
        </div>

        <div id={sectionItems[5].id} ref={sectionItems[5].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText6}</Heading>

          <Body variant="L">
            We regularly engage with our suppliers, contractors, investors, stakeholders, and industry on ESG matters.
            As applicable, ESG matters are discussed at Galatea Bio’s quarterly board meetings.
          </Body>
        </div>

        <div id={sectionItems[6].id} ref={sectionItems[6].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText7}</Heading>

          <Body variant="L">
            We are committed to being transparent with our investors, shareholders, and other stakeholders about Galatea Bios ESG initiatives,
            successes, and goals. Our ESG report provides updates regarding our ESG program firm-wide, and we provide ESG updates to our board no
            less frequently than annually. We value regular, frequent engagement with our stakeholders on ESG matters.
          </Body>
        </div>

        <div id={sectionItems[7].id} ref={sectionItems[7].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText8}</Heading>

          <Body variant="L">
            Galatea Bio’s CEO and Chief Administrative Officer (CAO) have ultimate responsibility for overseeing Galatea Bio’s ESG Policy.
          </Body>

          <Body variant="L">
            The corporate ESG team coordinates initiatives across the firm to maintain a coordinated approach.
            The Legal & Compliance teams at Galatea Bio, are responsible for supporting and ensuring compliance with additional
            ESG policies and related standards and overseeing their annual review. The compliance team also monitors changes or updates to industry trends,
            policy, and regulations and provides specialized expertise to the company and its leadership as needed.
          </Body>
        </div>

        <div id={sectionItems[8].id} ref={sectionItems[8].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText9}</Heading>

          <Body variant="L">
            This ESG Policy covers Galatea Bio’s investments and business units, as applicable, and is reviewed generally on an annual basis to
            reflect changes to the business. In cases where Blackstone determines it has limited ability to conduct diligence or to influence and control
            the consideration of ESG issues in connection with its operations, Galatea Bio will only apply those elements of this ESG Policy and the
            foregoing approaches that it determines to be practicable considering the underlying facts and circumstances.
          </Body>

          <Body variant="L">
            This ESG Policy was developed in September 2024 and is subject to change as the firm considers necessary or advisable. This policy is intended
            to be reviewed approximately annually. Further, this policy shall apply on a going-forward basis, subject to the limitations discussed herein,
            to the firm’s existing and future investments.
          </Body>
        </div>
      </PageWithSidebar>
    </>
  );
};

export default ESG;
