import { Icon } from 'app/types/Icons';
import { Option } from 'app/types/ProductCatalog';

export const getProductTypeTitle = (isPrs: boolean) => {
  if (isPrs) {
    return 'Health Risk Scoring';
  } else {
    return 'Next Generation Sequencing (NGS)';
  }
};

export const createOptionsFromTags = (tags: string[]): Option[] => {
  return tags.map(t => ({ value: t, label: t }));
};

export const getFileIcon = (fileName: string): Icon => {
  const parts = fileName.split('.');
  const extension = parts[parts.length - 1].toLowerCase();

  return extension === 'pdf' ? 'pdf' : 'file';
};
