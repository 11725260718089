import styles from 'pages/Provider/OrderTest/OrderTest.module.scss';
import { ReactComponent as Logo } from 'app/assets/Logo.svg';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { providersUrls } from 'app/routing';
import { Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import SampleCollectionInstructions from 'components/SampleCollectionInstructions/SampleCollectionInstructions';
import { Body, Caption, Heading } from 'components/Typography';
import OrderTestItem from 'pages/Provider/OrderTest/components/OrderTestItem/OrderTestItem';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const OrderTest: React.FC<Page> = ({ setPageHeader }) => {
  const sectionTitles = useMemo(() => [ 'Order a Test on ELIS / LIMS', 'Collection Instructions' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title={sectionTitles[0]} />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.orderTest}>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref}>

          <Heading variant="H3" className={styles.orderTest__title}>{sectionTitles[0]}</Heading>

          <div className={styles.orderTest__elis}>

            <div className={styles.orderTest__items}>
              <OrderTestItem title="Authorization on ELIS/LIMS portal" icon="finger-access" />
              <OrderTestItem title="Creating New Order" icon="shopping-cart-add" />
              <OrderTestItem title="Adding patient basic information" icon="contact" />
              <OrderTestItem title="Selecting panels" icon="lab" />
              <OrderTestItem title="Adding necessary medical information" icon="contracts" />
              <OrderTestItem title="Adding order details (shipping, notes, documents, etc.)" icon="contracts" />
              <OrderTestItem title="Send order and review progress in your account on Elis portal" icon="mail" />
            </div>

            <div className={styles.orderTest__download}>

              <Heading variant="H5">Access our detailed guide</Heading>

              <div className={styles.orderTest__guide}>
                <Logo className={styles.orderTest__logo} />

                <Caption variant="L">How to order a Test on ELIS/LIMS</Caption>

                <Body variant="S">Full step-by-step guide</Body>
              </div>

              <Link to={providersUrls.howItWorksAccessForm} className={styles.orderTest__button}>
                <Button size="L">Get access</Button>
              </Link>

            </div>

          </div>

        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref}>
          <SampleCollectionInstructions />
        </div>
      </PageWithSidebar>
    </>
  );
};

export default OrderTest;
