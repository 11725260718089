import styles from 'pages/GalateaDifference/Publications/components/Publication/Publication.module.scss';
import { formatDate } from 'app/helpers/dateTime';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import BodyItem from 'components/BodyItem/BodyItem';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import Tag from 'components/Tag/Tag';
import { Body, Heading } from 'components/Typography';
import usePieceOfNews from 'pages/GalateaDifference/News/components/PieceOfNews/hooks/usePieceOfNews';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PieceOfNews: React.FC<Page> = ({ setPageHeader }) => {
  const { newsSlug } = useParams<{ newsSlug: string }>();
  const { t } = useTranslation();
  const { fetchPieceOfNews, pieceOfNews, isLoading } = usePieceOfNews();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.news'), link: galateaDifferenceUrls.news },
    { text: pieceOfNews.title },
  ], [ t, pieceOfNews.title ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (newsSlug) {
      fetchPieceOfNews(newsSlug, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchPieceOfNews, newsSlug ]);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader title={t('galateaDifference.galateaNews')} breadcrumbItems={breadcrumbItems} />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, t, isLoading ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={pieceOfNews.title ? `News - ${pieceOfNews.title}` : 'News'} />

      <div className={styles.publication}>
        <div className={styles.publication__leftColumn}>
          <Heading variant="H5" className={styles.publication__date}>{formatDate(pieceOfNews.publicationDate)}</Heading>

          {pieceOfNews.authors && (
            <>
              <Heading variant="H6">{t('galateaDifference.authors')}</Heading>

              <Body variant="M" className={styles.publication__authors}>{pieceOfNews.authors}</Body>
            </>
          )}

          <Heading variant="H6">{t('galateaDifference.tags')}</Heading>

          <div className={styles.publication__tags}>
            {pieceOfNews.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
          </div>
        </div>

        <div>
          <Heading variant="H2" className={styles.publication__title}>{pieceOfNews.title}</Heading>
          {pieceOfNews.body.map(item => <BodyItem bodyItem={item} key={item.id} />)}
        </div>
      </div>
    </>
  );
};

export default PieceOfNews;
