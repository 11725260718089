import styles from 'pages/GalateaDifference/Compliance/Compliance.module.scss';
import { getFileIcon } from 'app/helpers/catalog';
import useBuiltInDocs from 'app/hooks/useBuiltInDocs';
import { galateaDifferenceUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import { Body, Heading } from 'components/Typography';
import AboutLink from 'pages/GalateaDifference/About/components/AboutLink/AboutLink';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Compliance: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const { docs, isLoading } = useBuiltInDocs();

  const codeOfConduct = useMemo(
    () => docs.find(doc => doc.documentType === 'Compliance_Code_of_Conduct'),
    [ docs ],
  );
  const supplierCodeOfConduct = useMemo(
    () => docs.find(doc => doc.documentType === 'Compliance_Supplier_Code_of_Conduct'),
    [ docs ],
  );
  const complianceHotline = useMemo(
    () => docs.find(doc => doc.documentType === 'Compliance_Compliance_Hotline'),
    [ docs ],
  );
  const noneRetaliationPolicy = useMemo(
    () => docs.find(doc => doc.documentType === 'Compliance_Non_Retaliation_Policy'),
    [ docs ],
  );

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: galateaDifferenceUrls.galateaDifference },
    { text: t('menuItems.compliance') },
  ], [ t ]);

  useEffect(
    () => setPageHeader(<PageHeader title="Compliance and Corporate Governance" breadcrumbItems={breadcrumbItems} />),
    [ setPageHeader, breadcrumbItems ],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title="Compliance" />

      <div className={styles.compliance}>

        <div className={styles.compliance__content}>
          <Body variant="L" style={{ marginBottom: 25 }}>
            At Galatea Bio, we manage our activities in a responsible way governed by Standards of Conduct. These guidelines are an extension of our values.
            They state expectations for how we conduct ourselves to promote and protect our organization’s integrity.
            We expect all our associates to comply with these standards.
          </Body>

          <Heading variant="H4" style={{ marginBottom: 25 }}>Standards of Conduct</Heading>

          <Heading variant="H5" style={{ marginBottom: 8 }}>Relationships with Others</Heading>

          <Body variant="M" style={{ marginBottom: 23 }}>
            We interact with others in a sincere and authentic manner. We develop relationships based on honesty, fairness and mutual trust.
            We act with dignity and mutual respect and do not discriminate against individuals on the basis of race, color, gender/sex, religion, age,
            national origin, citizenship, disability, genetic information, marital status, veteran status, or any other legally protected status.
          </Body>

          <Heading variant="H5" style={{ marginBottom: 8 }}>Compliance with Laws and Regulations</Heading>

          <Body variant="M" style={{ marginBottom: 23 }}>
            We comply with all laws and regulations applicable to Galatea Bio. We encourage associates to openly communicate concerns and report potential
            violations of applicable laws or regulations or our Standards of Conduct without fear of retaliation.
          </Body>

          <Heading variant="H5" style={{ marginBottom: 8 }}>Associate Experience</Heading>

          <Body variant="M" style={{ marginBottom: 23 }}>
            We strive to cultivate a work environment where associates’ uniqueness and the intrinsic worth of every individual is respected;
            where they are treated with dignity, respect, and compassion to foster a trusting work environment; where they are provided meaningful
            and holistic resources to live healthfully and practice safely; where all associates are recognized and rewarded for their achievements;
            where they are provided with the tools necessary to do their jobs well; where everyone has the opportunity to develop their skills
            and competencies and discover their purpose; where policies and procedures foster fair, consistent, and equitable treatment for all.
          </Body>

          <Heading variant="H5" style={{ marginBottom: 8 }}>Business and Ethical Practices and Confidentiality</Heading>

          <Body variant="M" style={{ marginBottom: 10 }}>
            We are committed to ethical business conduct and integrity. Associates must represent our organization accurately and honestly.
            They must not purposely defraud anyone – including other companies or the government – of money, property or services.
            Associates must also take all reasonable steps to preserve and protect Galatea Bio’s assets by making prudent and effective use of our resources,
            and properly and accurately reporting its financial condition.
          </Body>

          <Body variant="M" style={{ marginBottom: 23 }}>
            Associates have access to a variety of confidential and proprietary records and data, including personally identifiable information,
            patient information and company trade secrets. Associates are expected to: maintain the confidentiality of all organization information;
            access, use and disclose confidential and proprietary information only for which they are authorized; keep confidential and
            proprietary information secure when not using the information; and refrain from discussing confidential and proprietary information
            with unauthorized personnel or outside sources. This expectation of confidentiality extends beyond the Associate’s employment at Galatea Bio,
            and any use or disclosure of Galatea Bio confidential or proprietary information by a former employee is strictly prohibited.
          </Body>

          <Heading variant="H5" style={{ marginBottom: 8 }}>Conflicts of Interest</Heading>

          <Body variant="M" style={{ marginBottom: 25 }}>
            Associates are expected to act in a manner in the best interest of Galatea Bio. They may not use their positions to profit personally
            or assist others in profiting in any way at Galatea Bio’s expense. In any situation where an associate’s outside interests conflict
            with the organization’s, the associate must disclose the conflict in accordance with organizational policy.
          </Body>

          <Heading variant="H5" style={{ marginBottom: 8 }}>Your Voice</Heading>

          <Body variant="M">
            Galatea Bio has appointed a Chief Compliance & Privacy Officer. Associates can contact the Chief Compliance & Privacy Officer to report
            situations that may violate laws, the Standards of Conduct or applicable policies. Associates can also report potential violations through
            the Galatea Bio Compliance Hotline. All reported information will be treated confidentially.
          </Body>
        </div>

        <div className={styles.compliance__menu}>
          {codeOfConduct && (
            <AboutLink text="Code of Conduct" icon={getFileIcon(codeOfConduct.documentUrl)} blank linkTo={codeOfConduct.documentUrl} />
          )}
          {supplierCodeOfConduct && (
            <AboutLink text="Supplier Code of Conduct" icon={getFileIcon(supplierCodeOfConduct.documentUrl)} blank linkTo={supplierCodeOfConduct.documentUrl} />
          )}
          <AboutLink text="Non-Discrimination and Access to Services" icon="link-square" linkTo={galateaDifferenceUrls.nonDiscrimination} />
          <AboutLink text="California Comprehensive Compliance Program Statement" icon="link-square" linkTo={galateaDifferenceUrls.cccps} />
          <AboutLink text="Environmental, Social, and Governance (ESG) Policy" icon="link-square" linkTo={galateaDifferenceUrls.esg} />
          {complianceHotline && (
            <AboutLink text="Compliance Hotline" icon={getFileIcon(complianceHotline.documentUrl)} blank linkTo={complianceHotline.documentUrl} />
          )}
          {noneRetaliationPolicy && (
            <AboutLink text="Non-Retaliation Policy" icon={getFileIcon(noneRetaliationPolicy.documentUrl)} blank linkTo={noneRetaliationPolicy.documentUrl} />
          )}
        </div>

      </div>
    </>
  );
};

export default Compliance;
