import styles from 'pages/Legal/LegalPage.module.scss';
import useIntersectionProps from 'app/hooks/useIntersectionProps';
import { legalUrls } from 'app/routing';
import { SectionItem, Page, SidebarItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Table, TableRow } from 'components/Table';
import { Body, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy: React.FC<Page> = ({ setPageHeader }) => {
  const props = useIntersectionProps();

  const { ref: ref1, inView: ref1InView } = useInView(props);
  const { ref: ref2, inView: ref2InView } = useInView(props);
  const { ref: ref3, inView: ref3InView } = useInView(props);
  const { ref: ref4, inView: ref4InView } = useInView(props);
  const { ref: ref5, inView: ref5InView } = useInView(props);
  const { ref: ref6, inView: ref6InView } = useInView(props);
  const { ref: ref7, inView: ref7InView } = useInView(props);
  const { ref: ref8, inView: ref8InView } = useInView(props);
  const { ref: ref9, inView: ref9InView } = useInView(props);
  const { ref: ref10, inView: ref10InView } = useInView(props);
  const { ref: ref11, inView: ref11InView } = useInView(props);
  const { ref: ref12, inView: ref12InView } = useInView(props);
  const { ref: ref13, inView: ref13InView } = useInView(props);
  const { ref: ref14, inView: ref14InView } = useInView(props);
  const { ref: ref15, inView: ref15InView } = useInView(props);
  const menuText1 = 'Last Updates';
  const menuText2 = '1. SEPARATE TERMS OF USE';
  const menuText3 = '2. POLICY UPDATES';
  const menuText4 = '3. TYPES OF PERSONAL INFORMATION WE COLLECT';
  const menuText5 = '4. CHILDREN\'S INFORMATION';
  const menuText6 = '5. USE AND SHARING PERSONAL INFORMATION';
  const menuText7 = '6. RETENTION';
  const menuText8 = '7. SECURITY MEASURES';
  const menuText9 = '8. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION';
  const menuText10 = '9. NOTICE TO INDIVIDUALS LOCATED IN THE EUROPEAN ECONOMIC AREA, THE UNITED KINGDOM, OR SWITZERLAND';
  const menuText11 = '10. NOTICE TO CALIFORNIA RESIDENTS';
  const menuText12 = '11. DO NOT TRACK';
  const menuText13 = '12. SERVICE-SPECIFIC POLICIES: GENETIC TESTING';
  const menuText14 = '13. SERVICE-SPECIFIC POLICIES: PATIENT INSIGHTS NETWORKS (PINs)';
  const menuText15 = 'Contacting Us';
  const sectionItems: SectionItem[] = [
    { id: menuText1, ref: ref1 },
    { id: menuText2, ref: ref2 },
    { id: menuText3, ref: ref3 },
    { id: menuText4, ref: ref4 },
    { id: menuText5, ref: ref5 },
    { id: menuText6, ref: ref6 },
    { id: menuText7, ref: ref7 },
    { id: menuText8, ref: ref8 },
    { id: menuText9, ref: ref9 },
    { id: menuText10, ref: ref10 },
    { id: menuText11, ref: ref11 },
    { id: menuText12, ref: ref12 },
    { id: menuText13, ref: ref13 },
    { id: menuText14, ref: ref14 },
    { id: menuText15, ref: ref15 },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: menuText1, inView: ref1InView },
    { text: menuText2, inView: ref2InView && !ref1InView },
    { text: menuText3, inView: ref3InView && !ref2InView },
    { text: menuText4, inView: ref4InView && !ref3InView },
    { text: menuText5, inView: ref5InView && !ref4InView },
    { text: menuText6, inView: ref6InView && !ref5InView },
    { text: menuText7, inView: ref7InView && !ref6InView },
    { text: menuText8, inView: ref8InView && !ref7InView },
    { text: menuText9, inView: ref9InView && !ref8InView },
    { text: menuText10, inView: ref10InView && !ref9InView },
    { text: menuText11, inView: ref11InView && !ref10InView },
    { text: menuText12, inView: ref12InView && !ref11InView },
    { text: menuText13, inView: ref13InView && !ref12InView },
    { text: menuText14, inView: ref14InView && !ref13InView },
    { text: menuText15, inView: ref15InView && !ref14InView },
  ], [
    ref1InView,
    ref2InView,
    ref3InView,
    ref4InView,
    ref5InView,
    ref6InView,
    ref7InView,
    ref8InView,
    ref9InView,
    ref10InView,
    ref11InView,
    ref12InView,
    ref13InView,
    ref14InView,
    ref15InView,
  ]);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title="Privacy Policy" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <Heading variant="H1">Galatea Bio Privacy Policy</Heading>

        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.legalPage__section}>
          <Heading variant="H4">Effective date: October 1, 2024</Heading>

          <Body variant="L">
            Galatea Bio, including its subsidiaries and affiliates (referred to collectively as “Galatea,” “we” or “us”),
            is committed to protecting your privacy. This Privacy Policy (this “Policy”) contains (1) general terms that apply to all of Galatea Bio’s
            products and services, including our genetic testing products, our clinical testing services, and the BioBank of America services
            (collectively “the Services”) and (2) terms that apply to specific Services (please see the relevant Service-Specific Policies section).
            This Policy describes how we collect, use, secure and share your personal information when you:
          </Body>

          <ul>
            <li>
              Access or use our various products or services;
            </li>
            <li>
              Access or use our websites that link to this Policy (“Websites”);
            </li>
            <li>
              Interact with us, including by email, telephone, social media, and in person; or
            </li>
            <li>
              Otherwise communicate with us.
            </li>
          </ul>

          <Body variant="L">
            We refer to our Websites, products, services, and interactions with you collectively as “Services” in this Policy.
          </Body>

          <Body variant="L">
            This Policy, along with our&nbsp;
            <Link to={legalUrls.hipaaNotice} target="_blank" rel="noopener noreferrer">HIPAA Notice of Privacy Practices</Link>,
            explains how we use and disclose our patients’ protected health information (“PHI”) under the
            Health Insurance Portability and Accountability Act of 1996 (“HIPAA”). PHI may include, but is not limited to,
            your genetic testing information and results. Where the terms of this Policy either increase the commitment Galatea Bio has made
            to an individual’s privacy or grant additional rights to patients greater than those listed in the Notice of Privacy Policy Practices,
            the terms of this Policy will control how we use and disclose your PHI.
          </Body>

          <Body variant="L">
            To the extent that any Service-Specific Policies conflict with the general terms of this Policy, the Service-Specific Policies will control
            how Galatea Bio uses or shares your personal information.
          </Body>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText2}</Heading>

          <Body variant="L">
            This Policy is not a contract and does not create any contractual rights or obligations. Your use of the Services is governed by the
            service contract linked to a particular service.
          </Body>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText3}</Heading>

          <Body variant="L">
            Galatea Bio may revise this Policy from time to time. All updates to this Policy will be posted on the Websites.
            An updated Policy will supersede all previous versions. If we make any material changes to this Policy,
            we will notify you by email (sent to the e-mail address specified in your account) in addition to posting an updated Policy on our Websites.
            Your continued use of our Services after we have posted the updated Policy on the Websites constitutes your acceptance of such changes.
            Galatea Bio may also provide additional &quot;just-in-time&quot; notices or information about its data collection, use,
            and sharing practices in relation to specific Services.
          </Body>
        </div>

        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText4}</Heading>

          <Body variant="L">
            <b>
              This section lists the general categories of personal information we collect for the Services.
              See the Product Specific Policies section to learn more details for specific Galatea Bio products or services.
            </b>
          </Body>

          <Body variant="L">
            Throughout this Policy we use the term “personal information” to describe any information that identifies, relates to, describes,
            is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to a particular person or household.
            Personal information includes, for example, PHI under HIPAA and personal data under the EU’s General Data Protection Regulation.
            This Policy covers all personal information that we collect from you or on your behalf through our Services.
          </Body>

          <Heading variant="H5">We may collect the following types of personal information:</Heading>

          <ul>
            <li>
              <b>Personal information provided by you or your clinician.</b> We collect any personal information that you voluntarily provide to us,
              such as health information you provide to us or your clinician, or that your clinician provides to us either directly or via health
              information exchanges, to facilitate our provision of Services. If you are an ordering clinician, we may collect your name, phone number,
              place of business and other contact information you provide when ordering a test.
            </li>
            <li>
              <b>Communications between you and Galatea Bio.</b> We collect personal information you submit when contacting us
              (such as your name and email address).
            </li>
            <li>
              <b>Clinical information.</b> From time to time, we collect additional medical records from your treating providers to support
              our delivery of genetic testing services.
            </li>
            <li>
              <b>Registration information.</b> When you register with Galatea Bio or create a user account to access our Services,
              we collect personal information, such as your name, date of birth, billing and shipping addresses, and contact information.
              We may use your registration information to provide Services to you.
            </li>
            <li>
              <b>Payment information.</b> When you place an order that requires payment via our Services, we collect your payment information
              (such as payment card, billing, and shipping information, and your contact information).
            </li>
            <li>
              <b>Device information.</b> When you use a mobile device (e.g., a tablet or smartphone) to access our Services, we may collect information
              about your device. We may collect information about your device’s hardware, operating system or software, device name,
              unique device identifier, your mobile network information, and any other information about your device’s interaction with our Services.
              Some features of the Services may not function properly if the use or availability of device identifiers is impaired or disabled.
            </li>
            <li>
              <b>Information about your use of the Services.</b> When you browse our Websites, our system automatically collects information
              such as your web request, Internet Protocol (“IP”) address, browser type, browser language, domain names, referring and exit pages and URLs,
              platform type, pages viewed and the order of these page views, the amount of time spent on a page, the date and time of your request and one
              or more cookies that may uniquely identify your browser. We may collect this information through third-party analytics tools.
              This information is used to analyze trends, administer our Websites, improve the design of our Websites, and otherwise enhance our Services.
            </li>
            <li>
              <b>Cookies.</b> We use technologies like cookies, web beacons, and pixel tags to gather information about how you are interacting with our
              Services, which may include identifying your IP address, browser type, and referring page. For more information on cookies,
              please see our&nbsp;
              <Link to={legalUrls.cookiePolicy} target="_blank" rel="noopener noreferrer">Cookie Policy</Link>.
            </li>
          </ul>
        </div>

        <div id={sectionItems[4].id} ref={sectionItems[4].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText5}</Heading>

          <Body variant="L">
            Our Websites are directed toward adults and are not designed for, intended to attract, or directed toward children under the age of 16.
            If you are under the age of 16, you must obtain the authorization of a responsible adult (parent or legal guardian) before using or accessing
            our Websites. If we become aware that we have collected any personal information from children under 16 without the authorization of a responsible
            adult, we will promptly remove such information from our databases.
          </Body>
        </div>

        <div id={sectionItems[5].id} ref={sectionItems[5].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText6}</Heading>

          <Body variant="L">
            <b>
              This section lists the general ways Galatea Bio uses and shares your personal information we collect for the Services.
              See the Product Specific Policies section to learn more details for specific Galatea Bio products or services.
            </b>
          </Body>

          <ul>
            <li>
              <b>Provide Services.</b> We process your personal information to provide you with our Services. We share this information with
              third party service providers to the extent necessary to provide you with our Services.
            </li>
            <li>
              <b>Maintain legal and regulatory compliance.</b> Certain laws or regulations apply to our Services that may require us to process
              your personal information. For example, we may process your personal information to fulfill our business obligations,
              ensure compliance with employment laws, or as necessary to manage risk as required under applicable law.
            </li>
            <li>
              <b>Ensure the security of the Services.</b> We may process your personal information to combat spam, malware, malicious activities,
              or security risks; improve and enforce our security measures; and to monitor and verify your identity so that unauthorized users
              do not access your account with us.
            </li>
            <li>
              <b>Personalize your experience on our Websites.</b> We may use cookies and similar tracking technology to keep track of your preferences
              (e.g., your language selection, time zones, etc.) so we can personalize your experience on our Websites.
              Please see our&nbsp;
              <Link to={legalUrls.cookiePolicy} target="_blank" rel="noopener noreferrer">Cookie Policy</Link>&nbsp;
              for more information.
            </li>
            <li>
              <b>Sale, Merger, or Bankruptcy.</b> In connection with a bankruptcy, merger, acquisition or sale or other business transaction,
              involving all or a portion of our assets or business, user information will also be transferred as part of or in connection with the transaction.
            </li>
            <li>
              <b>Affiliates.</b> Galatea Bio will share your personal information with our affiliates as necessary to provide the Services or
              with your consent where required by applicable law.
            </li>
            <li>
              <b>Text Messages.</b> You may opt-in to receive occasional text messages from Galatea Bio to receive updates on our Services.
              Message frequency will vary. You agree that by providing your mobile phone number and opting in to receive text messages,
              you expressly consent to receive automated text messages from us to the mobile phone number you provide.
              Consent to receiving text messages is not required to be a Galatea Bio user. Message and data rates may apply, and you should check the rates
              of your mobile carrier. Your mobile carrier is not liable for delayed or undelivered messages. You can opt out of receiving text messages
              by texting STOP in response to any text message. You can also text HELP and we will respond with instructions on how to opt out of or
              sign up for text messages from Galatea Bio. Galatea Bio is located in the United States, so international rates may apply depending
              on your location. We share your mobile phone number with service providers with whom we contract to send you automated text messages,
              but we will not share your mobile phone number with third parties for their own marketing purposes without your express consent.
              Contact us at&nbsp;
              <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>&nbsp;
              if you have any questions about our text message program.
            </li>
          </ul>
        </div>

        <div id={sectionItems[6].id} ref={sectionItems[6].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText7}</Heading>

          <Body variant="L">
            Because genetic testing is still an evolving science, we may store personal information provided to us in the context of performing
            genetic tests and delivering genetic testing services for as long as we need it to provide and improve our Services and to perform
            the activities described in this Policy, all to the extent permitted by law. This information remains subject to the protections of HIPPA,
            and the commitments in this Privacy Policy, for as long as we retain it.
          </Body>
        </div>

        <div id={sectionItems[7].id} ref={sectionItems[7].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText8}</Heading>

          <Body variant="L">
            We use reasonable technical, administrative, and physical measures to protect information contained in our system against misuse,
            loss or alteration; and to secure information that we receive through our Services.
          </Body>

          <Body variant="L">
            Please recognize that protecting your personal information is also your responsibility. You should keep your username, password,
            ID numbers, or other access credentials secure as Galatea Bio cannot secure personal information that you release on your own.
          </Body>
        </div>

        <div id={sectionItems[8].id} ref={sectionItems[8].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText9}</Heading>

          <Body variant="L">
            We may store, process, and transmit personal information in locations around the world, including locations outside of the country or
            jurisdiction where you are located. Our primary data processing activities occur within the United States.
            The United States and other countries where we process data may have data protection laws that are less protective than the laws
            of the jurisdiction in which you reside. If you do not want your information transferred to or processed or maintained outside
            of the country or jurisdiction where you are located, you should not use our Services.
          </Body>

          <Body variant="L">
            We transfer your personal information subject to appropriate safeguards as permitted under the applicable data protection laws.
          </Body>
        </div>

        <div id={sectionItems[9].id} ref={sectionItems[9].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText10}</Heading>

          <Body variant="L">
            This Section only applies to users of our Services located in the European Economic Area, the United Kingdom or Switzerland (collectively,
            the “Designated Countries”) at the time of data collection. If any terms in this Section conflict with other terms contained in this Policy,
            the terms in this Section shall apply to users in the Designated Countries. Lawful basis for processing your personal information.
            Below is a summary of our data processing activities and our lawful bases for processing data.
          </Body>

          <Table id="privacyPolicy" columnsWidth={[ '50%', '50%' ]}>
            <TableRow highlighted>
              <div>
                <Heading variant="H5">Purposes of processing</Heading>
              </div>

              <div>
                <Heading variant="H5">Legal basis for processing</Heading>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <ul>
                  <li>to provide our services to you and conduct related, compatible activities</li>
                  <li>to send service-related communications</li>
                  <li>to provide customer support</li>
                  <li>to enforce our terms, agreements, or policies</li>
                  <li>to ensure the security of our services</li>
                  <li>to share information with our service providers, business partners, and affiliates</li>
                </ul>
              </div>

              <div>
                <Body variant="L">Processing is based on our contractual obligations under the applicable contract.</Body>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div>
                <ul>
                  <li>to personalize your experience on our Websites</li>
                  <li>to conduct research and product development</li>
                  <li>change of control</li>
                </ul>
              </div>

              <div>
                <Body variant="L">
                  Processing is based on our legitimate interest to better understand you, to maintain and improve the accuracy of the information
                  we store about you, and to better promote or optimize our Services.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <ul>
                  <li>to maintain legal or regulatory compliance</li>
                  <li>responding to legal requests and preventing harm</li>
                  <li>safety and legal compliance</li>
                </ul>
              </div>

              <div>
                <Body variant="L">
                  Processing is necessary for compliance with our legal obligations, the public interest, or in your vital interests
                </Body>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div>
                <ul>
                  <li>to conduct scientific, historical, or statistical research</li>
                </ul>
              </div>

              <div>
                <Body variant="L">
                  Processing is based on your consent, as required under applicable law, or as otherwise permitted by laws governing scientific,
                  historical or statistical research.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <ul>
                  <li>to create anonymized or pseudonymized information or samples</li>
                </ul>
              </div>

              <div>
                <Body variant="L">
                  Processing is based on your consent, as required under applicable law, or as otherwise permitted
                  by laws governing scientific or historical research.
                </Body>
              </div>
            </TableRow>
          </Table>

          <Body variant="L">
            <b>Marketing activities</b>. Direct marketing includes any communications we send to you that are only advertising or promoting products
            and services. Transactional communications about your account or our Services are not considered “direct marketing” communications.
            We will only send you direct marketing communications by electronic means (including email or SMS) based on our legitimate interest or your consent.
            When we rely on legitimate interest, we will only send you information about our Services that are similar to those which were the subject
            of a previous sale or negotiations of a sale to you. If you do not want us to use your personal information in this way,
            please click an unsubscribe link in your emails, or contact us at&nbsp;
            <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>.
            You can object to direct marketing at any time and free of charge.
          </Body>

          <Body variant="L">
            <b>Individual rights.</b> We provide you with the rights described below when you use our Services. When we receive an individual rights request
            from you, please make sure you are ready to verify your identity. Please be advised that there are limitations to your individual rights.
            We may limit your individual rights in the following ways: (i) where denial of access is required or authorized by law; (ii) when granting
            access would have a negative impact on other&apos;s privacy; (iii) to protect our rights and properties; and (iv) where the request is frivolous
            or burdensome. If you have questions, if you would like to exercise your rights under the applicable law please contact us at&nbsp;
            <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>.
          </Body>

          <ul>
            <li>
              <b>Right to withdraw consent.</b> If we, or any third parties to which we provide your personal information, rely on your consent to process
              your personal information, you have the right to withdraw your consent at any time. A withdrawal of consent will not affect the lawfulness
              of our processing or the processing by any third parties made before your withdrawal. In addition, your withdrawal of consent may not limit our,
              or the third parties’ further processing of personal information on another lawful basis, including where it is necessary for the performance
              of a contract; for us to comply with our legal obligations; where it protects your vital interests; or in limited circumstances where it is in
              our legitimate interests.
            </li>
            <li>
              <b>Right of access and rectification.</b> If you request a copy of your personal information that we hold, we will provide you with a copy
              without undue delay and free of charge, except where we are permitted by law to charge a fee. We may limit your access if such access would
              adversely affect the rights and freedoms of other individuals. You may request to correct or update any of your personal information held by us.
            </li>
            <li>
              <b>Right to erasure (the “right to be forgotten”).</b> You may request us to erase any of your personal information held by us that:
              is no longer necessary in relation to the purposes for which it was collected or otherwise processed; was collected in relation
              to processing that you previously consented to, but later withdrew such consent; or was collected in relation to processing activities
              to which you object, and there are no overriding legitimate grounds for our processing such as the conduct of scientific research
              or the establishment or defense of legal claims.
            </li>
            <li>
              <b>Right to object to processing.</b> You may object to our processing at any time and as permitted by applicable law if we process
              your personal information on the legal basis of consent, contract, or legitimate interests. We can continue to process your personal
              information if it is necessary for the defense of legal claims, or for any other exceptions permitted by applicable law.
            </li>
            <li>
              <b>Right to restriction.</b> You have the right to restrict our processing of your personal information where one of the following applies:

              <ul style={{ marginTop: 12 }}>
                <li>
                  You contest the accuracy of your personal information that is processed. We will restrict the processing of your personal information,
                  which may result in an interruption of some or all of the Services, during the period necessary for us to verify the accuracy of
                  your personal information.
                </li>
                <li>
                  The processing is unlawful and you oppose the erasure of your personal information and request the restriction of its use instead.
                </li>
                <li>
                  We no longer need your personal information for the purposes of the processing, but it is required by you to establish, exercise,
                  or defend legal claims.
                </li>
                <li>
                  You have objected to processing, pending the verification whether the legitimate grounds of our processing override your rights.
                </li>
                <li>
                  We will only process your restricted personal information with your consent or for the establishment, exercise, or defense of legal claims
                  or for the protection of the rights of another natural or legal person or for reasons of important public interest.
                  We will inform you if or when the restriction is lifted.
                </li>
              </ul>
            </li>
            <li>
              <b>Right to data portability.</b> If we process your personal information based on a contract with you or based on your consent,
              or the processing is carried out by automated means, you may request to receive your personal information in a structured,
              commonly used and machine-readable format, and to have us transfer your personal information directly to another data controller,
              where technically feasible, unless exercise of this right adversely affects the rights and freedoms of others.
            </li>
            <li>
              <b>Notification to third parties.</b> If we share your personal information with third parties, we will notify them of any requests
              for rectification, erasure, or restriction of your personal information, unless this proves impossible or involves disproportionate effort.
            </li>
            <li>
              <b>Right to lodge a complaint.</b> If you believe we have infringed or violated your privacy rights, please contact us at&nbsp;
              <Link to="mailto:compliance@galatea.bio">compliance@galatea.bio</Link>&nbsp;
              so that we can work to resolve your concerns. You also have a right to lodge a complaint with a competent supervisory authority situated
              in an EEA Member State of your habitual residence, place of work, or place of alleged infringement.
            </li>
          </ul>
        </div>

        <div id={sectionItems[10].id} ref={sectionItems[10].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText11}</Heading>

          <Body variant="L">
            This Section only applies to users of our Services that reside in the State of California. For purposes of this Section, the term
            &quot;personal information&quot; does not include information subject to HIPAA or the California Confidentiality of Medical Information Act.
            For example, this Section does not apply to genetic test records or to any data or medical records stored by Galatea Bio.
          </Body>

          <Body variant="L">
            If you are a California Resident, you are entitled to certain privacy rights relating to your personal information.
            This section describes those rights and how you can exercise those rights.
          </Body>

          <ul>
            <li>
              <b>Right to Know and Access Personal Information.</b> You have the right to access and know what personal information we collect about you,
              and how we use, disclose, or sell such personal information. We do not &quot;share&quot; personal information as that term is used in
              the California Consumer Privacy Act.
            </li>
            <li>
              <b>Right to Request Deletion of your Personal Information.</b> You have the right to request that we delete the personal information we have
              about you subject to certain limitations. If we do not delete your personal information for reasons permitted under applicable law,
              we will let you know the reason why.
            </li>
            <li>
              <b>Right to Correct Inaccurate Personal Information.</b> You have the right to request that we correct inaccurate personal information
              that we maintain about you.
            </li>
            <li>
              <b>Right to Opt Out of Sale of your Personal Information.</b> You may have the right to opt out of the sale or sharing of your Personal Data.
            </li>
          </ul>

          <Heading variant="H5">Non-Discrimination</Heading>

          <Body variant="L">We will not discriminate or retaliate against you for exercising any of your rights identified in this privacy policy.</Body>

          <Body variant="L">
            <b id="Methods for Submitting Requests">Methods for Submitting Requests.</b> If you wish to exercise any of these rights please email&nbsp;
            <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>&nbsp;
            with the phrase “California Privacy Rights” in the subject line. You may also send a letter to us at Galatea Bio, Attn:
            Chief Administrative Officer, 14350 Commerce Way, Miami, Florida 33016. We will review your request and respond accordingly.
          </Body>

          <Body variant="L">
            <b>Identity Verification for requests.</b> If you make a request related to personal information about you, you will be required to supply
            a valid means of identification as a security precaution. We will verify your identity with a reasonably high degree of certainty using
            the following procedure where feasible: we will match identifying information you provide when making the request to the personal
            information maintained by us, or use a third-party identity verification service. If it is necessary to collect additional information,
            we will use the information only for verification purposes and will delete it as soon as practicable after complying with your request.
            For requests related to particularly sensitive information, we may require additional proof of your identity.
          </Body>

          <Body variant="L">
            <b>Authorizing a Third Party to Make a Request.</b> If you wish to authorize a third party to make a request on your behalf through an
            authorized agent, you must contact us directly and you or the third party acting on your behalf must provide a valid California power
            of attorney or comparable documentation of written permission from you and verification of your identity. Such power of attorney
            must meet the requirements of Probate Code sections 4000 to 4465. You may also make a privacy request on behalf of your minor child.
          </Body>

          <Heading variant="H4">Additional disclosures</Heading>

          <Heading variant="H5">How We Collect Your Personal Information</Heading>

          <Body variant="L">
            We may have collected personal information about you from a variety of sources in the past 12 months:
          </Body>

          <ul>
            <li>
              <b>From You:</b> When you set up your account or interact with us regarding your account, for billing and payment, for when you start,
              receive, or discontinue services, and when you otherwise interact with us or our representatives.
            </li>
            <li>
              <b>From Providers:</b> We work with and receive personal information from third parties such as service providers, vendors, contractors,
              credit agencies, or market researchers who provide products and services on our behalf.
            </li>
            <li>
              <b>From Your Use of Our Website or Mobile Apps:</b> We collect information about your visits and use of our website and mobile apps,
              including information through cookies and other logging technologies.
            </li>
          </ul>

          <Body variant="L">
            <b>Categories of personal information we collect.</b> In the previous 12 months, Galatea Bio may have collected the following categories
            of personal information:
          </Body>

          <ul>
            <li>
              <b>Identifiers,</b> such as your name, date of birth and contact information. We use this information to provide you with our services,
              maintain your account, operate our business, and to otherwise communicate with you. We may provide this information to service providers
              and contractors or to other third parties as required by law.
            </li>
            <li>
              <b>Information Subject to Protection Under California Law,</b> such as the Identifiers above, health insurance information, credit card number,
              or debit card number. We use this information to provide you with our services, maintain your account, and to otherwise communicate with you.
              We may provide this information to service providers, regulators or to other third parties as required by law.
            </li>
            <li>
              <b>Characteristics of Protected Classifications,</b> such as your racial or ethnic origin and health information. For more information,
              please see the &quot;Sensitive personal information&quot; description below.
            </li>
            <li>
              <b>Sensitive Personal Information,</b> such as your driver&apos;s license or government issued identification number, racial or ethnic origin.
            </li>
            <li>
              <b>Payment Information.</b> We or our service providers may collect your credit card or debit card information. We use this information
              to process payments for our services. We do not, however, collect or store payment card or bank information.
              Payments and purchases are processed through our service providers.
            </li>
            <li>
              <b>Commercial Information,</b> such as a record of products or services obtained or considered. We use this information to better
              understand your use of our services and for our internal business purposes. We may provide this information to service providers
              and contractors or to other third parties as required by law.
            </li>
            <li>
              <b>Professional Information,</b> including information relating to your role as a representative or agent of a company or business,
              such as your work title and contact information. We use this to conduct business with you or your employer and provide you with
              services and otherwise communicate with you. We may provide this information to service providers and contractors or with
              other third parties as required by law.
            </li>
            <li>
              <b>Internet and Other Electronic Activity Information,</b> such as your browsing history, search history, information about your interactions
              with our website, use our mobile apps, or interact with our digital advertisements. We may provide this information to service providers,
              contractors or to other third parties as required by law. We may collect the following internet and other electronic activity data:
            </li>
            <li>
              <b>Your Visits to Our Website or Mobile Apps:</b> we collect information about visits to our website and mobile apps, such as the number
              of visitors and the number of users that click on certain links or use certain services. For some functionalities, such as rate analysis,
              we link usage information with the customer visiting the website.
            </li>
            <li>
              <b>Log Data:</b> we receive information that is automatically recorded by our servers when you visit our website or mobile apps,
              including your Internet Protocol (&quot;IP&quot;) Address.
            </li>
          </ul>

          <Body variant="L">
            <b>Cookies:</b> For more information about our use of Cookies, and your choices with respect to cookies, please read our&nbsp;
            <Link to={legalUrls.cookiePolicy} target="_blank" rel="noopener noreferrer">Cookie Policy</Link>.
          </Body>

          <Body variant="L">
            Purpose for collecting or selling personal information. Your personal information may be collected or used for the purposes described
            in sections 5, 12, or 13 of this Policy, as well as for other purposes that may be described to you when we collect your personal information.
          </Body>

          <Body variant="L">
            <b>Categories of third parties with whom we share your personal information.</b> Galatea Bio may share your personal information with
            the following categories of third parties:
          </Body>

          <ul>
            <li>
              <b>At Your Choice:</b> You may authorize other companies or persons to receive your personal information.
            </li>
            <li>
              <b>To Our Service Providers and Contractors:</b> We may disclose your personal information to service providers and contractors that
              provide services and support to us. We contractually require service providers and contractors acting on our behalf to take reasonable
              steps to protect your personal information and to not use your personal information for any purpose other than that for which it was given
              to the service provider or contractor.
            </li>
            <li>
              <b>To Third Parties to Fulfill Legal Obligations:</b> From time to time, we may disclose your personal information with other parties
              as required pursuant to a valid legal warrant, subpoena, court order, or other legal or regulatory mandate, or as necessary for us to
              defend to assert legal claims.
            </li>
            <li>
              <b>To Third-Party Analytics Companies.</b> We use selected third parties to collect data about how you interact with our website and apps.
              This information may be used to, among other things, improve the functionality of our website and services.
            </li>
            <li>
              <b>To Potential Business Partners.</b> We may share or transfer your information in connection with, or during negotiations of, any merger,
              sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </li>
            <li>
              <b>To Other Parties:</b> From time to time, we may share your personal information to parties such as law enforcement or other government
              agencies when we, in good faith, believe you or others are acting unlawfully, when we believe it is necessary or appropriate to satisfy any law,
              regulation or other governmental request, to operate our business properly, to protect or defend our rights or the rights or well-being
              of our users, and when we believe disclosure is necessary or appropriate to protect the health and safety of our employees,
              other consumers, and the general public.
            </li>
          </ul>

          <Heading variant="H5">Retention</Heading>

          <Body variant="L">
            We retain your personal information based on legal requirements or business needs. Generally, we only retain personal information for as
            long as is reasonably necessary for our business purpose or as required by law. Information we retain remains subject to the protections
            of applicable law and the commitments made by Galatea Bio in this Privacy Policy.
          </Body>

          <Heading variant="H5">Selling Personal Information</Heading>

          <Body variant="L">
            In the last 12 months we may have sold the personal information relating to clinicians who have ordered genetic tests from Galatea Bio.
            We have not sold other personal information, including the PHI of patients, in the preceding 12 months for any monetary value.
            However, our use of certain website cookies may be considered a “sale” of information under California law. In the past twelve months,
            we may have shared your internet activity or geolocation with third parties whose cookies are on our websites.
            These cookies are used to analyze usage of our website, provide you with relevant advertising and products, and provide additional,
            dynamic functionality to our websites. You can opt-out of this “sale” of information by following the instructions, available&nbsp;
            <Link to="#Methods for Submitting Requests">here</Link>.
            We also recognize opt-out preference signals contained in HTTP header fields.
          </Body>

          <Heading variant="H5">Disclosing Aggregated and De-identified Data</Heading>

          <Body variant="L">
            We may share aggregated or de-identified, data and information derived from personal information with other entities for the purpose of performing
            activities that may help us provide and improve our products and services.
          </Body>

          <Heading variant="H5">California Shine the Light Law</Heading>

          <Body variant="L">
            California residents may also request information from us once per calendar year about any personal information shared with third parties for
            the third party’s own direct marketing purposes, including the categories of information and the names and addresses of those businesses
            with which we have shared such information. To make such a request, please reach us at the contact information listed below.
            This request may be made no more than once per calendar year, and we reserve our right not to respond to requests submitted other than to
            the email or mailing addresses specified below.
          </Body>
        </div>

        <div id={sectionItems[11].id} ref={sectionItems[11].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText12}</Heading>

          <Body variant="L">
            Certain web browsers and other devices you may use to access the Websites may permit you to submit your preference that you do not wish
            to be “tracked” online. Like many websites, our Websites are not currently designed to recognize a “Do Not Track” signal from a web browser.
          </Body>
        </div>

        <div id={sectionItems[12].id} ref={sectionItems[12].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText13}</Heading>

          <Body variant="L">
            This section describes policies specific to Galatea Bio’s genetic testing services. We describe how Galatea Bio uses and protects your data
            and the choices available to you to determine how it is used. Two of Galatea Bio’s founding principles are 1) patients own and control their
            own genetic information and 2) genetic information is more valuable when shared. These principles have guided the company since its inception
            and inform how Galatea Bio uses and protects patient data.
          </Body>

          <Heading variant="H5">Information Galatea Bio Collects for Genetic Testing</Heading>

          <Body variant="L">
            As a genetic testing laboratory, Galatea Bio receives your sample (e.g. blood, saliva) along with your relevant health information.
            Galatea Bio then analyzes the genetic information contained within the sample and delivers a genetic test report to your healthcare provider.
            Your sample and the health and genetic information Galatea Bio receives and generates about you (collectively, “your data”) is considered
            as sensitive personal data by regulators. Your data is subject to strict legal requirements regarding how it can and cannot be used and
            how it must be protected.
          </Body>

          <Heading variant="H5">How Galatea Bio Uses and Shares Your Genetic Data.</Heading>

          <Body variant="L">
            The following activities are a core part of Galatea Bio’s genetic testing services such that when you consent to a genetic test,
            <b>you are consenting to and understand you cannot opt-out</b> of these activities:
          </Body>

          <ul>
            <li>
              Providing genetic testing services, including preparing and delivering a genetic test report to your healthcare providers.
              This report may be delivered through the Galatea Bio portal or an intermediary and may include raw sequencing data.
            </li>
            <li>
              Performing operational activities in support of genetic testing services, such as billing for services Galatea Bio provides.
              Galatea Bio may contact you via text or email (per your contact preference) as part of delivering the genetic testing services.
            </li>
            <li>
              Internal uses for validation, quality improvement, refining and updating Galatea Bio’s classification of genetic variants,
              and product development related to genetic testing.
            </li>
            <li>
              Sharing of anonymized variant information with ClinVar, a federal program that enables research on genes and health.
            </li>
            <li>
              Sharing of anonymized variant information according to the&nbsp;
              <Link to="https://clinicalgenome.org/docs/clingen-variant-curation-expert-panel-vcep-protocol" target="_blank" rel="noopener noreferrer">
                ClinGen Variant Curation Expert Panel Protocol
              </Link>, which implements an approved process of evaluating the strength of evidence supporting or refuting a claim that variation
              in a particular gene causes a particular disease.
            </li>
            <li>
              Sharing of data that is summarized at a group or aggregate level rather than data that is specific to a single individual.
            </li>
            <li>
              Sharing of the contact information of your healthcare provider with third parties if your healthcare provider has provided consent.
            </li>
          </ul>

          <Body variant="L">
            You also consent to the research and commercial activities as set forth below, you can opt out of any of the following activities by&nbsp;
            <Link to="mailto:info@galatea.bio">emailing us</Link>.
            Samples originating in the State of New York will not be eligible for the research and commercial activities described below.
            No tests other than those authorized shall be performed on your biological sample, though your de-identified data may still be
            utilized unless you opt out as described above.
          </Body>

          <ul>
            <li>
              Performing internal research activities. These are activities where Galatea Bio uses patient samples or data to generate new knowledge.
            </li>
            <li>
              Sharing de-identified data and samples with third parties for research or commercial activities.

              <ul>
                <li>
                  Third parties may include academic researchers, commercial entities, and other genetic testing laboratories.
                  Recipients of the de-identified data and samples are prohibited from attempting to re-identify you.
                  Recipients may link de-identified data from Galatea Bio with other data sources to create a combined data set as long as
                  the data remains de-identified. Galatea Bio will NOT share your identifiable data or sample without your additional, explicit consent.
                  If you opt out of data sharing after your data has already been shared, you understand your data cannot be “un-shared”.
                </li>
              </ul>
            </li>
            <li>
              Contacting you about research opportunities, opportunities to connect with others, product feedback, and new products and services.
            </li>
          </ul>

          <Body variant="L">
            <b>Sponsored Testing Programs and Data Sharing.</b>
            <i>
              If you choose to participate in a sponsored testing program or other data sharing program offered by Galatea Bio to help individuals access
              genetic testing, you are consenting to de-identified data sharing through the program. You understand that (1) you cannot opt out of data
              sharing through the program (except where required by law) and (2) your general data sharing preference will not apply to data sharing
              through the program.
            </i>
          </Body>

          <Body variant="L">
            <b>Location of processing of sample and data.</b> Galatea Bio is located in the United States and Galatea Bio will process your data
            and sample in the United States.
          </Body>

          <Body variant="L">
            <b>Retention of data and samples.</b> Galatea Bio will retain your data and sample for as long as reasonably necessary for the purposes
            described above. <i>However, if you are a resident of New York,</i> your sample will be destroyed no more than 60 days after the sample was taken
            or at the end of the testing process, whichever is later.
          </Body>
        </div>

        <div id={sectionItems[13].id} ref={sectionItems[13].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText14}</Heading>

          <Body variant="L">
            Patient Insights Networks (PINs) are online platforms where individuals can take surveys, upload medical records, track health outcomes,
            and learn about the latest research and clinical trials.
          </Body>

          <Body variant="L">
            <b>PIN Data Storage and Sharing.</b> Data stored in PINs is safeguarded and de-identified which makes it possible to share with
            researchers and drug developers working to help find new and better treatments for disease. Data is also shared with other patients,
            as well as various organizations, as discussed in further detail below.
          </Body>

          <Body variant="L">
            Galatea Bio will only share your de-identified data stored in a PIN according to the preferences you set, either at account creation
            or at any time after. If you opt-in, your de-identified data may be shared:
          </Body>

          <ul>
            <li>
              With public databases such as ClinVar, a federal program that enables research on genes and health.
            </li>
            <li>
              With PIN Participants including non-profit organizations, doctors, research scientists, medical investigators, regulatory agencies,
              and pharmaceutical and biomedical companies.
            </li>
          </ul>
        </div>

        <div id={sectionItems[14].id} ref={sectionItems[14].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText15}</Heading>

          <Body variant="L" style={{ marginBottom: 96 }}>
            Our Websites are owned and operated by Galatea Bio. If you have any questions about this Policy or our Services, you can email us at&nbsp;
            <Link to="mailto:info@galatea.bio">info@galatea.bio</Link>,
            or write to us via regular mail at Galatea Bio, 14350 Commerce Way, Miami, Florida 33016.
          </Body>
        </div>
      </PageWithSidebar>
    </>
  );
};

export default PrivacyPolicy;
