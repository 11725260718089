import 'components/Table/Table.scss';

import React, { CSSProperties, ReactNode } from 'react';

interface Props {
  id: string;
  children: ReactNode;
  columnsWidth: string[];
  style?: CSSProperties;
}

const Table: React.FC<Props> = ({ id, children, columnsWidth, style }) => {
  return (
    <>
      <style type="text/css" media="screen">
        {columnsWidth.map((width, i) => `#${id} .table__row div:nth-child(${i + 1}) { width: ${width}; }`)}
      </style>

      <div className="table" id={id} style={style}>
        {children}
      </div>
    </>
  );
};

export default Table;
