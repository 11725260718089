import styles from 'pages/GalateaDifference/About/components/OurPublications/OurPublications.module.scss';
import { PublicationsResponse } from 'api/Publications/types';
import { galateaDifferenceUrls } from 'app/routing';
import { Body, Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  publications: PublicationsResponse;
}

const OurPublications: React.FC<Props> = ({ publications }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.ourPublications} data-cy="our-publications">
      <div className={styles.ourPublications__topLine}>
        <Heading variant="H4">{t('welcomePage.ourPublications')}</Heading>

        <Link to={galateaDifferenceUrls.publications}>
          <Heading variant="H6">{t('welcomePage.showAll')}</Heading>
        </Link>
      </div>

      <div className={styles.ourPublications__links}>

        {publications.items.slice(0, 4).map(item => (
          <Link to={galateaDifferenceUrls.publicationsDetailUrl(item.meta.slug)} className={styles.ourPublications__link} key={item.id}>
            <Body variant="M">{item.title}</Body>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurPublications;
