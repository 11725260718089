import styles from 'components/Forms/components/ActionButton/ActionButton.module.scss';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import Button from 'components/Button/Button';

import React from 'react';
import cn from 'classnames';

interface Props {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  onClick: () => void;
  onTryAgainClick: () => void;
}

const ActionButton: React.FC<Props> = ({ isLoading, isSuccess, isError, onClick, onTryAgainClick }) => {
  const isPatientVersion = useIsPatientVersion();
  const buttonClasses = cn(
    styles.actionButton,
    {
      [styles.actionButton_loading]: isLoading,
      [styles.actionButton_success]: isSuccess,
      [styles.actionButton_error]: isError,
      [styles.actionButton_patients]: isPatientVersion,
    },
  );
  const buttonIcon = () => {
    if (isSuccess) {
      return getIconByType('check');
    } else if (isError) {
      return getIconByType('alert');
    }
  };

  return (
    <div className={styles.actionButtons}>
      <Button
        onClick={onClick}
        className={buttonClasses}
        size="L"
        type={(isSuccess || isError) ? 'ghost' : 'primary'}
        icon={buttonIcon()}
      >
        {isLoading && <span className={styles.actionButton__loader} />}
        {!isLoading && !isSuccess && !isError && 'Send request'}
        {!isLoading && isSuccess && 'Request Sent'}
        {!isLoading && isError && 'Request Failed'}
      </Button>

      {isError && <Button size="L" type="ghost" onClick={onTryAgainClick}>Try again</Button>}
    </div>
  );
};

export default ActionButton;
