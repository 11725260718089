import 'pages/ProductCatalog/components/Comparing/components/ComparingContent/ComparingContent.scss';
import { ComparingSubset, ComparingPanel } from 'api/TestingPanels/types';
import { grayscale400 } from 'app/consts/colors';
import { renderIcon } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { catalogUrls } from 'app/routing';
import { SectionItem } from 'app/types/ProductCatalog';
import GenesList from 'components/GenesList/GenesList';
import { Body, Caption, Heading } from 'components/Typography';

import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  sectionItems: SectionItem[];
  panel: ComparingPanel;
  subset: ComparingSubset;
}

const ComparingContent: React.FC<Props> = ({ sectionItems, panel, subset }) => {
  const isPatientVersion = useIsPatientVersion();

  return (
    <div className={cn('comparingContent', { comparingContent_patients: isPatientVersion })}>

      <div id={sectionItems[0].id} ref={sectionItems[0].ref}>
        <div className="comparingContent__section" style={{ paddingTop: 0 }}>
          <div className="comparingContent__block">
            <Heading variant="H4">{panel.title}</Heading>
          </div>

          <div className="comparingContent__block comparingContent__block_subset">
            <Heading variant="H4">{subset.title}</Heading>
          </div>
        </div>

        <div className="comparingContent__section">
          <div className="comparingContent__block">
            <Body variant="M">{panel.description}</Body>
          </div>

          <div className="comparingContent__block comparingContent__block_subset">
            <Body variant="M">{subset.description}</Body>
          </div>
        </div>
      </div>

      {!sectionItems[1].hide && (
        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className="comparingContent__section">
          <div className="comparingContent__block">
            <Heading variant="H1">{panel.genesAmount}</Heading>
            <Caption variant="L" style={{ color: grayscale400 }}>Genes Analyzed</Caption>

            <GenesList genes={panel.genes} showMoreButton={false} maximumGenes={10} style={{ marginTop: 11, marginBottom: 6 }} />
            <Link to={catalogUrls.panelDetailUrl(panel.slug)}>
              <Caption variant="L">Full genes list on panel page</Caption>
            </Link>
          </div>

          <div className="comparingContent__block comparingContent__block_subset">
            <Heading variant="H1">{subset.genesAmount}</Heading>
            <Caption variant="L" style={{ color: grayscale400 }}>Genes Analyzed</Caption>

            <GenesList genes={subset.genes} maximumGenes={10} style={{ marginTop: 11, marginBottom: 6 }} />
          </div>
        </div>
      )}

      <div id={sectionItems[2].id} ref={sectionItems[2].ref}>
        {(panel.methodologies.length > 0 || subset.methodologies.length > 0) && (
          <div className="comparingContent__section">
            <div className="comparingContent__block">
              <Heading variant="H6">Methodologies</Heading>
              {panel.methodologies.map(methodology => (
                <div className="comparingContent__specification" key={methodology.name}>
                  {renderIcon('cogs')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{methodology.name}</Body>
                </div>
              ))}
            </div>

            <div className="comparingContent__block comparingContent__block_subset">
              <Heading variant="H6">Methodologies</Heading>
              {subset.methodologies.map(methodology => (
                <div className="comparingContent__specification" key={methodology.name}>
                  {renderIcon('cogs')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{methodology.name}</Body>
                </div>
              ))}
            </div>
          </div>
        )}

        {(panel.specifications.length > 0 || subset.specifications.length > 0) && (
          <>
            <div className="comparingContent__section">
              <div className="comparingContent__block">
                <Heading variant="H6" style={{ marginTop: 15 }}>Turnaround time</Heading>
                <div className="comparingContent__specification">
                  {renderIcon('clock')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{panel.specifications[0].value.turnaroundTime}</Body>
                </div>
              </div>

              <div className="comparingContent__block comparingContent__block_subset">
                <Heading variant="H6" style={{ marginTop: 15 }}>Turnaround time</Heading>
                <div className="comparingContent__specification">
                  {renderIcon('clock')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{subset.specifications[0].value.turnaroundTime}</Body>
                </div>
              </div>
            </div>

            <div className="comparingContent__section">
              <div className="comparingContent__block">
                <Heading variant="H6" style={{ marginTop: 15 }}>Preferred Specimen</Heading>
                <div className="comparingContent__specification">
                  {renderIcon('lab')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{panel.specifications[0].value.preferredSpecimen}</Body>
                </div>
              </div>

              <div className="comparingContent__block comparingContent__block_subset">
                <Heading variant="H6" style={{ marginTop: 15 }}>Preferred Specimen</Heading>
                <div className="comparingContent__specification">
                  {renderIcon('lab')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{subset.specifications[0].value.preferredSpecimen}</Body>
                </div>
              </div>
            </div>

            <div className="comparingContent__section">
              <div className="comparingContent__block">
                <Heading variant="H6" style={{ marginTop: 15 }}>Alternate Specimen</Heading>
                <div className="comparingContent__specification">
                  {renderIcon('lab')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{panel.specifications[0].value.alternateSpecimen}</Body>
                </div>
              </div>

              <div className="comparingContent__block comparingContent__block_subset">
                <Heading variant="H6" style={{ marginTop: 15 }}>Alternate Specimen</Heading>
                <div className="comparingContent__specification">
                  {renderIcon('lab')}
                  <Body variant="S" style={{ marginLeft: 12 }}>{subset.specifications[0].value.alternateSpecimen}</Body>
                </div>
              </div>
            </div>

            {(panel.specifications[0].value.newYorkApproved || subset.specifications[0].value.newYorkApproved) && (
              <div className="comparingContent__section">
                <div className="comparingContent__block">
                  <Heading variant="H6" style={{ marginTop: 15 }}>New York approved</Heading>
                  {panel.specifications[0].value.newYorkApproved && (
                    <div className="comparingContent__specification">
                      {renderIcon('check')}
                      <Body variant="S" style={{ marginLeft: 12 }}>Yes</Body>
                    </div>
                  )}
                </div>

                <div className="comparingContent__block comparingContent__block_subset">
                  <Heading variant="H6" style={{ marginTop: 15 }}>New York approved</Heading>
                  {subset.specifications[0].value.newYorkApproved && (
                    <div className="comparingContent__specification">
                      {renderIcon('check')}
                      <Body variant="S" style={{ marginLeft: 12 }}>Yes</Body>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div id={sectionItems[3].id} ref={sectionItems[3].ref}>
        <div className="comparingContent__section">
          <div className="comparingContent__block" style={{ borderColor: 'transparent' }}>
            <Body variant="M" style={{ whiteSpace: 'pre-line' }}>
              {panel.limitations}
            </Body>
          </div>

          <div className="comparingContent__block comparingContent__block_subset" style={{ borderColor: 'transparent' }}>
            <Body variant="M" style={{ whiteSpace: 'pre-line' }}>
              {subset.limitations}
            </Body>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ComparingContent;
