import { getProductCategoriesRequest } from 'api/Products/api';
import { ProductCategoriesResponse } from 'api/Products/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useProductCategories = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ productCategories, setProductCategories ] = useState<ProductCategoriesResponse>({
    meta: { totalCount: 0 },
    items: [],
    commonInfo: {
      genesTotal: 0,
      commonTitle: '',
      commonDescription: '',
      commonIcons: [],
      commonImage: '',
    },
  });

  const fetchProductCategories = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getProductCategoriesRequest(abortController)
      .then(({ data }) => {
        setProductCategories(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { productCategories, isLoading, error, fetchProductCategories };
};

export default useProductCategories;
