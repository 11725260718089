import 'pages/WelcomePage/components/FullTestsCatalog/FullTestsCatalog.scss';
import { ReactComponent as Arrow } from 'app/assets/Arrow.svg';
import { ReactComponent as Logo } from 'app/assets/Logo.svg';
import { catalogUrls } from 'app/routing';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Hero } from 'components/Typography';

import React, { MouseEvent, FocusEvent } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FullTestsCatalog: React.FC = () => {
  const { t } = useTranslation();
  const textArray = t('welcomePage.completeTestingCatalog').split(' ');
  const isPatientVersion = useIsPatientVersion();

  // prevent animation on page load event
  const onFirstMouseOver = (e: MouseEvent<HTMLAnchorElement> | FocusEvent<HTMLAnchorElement>) => {
    if (!e.currentTarget.classList.contains('animated')) {
      e.currentTarget.classList.add('animated');
    }
  };

  return (
    <Link to={catalogUrls.products} className="fullTestsCatalog" onMouseOver={onFirstMouseOver} onFocus={onFirstMouseOver}>

      <div className={cn('fullTestsCatalog__bg', { fullTestsCatalog__bg_patients: isPatientVersion })} />

      <div className={cn('fullTestsCatalog__layer', { fullTestsCatalog__layer_patients: isPatientVersion })}>

        <div className="fullTestsCatalog__logoBg">
          <Logo className="fullTestsCatalog__logo" />
        </div>

        <div className="fullTestsCatalog__text">
          <Hero>{textArray[0]}</Hero>
          <Hero>{textArray[1]}</Hero>
          <Hero variant="M">{textArray[2]}</Hero>
        </div>

        <Arrow className="fullTestsCatalog__arrow" />
      </div>

    </Link>
  );
};

export default FullTestsCatalog;
