import styles from 'pages/GalateaDifference/About/components/LatestEvents/components/LatestEvent/LatestEvent.module.scss';
import { EventItem } from 'api/Events/types';
import { getDateInfo } from 'app/helpers/dateTime';
import { galateaDifferenceUrls } from 'app/routing';
import { Body, Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  event: EventItem;
}

const LatestEvent: React.FC<Props> = ({ event }) => {
  const startDateInfo = getDateInfo(event.startDate);
  const endDateInfo = getDateInfo(event.endDate);

  return (
    <div className={styles.latestEvent} key={event.id}>
      <Heading variant="H6" className={styles.latestEvent__title}>
        {startDateInfo.day} → {endDateInfo.day} {startDateInfo.month} {startDateInfo.year}
      </Heading>
      <Link to={galateaDifferenceUrls.eventsDetailUrl(event.meta.slug)}>
        <Heading variant="H5" className={styles.latestEvent__text}>
          {event.title}
        </Heading>
      </Link>
      <Body variant="S">{event.addressCity}, {event.addressState}</Body>
    </div>
  );
};

export default LatestEvent;
