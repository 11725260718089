import { PageWithSidebarContent, SectionItem, SidebarItem } from 'app/types/ProductCatalog';
import useInViewRefs from 'app/hooks/useInViewRefs';

import { useMemo } from 'react';

// titles must be memoized array to avoid Maximum update depth exceeded error
const usePageWithSidebarContent = (titles: string[], hideItemsWithIndices?: number[]): PageWithSidebarContent => {
  const inViewRefs = useInViewRefs();
  const sectionItems: SectionItem[] = titles.map((t, i): SectionItem => {
    return {
      id: t,
      ref: inViewRefs[i].ref,
      hide: hideItemsWithIndices?.includes(i),
    };
  });
  const sidebarItems: SidebarItem[] = useMemo(
    () => titles.map((t, i): SidebarItem => {
      return {
        text: t,
        inView: inViewRefs[i].inView,
        hide: hideItemsWithIndices?.includes(i),
      };
    }),
    [ inViewRefs, titles, hideItemsWithIndices ],
  );

  return {
    sidebarItems,
    sectionItems,
  };
};

export default usePageWithSidebarContent;
