import styles from 'pages/GalateaDifference/Licenses/component/Certificate/Certificate.module.scss';
import { Certificate as CertificateType } from 'api/Certificates/types';
import { getIconByType } from 'app/helpers/icons';
import { Caption, Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  certificate: CertificateType;
}

const Certificate: React.FC<Props> = ({ certificate }) => {
  return (
    <div className={cn(styles.certificate)}>

      <div className={styles.certificate__leftColumn}>

        {getIconByType('check')}

        <Link to={certificate.nationalCertificateUrl} className={styles.certificate__title} target="_blank" rel="noopener noreferrer">
          <Heading variant="H5">{certificate.title}</Heading>
        </Link>

      </div>

      <div className={styles.certificate__rightColumn}>

        {certificate.capNumber && (
          <div className={styles.certificate__property}>

            <Caption variant="L" className={styles.certificate__propertyTitle}>CAP</Caption>

            <Heading variant="H5">{certificate.capNumber}</Heading>

          </div>
        )}

        {certificate.capNumber && (
          <div className={styles.certificate__property}>

            <Caption variant="L" className={styles.certificate__propertyTitle}>AU-ID</Caption>

            <Heading variant="H5">{certificate.auId}</Heading>

          </div>
        )}

        {certificate.cliaNumber && (
          <div className={styles.certificate__property}>

            <Caption variant="L" className={styles.certificate__propertyTitle}>Certificate Number</Caption>

            <Heading variant="H5">{certificate.cliaNumber}</Heading>

          </div>
        )}

      </div>

    </div>
  );
};

export default Certificate;
