import styles from 'pages/Legal/LegalPage.module.scss';
import useIntersectionProps from 'app/hooks/useIntersectionProps';
import { Page, SectionItem, SidebarItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Table, TableRow } from 'components/Table';
import { Body, Heading } from 'components/Typography';
import appendix from 'pages/Legal/ResearchDataGovernance/assets/appendix.png';

import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const DataGovernance: React.FC<Page> = ({ setPageHeader }) => {
  const props = useIntersectionProps();

  const { ref: ref1, inView: ref1InView } = useInView(props);
  const { ref: ref2, inView: ref2InView } = useInView(props);
  const { ref: ref3, inView: ref3InView } = useInView(props);
  const { ref: ref4, inView: ref4InView } = useInView(props);
  const { ref: ref5, inView: ref5InView } = useInView(props);
  const { ref: ref6, inView: ref6InView } = useInView(props);
  const { ref: ref7, inView: ref7InView } = useInView(props);
  const { ref: ref8, inView: ref8InView } = useInView(props);
  const { ref: ref9, inView: ref9InView } = useInView(props);
  const { ref: ref10, inView: ref10InView } = useInView(props);
  const { ref: ref11, inView: ref11InView } = useInView(props);
  const { ref: ref12, inView: ref12InView } = useInView(props);
  const { ref: ref13, inView: ref13InView } = useInView(props);
  const { ref: ref14, inView: ref14InView } = useInView(props);
  const menuText1 = 'Last Updates';
  const menuText2 = 'Policy Statement';
  const menuText3 = '1. Introduction';
  const menuText4 = '2. Research data at Galatea Bio';
  const menuText5 = '3. The Policy framework and principles';
  const menuText6 = '3.1. Roles and responsibilities';
  const menuText7 = '3.2. Quality and integrity';
  const menuText8 = '3.3. Storage, retention and disposal of research data';
  const menuText9 = '3.4 Classification and security';
  const menuText10 = '4. Import and export of research material';
  const menuText11 = '5. Data accessibility';
  const menuText12 = '6. Failure to comply with the Policy';
  const menuText13 = '7. Further assistance';
  const menuText14 = 'APPENDIX 1 – RESEARCH DATA MANAGEMENT LIFECYCLE';
  const sectionItems: SectionItem[] = [
    { id: menuText1, ref: ref1 },
    { id: menuText2, ref: ref2 },
    { id: menuText3, ref: ref3 },
    { id: menuText4, ref: ref4 },
    { id: menuText5, ref: ref5 },
    { id: menuText6, ref: ref6 },
    { id: menuText7, ref: ref7 },
    { id: menuText8, ref: ref8 },
    { id: menuText9, ref: ref9 },
    { id: menuText10, ref: ref10 },
    { id: menuText11, ref: ref11 },
    { id: menuText12, ref: ref12 },
    { id: menuText13, ref: ref13 },
    { id: menuText14, ref: ref14 },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: menuText1, inView: ref1InView },
    { text: menuText2, inView: ref2InView && !ref1InView },
    { text: menuText3, inView: ref3InView && !ref2InView },
    { text: menuText4, inView: ref4InView && !ref3InView },
    { text: menuText5, inView: ref5InView && !ref4InView },
    { text: menuText6, inView: ref6InView && !ref5InView },
    { text: menuText7, inView: ref7InView && !ref6InView },
    { text: menuText8, inView: ref8InView && !ref7InView },
    { text: menuText9, inView: ref9InView && !ref8InView },
    { text: menuText10, inView: ref10InView && !ref9InView },
    { text: menuText11, inView: ref11InView && !ref10InView },
    { text: menuText12, inView: ref12InView && !ref11InView },
    { text: menuText13, inView: ref13InView && !ref12InView },
    { text: menuText14, inView: ref14InView && !ref13InView },
  ], [
    ref1InView,
    ref2InView,
    ref3InView,
    ref4InView,
    ref5InView,
    ref6InView,
    ref7InView,
    ref8InView,
    ref9InView,
    ref10InView,
    ref11InView,
    ref12InView,
    ref13InView,
    ref14InView,
  ]);

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <>
      <Helmet title="Research Data Governance and Materials Handling Policy" />
      <PageWithSidebar sidebarItems={sidebarItems} className={styles.legalPage}>
        <Heading variant="H1" style={{ marginBottom: 26 }}>Research Data Governance & Materials Handling Policy</Heading>

        <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={cn(styles.legalPage__section, styles.legalPage__section_lastChildIsTable)}>
          <Table id="dataGovernance" columnsWidth={[ '20%', '20%', '20%', '20%', '20%' ]}>
            <TableRow style={{ paddingTop: 13 }} small>
              <div style={{ paddingBottom: 24 }}>
                <Heading variant="H6">Version</Heading>
                <Body variant="S">1.0</Body>
              </div>

              <div>
                <Heading variant="H6">Approved by</Heading>
                <Body variant="S">Compliance Officer</Body>
              </div>

              <div>
                <Heading variant="H6">Approval Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Effective Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Next Review</Heading>
                <Body variant="S">May 2027</Body>
              </div>
            </TableRow>
          </Table>
        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText2}</Heading>

          <Table id="policyStatement" columnsWidth={[ '22%', '78%' ]}>
            <TableRow style={{ padding: '11px 0 13px' }}>
              <div>
                <Heading variant="H5">Purpose</Heading>
              </div>

              <div>
                <Body variant="L">
                  The Galatea Bio (GB) <i>Research Data Governance & Materials Handling Policy</i> (<b>‘the Policy’</b>) covers principles related to
                  maintaining the integrity, security, quality, and proper usage of research data and materials (<b>‘research data’</b>) at GB.<br />
                  The purpose of the Data Governance Policy is to:
                </Body>

                <ul style={{ marginBottom: 0 }}>
                  <li>
                    Outline the requirements and roles and responsibilities associated with access, retrieval, storage, disposal, and backup
                    of GB research data and materials
                  </li>
                  <li>
                    Provide best practice measures to enable compliance with the requirements
                  </li>
                  <li style={{ marginBottom: 0 }}>
                    Ensure that GB complies with applicable laws, regulations, and operational standards.
                  </li>
                </ul>
              </div>
            </TableRow>

            <TableRow style={{ padding: '11px 0 13px' }}>
              <div>
                <Heading variant="H5">Scope</Heading>
              </div>

              <div>
                <Body variant="L">
                  The Policy applies to:
                </Body>

                <ul style={{ marginBottom: 0 }}>
                  <li>
                    All GB Researchers and staff, contractors, consultants, and external parties engaged
                    in research activities associated or affiliated with GB.
                  </li>
                  <li>
                    All research data and materials used or generated at GB or during GB affiliated research.
                  </li>
                  <li style={{ marginBottom: 0 }}>
                    Research data and materials in any form, including but not limited to, print, electronic, audio visual, backup and archived data.
                  </li>
                </ul>
              </div>
            </TableRow>
          </Table>

          <Heading variant="H4" style={{ marginBottom: 0 }}>Policy Provisions</Heading>
        </div>

        <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText3}</Heading>

          <Body variant="L">
            Research data are a strategic asset of GB, making the appropriate governance regarding management and use
            of research data critical to our operations.
            Lack of governance could expose GB to unwanted risks and may lead to improper management of GB assets.
          </Body>

          <Body variant="L">
            Research data and material allow research findings to be validated and have long-term value as a potential resource for future research.
            Good practice in research data governance and materials handling benefits the wider research community by
            enabling future researchers to publish, share, cite and reuse the research data and material by reducing the risk of loss and corruption.
          </Body>

          <Body variant="L">
            ‘Good data management is good research’ is the principle upon which our approach to managing research data and material at GB is founded.
            This Policy has been developed in response to the specific needs of the research community. Additionally, an overarching GB Data Governance
            Framework (DGF) has been developed.
          </Body>
        </div>

        <div id={sectionItems[3].id} ref={sectionItems[3].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText4}</Heading>

          <Body variant="L">
            Research data and materials are the original sources that have been created, generated or collated in the conduct of research.
            They can be digital or non-digital.
          </Body>

          <Body variant="L">
            Research data and materials are data generated in the conduct of research that include, but are not limited to:
          </Body>

          <ul>
            <li>Any data that are generated in the conduct of research</li>
            <li>Any recorded data that are of significance to research undertaken by GB’s researchers</li>
            <li>Any source or primary materials, in particular where these may be necessary to validate research results</li>
            <li>Annotated bibliographies and readings</li>
            <li>Clinical records of treatments and test results</li>
            <li>Collection of digital objects acquired and generated during the process of research</li>
            <li>Contents of an application (e.g. input, output, logfiles for analysis software, simulation software, schemas)</li>
            <li>Curated data from other research projects</li>
            <li>Database contents (video, audio, text, images)</li>
            <li>Design portfolios, mock-ups</li>
            <li>Documentation of a research journey</li>
            <li>Documents (text, Word), spreadsheets</li>
            <li>Experimental results and the contents of laboratory notebooks, field notebooks, diaries</li>
            <li>Human / animal / geological materials (e.g. slides, artefacts, specimens, samples)</li>
            <li>Images or visualisations</li>
            <li>Metadata</li>
            <li>Methodologies and workflows</li>
            <li>Models, algorithms, scripts</li>
            <li>Notes, audiotapes, videotapes, (including those collected during interviews or by other means)</li>
            <li>
              Numerical or graphic results obtained as the output of simulations, modelling or any other software or as the result
              of analysis of experimental results
            </li>
            <li>
              Personnel and medical information and similar information the disclosure of which would constitute a clearly unwarranted invasion
              of personal privacy, such as information that could be used to identify a particular person in a research study
            </li>
            <li>Photographs, films</li>
            <li>Plant materials, cell/bacterial/viral samples or specimens</li>
            <li>Protein or genetic sequences</li>
            <li>Questionnaires, transcripts, codebooks</li>
            <li>Records of interviews in the form of notes, audio or video recordings</li>
            <li>References stored in management software (e.g. Zotero, Mendeley, Endnote)</li>
            <li>Responses to surveys or questionnaires</li>
            <li>Spectra</li>
            <li>Standard operating procedures and protocols</li>
            <li>Survey responses</li>
            <li>Test responses</li>
            <li>
              Trade secrets, commercial information, materials necessary to be held confidential by a researcher until they are published, or similar
              information that is protected under law.
            </li>
          </ul>

          <Body variant="L">
            <b>The following data and materials are not</b> considered to be research data and materials, unless the use of such data and
            materials is for research purposes, and they are outside the scope of the Policy:
          </Body>

          <ul>
            <li>Administrative data such as payroll, employee files, company records.</li>
            <li>Research management systems used by GB.</li>
            <li>Research publications.</li>
          </ul>
        </div>

        <div id={sectionItems[4].id} ref={sectionItems[4].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText5}</Heading>

          <Body variant="L">
            The following framework outlines the principles and minimum standards that are applicable to all individuals fitting the scope of the Policy:
          </Body>
        </div>

        <div id={sectionItems[5].id} ref={sectionItems[5].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText6}</Heading>

          <Body variant="L">
            The Policy applies to everyone involved in research at GB, e.g. researchers, staff and consultants.
            This Policy provides guidance on the different types of roles involved in a research project. A brief description of these roles follows below:
          </Body>

          <ul>
            <li>
              <b>Data Custodian:</b> GB, rather than any individual or department, is the Custodian of the data and materials and any information
              derived from the data. Original research data and primary materials generated in the conduct of research at GB will be owned and
              retained by the company subject to any contractual, statutory, ethical, and/or funding body requirements.
            </li>
            <li>
              <b>Chief Data Officer:</b> is responsible for the overall management of GB’s Data and Information Governance.
            </li>
            <li>
              <b>Data Governance Steering Committee:</b> is responsible for defining the overall vision and Data and Information Governance principles,
              and oversees initiatives designed to result in more effective and efficient use of data across GB.
            </li>
            <li>
              <b>Research Data Management Committee:</b> is the committee delegated by the Data Governance Steering Committee for the governance
              of research data at GB.
            </li>
            <li>
              <b>GB IT:</b> is responsible for establishing and maintaining secure locations to store research data.
            </li>
            <li>
              <b>Research Data Owners:</b> are typically Principal Investigators and are accountable for ensuring effective local protocols are in place
              to guide the appropriate use of their data and materials. They (or delegated Research Data Stewards) are responsible for ensuring that all legal,
              regulatory, and policy requirements are met in relation to the specific data and that the data and materials conform to legal, regulatory,
              exchange, and operational standards.<br />
              The Research Data Owner must ensure that the process for the administration of data and material is in accordance with the&nbsp;
              <Link to="#APPENDIX 1 – RESEARCH DATA MANAGEMENT LIFECYCLE">Research Data Management Lifecycle</Link>.<br />
              Research Data Owners are responsible for ensuring that the requirements outlined in the Policy can be implemented prior to approving
              a research project, including that there is sufficient space and resource to accommodate the research data and material.<b />
              Research Data Owners are responsible for maintaining a register of the establishment and ownership of databases containing confidential
              information specific to their research project or study. Access to these databases must be restricted to researchers with approved involvement
              in specific research projects.
            </li>
            <li>
              <b>Research Data Stewards:</b> are responsible for the quality, integrity, implementation and enforcement of data management within
              their research project. Every research dataset may have one or more Data Steward<b />
              The Research Data Steward will classify and approve user access to the data and materials, under delegation from a Research Data Owner,
              based upon the appropriateness of the User’s role and the intended use. Where necessary, approval from the Research Data Owner may be
              required prior to authorisation of access to other users.
            </li>
            <li>
              <b>Research Data Creators:</b> are people who create original research data and materials during a research project at GB.
              This includes professional and technical staff, collaborators at other institutions, contractors, and consultants who participate
              in a research project.
            </li>
            <li>
              <b>Data Specialists:</b> are subject matter experts in relation to the data and materials. They are specialists who are responsible
              for providing ongoing support in respect of research data and related systems.
            </li>
          </ul>
        </div>

        <div id={sectionItems[6].id} ref={sectionItems[6].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText7}</Heading>

          <Body variant="L">
            Everyone responsible for research projects at GB must ensure appropriate research data standards are followed to uphold
            the quality and integrity of the data they create, access, manage and destroy. This responsibility includes:
          </Body>

          <ul>
            <li>
              Keeping research data and material records up-to-date throughout every stage of the research project and in an auditable and traceable manner.
            </li>
            <li>
              Maintaining a catalog of all research data and materials in an accessible form that can be traced back to their source.
            </li>
            <li>
              Recording data that supports research conducted at GB (including electronic data) in a durable and appropriately referenced form.
            </li>
            <li>
              Making research data and material related to research outputs available for discussion with other researchers
              (unless confidentiality provisions apply).
            </li>
            <li>
              Ensuring research data and materials remain the property of GB, unless subject to a third- party agreement.
            </li>
            <li>
              Restricting the collection of research data and materials to legitimate uses, to optimise research outcomes and to add value to GB.
            </li>
            <li>
              Producing a written agreement between all relevant parties when research is carried out at multiple organisations, which clearly specifies
              the principles of storage, destruction and retention of research data and materials within each organization.
            </li>
            <li>
              Ensuring any confidential data and material, including data and materials held in computing systems, are kept appropriately secure according
              to any applicable privacy laws.
            </li>
          </ul>
        </div>

        <div id={sectionItems[7].id} ref={sectionItems[7].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText8}</Heading>

          <Body variant="L">
            Everyone responsible for research at GB must ensure that research data and materials, as well as registers of these data and materials,
            are kept in a format, and for a period, that conforms to the requirements of GB’s <i>Data and Record Retention Policy</i>, funding agencies’
            or publisher guidelines, or in accordance with discipline norms, whichever is the longer period.
          </Body>

          <Body variant="L">
            They should also ensure that:
          </Body>

          <ul>
            <li>
              Wherever possible, original research data and materials (and, where relevant, materials or samples) are retained in the school or research unit in
              which they are generated. If required, individual researchers can hold copies of the data for their own use are retained in the school
              or research unit in which they are generated. If required, individual researchers can hold copies of the data for their own use.
            </li>
            <li>
              Where research material is not kept within the school, a written record of the location of data is retained by the researcher and school.
            </li>
            <li>
              At the end of any research project hosted by GB, research data and materials remain the property of GB, unless subject to a third-party agreement.
            </li>
            <li>
              When the specified period of retention has finished, researchers fulfil their responsibility to dispose of research data and materials in a secure
              and safe manner and in accordance with GB policies and procedures.
            </li>
            <li>
              Where a researcher moves from GB, original data and materials remain at GB unless a written agreement has been reached with the researcher’s
              new organization covering ownership, use, storage and disposal of research data and materials.
            </li>
            <li>
              Wherever relevant, research data and materials storage and handling adhere to the conditions of human research ethics approvals.
            </li>
          </ul>
        </div>

        <div id={sectionItems[8].id} ref={sectionItems[8].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText9}:</Heading>

          <Body variant="L">
            People working on GB Research Projects must refer to the <i>Data Classification Standard</i> and the <i>Data Handling Guidelines</i> for information
            on classification and security requirements. To comply with these requirements, they must:
          </Body>

          <Body variant="L">
            Always use appropriate research data security measures to ensure the safety, quality and integrity of GB’s research data and materials.
          </Body>

          <ul>
            <li>
              Store research data in an electronic format that is protected by appropriate electronic safeguards and/or physical access controls that restrict
              access only to authorized user(s), including research data in any GB or external data repository (databases etc.).
            </li>
            <li>
              Ensure research data and materials are always accessible and available during the life of a research project unless subject to
              confidentiality/security provisions. This Policy applies to data in all formats (see Section 2 for examples).
            </li>
            <li>
              If research is undertaken in collaboration with other institutions, government agencies, or any third party, ensure that a written agreement
              is in place to cover research data and materials ownership, sharing, storage, accessibility, retention, and disposal.
            </li>
          </ul>
        </div>

        <div id={sectionItems[9].id} ref={sectionItems[9].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText10}</Heading>

          <Body variant="L">
            People working on GB research projects must obtain relevant permits before shipping any research materials that have import/export
            restrictions in the United States. An import license or permit, together with the appropriate packaging and labelling, will expedite
            the clearance of such packages through U.S. Customs. Researchers must ensure that they meet the relevant Customs and other
            requirements for the import or export of research material.
          </Body>
        </div>

        <div id={sectionItems[10].id} ref={sectionItems[10].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText11}</Heading>

          <Body variant="L">
            Researchers must make available any research data and materials related to publications for discussion with other researchers.
            Where confidentiality provisions apply (for example, where the researchers or the institution have given undertakings to third parties,
            such as the subjects of the research), it is desirable for researchers to keep data in a way that allows necessary third parties to
            reference the information without breaching such confidentiality.
          </Body>
        </div>

        <div id={sectionItems[11].id} ref={sectionItems[11].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText12}</Heading>

          <Body variant="L">
            The Policy forms part of the GB Code of Conduct (‘the Code’). Non-compliance with any of the requirements of the Policy may be considered as a
            breach of the Code or other GB policies and procedures.
          </Body>
        </div>

        <div id={sectionItems[12].id} ref={sectionItems[12].ref} className={cn(styles.legalPage__section, styles.legalPage__section_lastChildIsTable)}>
          <Heading variant="H4">{menuText13}</Heading>

          <Body variant="L">
            Any staff member who requires assistance in understanding the Policy should first consult their supervisor who is responsible for the implementation
            and operation of these arrangements in their department. Should further assistance be needed, the staff member should contact the
            Responsible Officer for clarification.
          </Body>

          <Table columnsWidth={[ '22%', '78%' ]} style={{ marginBottom: 0 }} id="furtherAssistance">
            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Accountabilities</Heading>
              </div>
              <div />
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Responsible Officer</Heading>
              </div>

              <div>
                <Body variant="L">
                  Chief Administrative Officer
                </Body>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Supporting Information</Heading>
              </div>
              <div />
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Regulatory Compliance</Heading>
              </div>

              <div>
                <Body variant="L">
                  This Policy supports GB’s compliance with the following legislation: HIPAA, the Common Rule, Genetic Information Nondiscrimination Act
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Superseded Documents</Heading>
              </div>

              <div>
                <Body variant="L">
                  None
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">File Number</Heading>
              </div>

              <div>
                <Body variant="L">
                  [Policy ID]
                </Body>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Definitions and Acronyms</Heading>
              </div>
              <div />
            </TableRow>

            <TableRow>
              <div style={{ width: '100%' }}>
                <Body variant="L">To establish operational definitions and facilitate ease of reference, the following terms are defined:</Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Access</Heading>
              </div>

              <div>
                <Body variant="L">
                  The right to read, copy, or query data
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data set</Heading>
              </div>

              <div>
                <Body variant="L">
                  A term use to denote a set of research data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Chief Data Officer</Heading>
                <Body variant="S">CDO</Body>
              </div>

              <div>
                <Body variant="L">
                  Senior officer of GB responsible for Data and Information Governance.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Institutional data</Heading>
              </div>

              <div>
                <Body variant="L">
                  The representation of facts, concepts or instructions in a formalised (consistent and agreed) manner suitable for communication,
                  interpretation or processing by human or automatic means. Typically comprised of numbers, words or images. The format and presentation
                  of data may vary with the context in which they are used.
                </Body>

                <Body variant="L">
                  Data are not Information until used in a particular context for a particular purpose. Data are typically considered to be conceptually
                  at the lowest level of abstraction.
                </Body>

                <Body variant="L">
                  In the context of this Policy this term includes all institutional data including research, administrative, genomic, clinical,
                  and diagnostic artefacts.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research data and materials</Heading>
              </div>

              <div>
                <Body variant="L">
                  Research data and materials are the original sources or material that have been created, generated or collated in the conduct of research.
                  They can be digital or non- digital. The response to a particular research question is based on the analysis
                  of the research data and material.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Examples of research data</Heading>
              </div>

              <div>
                <Body variant="L">
                  Research data and material may include but are not limited to:
                </Body>

                <ul>
                  <li>any data that are generated in the conduct of research</li>
                  <li>clinical records of treatments and test results</li>
                  <li>annotated bibliographies and readings</li>
                  <li>records of interviews in the form of notes, audio or video recordings</li>
                  <li>audiotapes, videotapes</li>
                  <li>collection of digital objects acquired and generated during the process of research</li>
                  <li>contents of an application (e.g. input, output, logfiles for analysis software, simulation software, schemas)</li>
                  <li>curated data from other research projects</li>
                  <li>database contents (video, audio, text, images)</li>
                  <li>design portfolios, mock-ups</li>
                  <li>documentation of the research journey</li>
                  <li>documents (text, Word), spreadsheets</li>
                  <li>experimental results and the contents of laboratory notebooks, field notebooks, diaries</li>
                  <li>human/animal materials (e.g. slides, artefacts, specimens, samples)</li>
                  <li>images or visualisations</li>
                  <li>metadata</li>
                  <li>methodologies and workflows</li>
                  <li>models, algorithms, scripts</li>
                  <li>photographs, films</li>
                  <li>protein or genetic sequences</li>
                  <li>questionnaires, transcripts, codebooks</li>
                  <li>references stored in management software</li>
                  <li>responses to surveys or questionnaires</li>
                  <li>
                    numerical or graphic results obtained as the output of simulations, modelling or any other software or as the result of
                    analysis of experimental results
                  </li>
                  <li>plant materials, cell/bacterial/viral samples or specimens</li>
                  <li>spectra</li>
                  <li>standard operating procedures and protocols</li>
                  <li>survey and test responses</li>
                  <li>any other source or primary materials, in particular where these may be necessary to validate research results</li>
                  <li>
                    trade secrets, commercial information, materials necessary to be held confidential by a researcher until they are published,
                    or similar information that is protected under law
                  </li>
                  <li>
                    personnel and medical information and similar information the disclosure of which would constitute a clearly unwarranted invasion
                    of personal privacy, such as information that could be used to identify a particular person in a research study
                  </li>
                  <li>any other recorded data that are of significance to research undertaken by GB&apos;s researchers.</li>
                </ul>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research Data Creator</Heading>
              </div>

              <div>
                <Body variant="L">
                  Research Data Creators are persons responsible for the creation of research data and primary materials.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Governance Steering Committee</Heading>
                <Body variant="S">DGSC</Body>
              </div>

              <div>
                <Body variant="L">
                  A company-wide committee with members consisting of Data Executives, Data Owners, Data Stewards and designated Data Users,
                  and professional staff members.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Data Specialist</Heading>
              </div>

              <div>
                <Body variant="L">
                  Data Specialists are subject matter experts in relation to the data or information asset.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Information Security Management System</Heading>
                <Body variant="S">ISMS</Body>
              </div>

              <div>
                <Body variant="L">
                  The ISMS provides information security governance and sets out people, process and technology related controls to assure the confidentiality,
                  integrity and availability of all GB data. The ISMS is a response to GB data classification and data handling requirements.
                  Moreover, the deployment and measurement of ISMS controls provides input into the risk management process enabling informed
                  business decisions.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Integrity or data integrity</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refers to the accuracy and consistency of data over their entire lifecycle.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Management Board</Heading>
                <Body variant="S">MB</Body>
              </div>

              <div>
                <Body variant="L">
                  The senior executive team of GB.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Record</Heading>
              </div>

              <div>
                <Body variant="L">
                  Any recorded information made or received by a staff member of GB in the course of undertaking their duties.
                  Records are evidence or information about GB activities. They exist in any format.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research Data Management Committee</Heading>
              </div>

              <div>
                <Body variant="L">
                  Is responsible for providing consultation and input in relation to research data governance, data quality, and research data and materials
                  management activities across GB.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research Data Management Lifecycle</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refers to the process for planning, creating, managing, storing, implementing, protecting, improving and disposing
                  of all research data across GB.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research Data Owner</Heading>
              </div>

              <div>
                <Body variant="L">
                  Accountable for research data and materials including, but not limited to: the collection, development, maintenance,
                  distribution and security of research data and materials.<br />
                  Research Data Owners are expected to have high-level knowledge and expertise in the content of data and materials within their
                  responsible area
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research Data Steward</Heading>
              </div>

              <div>
                <Body variant="L">
                  Every research data set may have one or more Research Data Steward, who are accountable for the quality and integrity,
                  implementation and enforcement of data management within their research project.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Research Data User</Heading>
              </div>

              <div>
                <Body variant="L">
                  Any staff member, contractor, consultant, third party or authorised agent who accesses, inputs, amends, deletes, extracts or analyses
                  research data. Data Users are not generally involved in the governance process but are responsible for the quality assurance of data.
                </Body>
              </div>
            </TableRow>

            <TableRow>
              <div>
                <Heading variant="H5">Security</Heading>
              </div>

              <div>
                <Body variant="L">
                  Refers to the safety of GB data in relation to the following criteria:
                </Body>

                <ul style={{ marginBottom: 0 }}>
                  <li>Access control;</li>
                  <li>authentication;</li>
                  <li>effective incident detection, reporting and solution;</li>
                  <li>physical and virtual security;</li>
                  <li style={{ marginBottom: 0 }}>and change management and version control.</li>
                </ul>
              </div>
            </TableRow>

            <TableRow highlighted>
              <div style={{ width: '100%' }}>
                <Heading variant="H4" style={{ marginBottom: 0 }}>Revision History</Heading>
              </div>
              <div />
            </TableRow>
          </Table>

          <Table id="revisionHistory" style={{ borderColor: 'transparent' }} columnsWidth={[ '20%', '20%', '20%', '20%', '20%' ]}>
            <TableRow small>
              <div>
                <Heading variant="H6">Version</Heading>
                <Body variant="S">1.0</Body>
              </div>

              <div>
                <Heading variant="H6">Approved by</Heading>
                <Body variant="S">Compliance Officer</Body>
              </div>

              <div>
                <Heading variant="H6">Approval Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Effective Date</Heading>
                <Body variant="S">October 1, 2024</Body>
              </div>

              <div>
                <Heading variant="H6">Sections modified</Heading>
                <Body variant="S">New Policy</Body>
              </div>
            </TableRow>
          </Table>
        </div>

        <div id={sectionItems[13].id} ref={sectionItems[13].ref} className={styles.legalPage__section}>
          <Heading variant="H4">{menuText14}</Heading>

          <Body variant="L">
            The Research Data Management Lifecycle refers to the process for planning, creating, managing, storing, implementing, protecting,
            improving and disposing of all institutional research data of Galatea Bio .
          </Body>

          <img src={appendix} alt="Research Data Management Lifecycle" className={styles.legalPage__lifeCycle} />
        </div>
      </PageWithSidebar>
    </>
  );
};

export default DataGovernance;
