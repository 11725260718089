import 'App.scss';
import appBgVideo from 'app/assets/dnachain.mp4';
import Context from 'app/consts/context';
import useSetSiteVersion from 'app/hooks/useSetSiteVersion';
import { catalogUrls, galateaDifferenceUrls, legalUrls, patientsUrls, providersUrls } from 'app/routing';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import RouteChangeTracker from 'components/RouteChangeTracker/RouteChangeTracker';
import VideoBackground from 'components/VideoBackground/VideoBackground';
import About from 'pages/GalateaDifference/About/About';
import Compliance from 'pages/GalateaDifference/Compliance/Compliance';
import CCCPS from 'pages/GalateaDifference/Compliance/components/CCCPS/CCCPS';
import ESG from 'pages/GalateaDifference/Compliance/components/ESG/ESG';
import Nondiscrimination from 'pages/GalateaDifference/Compliance/components/Nondiscrimination/Nondiscrimination';
import Events from 'pages/GalateaDifference/Events/Events';
import Event from 'pages/GalateaDifference/Events/components/Event/Event';
import LeadershipTeam from 'pages/GalateaDifference/LeadershipTeam/LeadershipTeam';
import Licenses from 'pages/GalateaDifference/Licenses/Licenses';
import News from 'pages/GalateaDifference/News/News';
import PieceOfNews from 'pages/GalateaDifference/News/components/PieceOfNews/PieceOfNews';
import Publications from 'pages/GalateaDifference/Publications/Publications';
import Publication from 'pages/GalateaDifference/Publications/components/Publication/Publication';
import CookiePolicy from 'pages/Legal/CookiePolicy/CookiePolicy';
import DataGovernance from 'pages/Legal/DataGovernance/DataGovernance';
import HIPAANotice from 'pages/Legal/HIPAANotice/HIPAANotice';
import PrivacyPolicy from 'pages/Legal/PrivacyPolicy/PrivacyPolicy';
import ResearchDataGovernance from 'pages/Legal/ResearchDataGovernance/ResearchDataGovernance';
import PatientRoot from 'pages/Patient/PatientRoot';
import FAQ from 'pages/Patient/FAQ/FAQ';
import { HowItWorks as HowItWorksPatients } from 'pages/Patient/HowItWorks/HowItWorks';
import SubmitSample from 'pages/Patient/SubmitSample/SubmitSample';
import ProductCatalog from 'pages/ProductCatalog/ProductCatalog';
import Product from 'pages/ProductCatalog/components/Product/Product';
import PanelsCatalog from 'pages/ProductCatalog/components/PanelsCatalog/PanelsCatalog';
import Comparing from 'pages/ProductCatalog/components/Comparing/Comparing';
import Panel from 'pages/ProductCatalog/components/Panel/Panel';
import ProviderRoot from 'pages/Provider/ProviderRoot';
import ClinicalGeneticTesting from 'pages/Provider/ClinicalGeneticTesting/ClinicalGeneticTesting';
import { HowItWorks as HowItWorksProviders } from 'pages/Provider/HowItWorks/HowItWorks';
import Insurance from 'pages/Provider/Insurance/Insurance';
import OrderTest from 'pages/Provider/OrderTest/OrderTest';
import Resources from 'pages/Provider/Resources/Resources';
import WelcomePage from 'pages/WelcomePage/WelcomePage';

import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

function App() {
  const { setPathPageView, setTrackPageView } = useTrackingCode();
  const containerRef = useRef<HTMLElement | null>(null);
  const [ pageHeader, setPageHeader ] = useState<ReactNode>();
  const location = useLocation();
  const isMainPage = location.pathname === '/';
  const { siteVersion, setSiteVersion } = useSetSiteVersion(isMainPage, containerRef);

  const value = useMemo(
    () => ({ siteVersion, setSiteVersion }),
    [ siteVersion, setSiteVersion ],
  );

  return (
    <Context.Provider value={value}>

      <Helmet titleTemplate="Galatea Bio - %s">
        {!window.Cypress && (
          <script type="text/javascript" id="hs-script-loader" async defer src={`//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}.js`} />
        )}
      </Helmet>

      {!window.Cypress && <RouteChangeTracker setPathPageView={setPathPageView} setTrackPageView={setTrackPageView} />}

      <Header pageHeader={pageHeader} />

      {isMainPage && <VideoBackground video={appBgVideo} />}

      <main ref={containerRef} className="container" data-testid="container">

        <Routes>
          <Route path="/" index element={<WelcomePage setPageHeader={setPageHeader} />} />

          <Route path={catalogUrls.products} element={<ProductCatalog setPageHeader={setPageHeader} />} />
          <Route path={catalogUrls.productDetail} element={<Product setPageHeader={setPageHeader} />} />
          <Route path={catalogUrls.panels} element={<PanelsCatalog setPageHeader={setPageHeader} />} />
          <Route path={catalogUrls.panelDetail} element={<Panel setPageHeader={setPageHeader} />} />
          <Route path={catalogUrls.subsetComparing} element={<Comparing setPageHeader={setPageHeader} />} />

          <Route path={providersUrls.providers} element={<ProviderRoot />}>
            <Route path={providersUrls.howItWorks} element={<HowItWorksProviders setPageHeader={setPageHeader} />} />
            <Route path={providersUrls.insurance} element={<Insurance setPageHeader={setPageHeader} />} />
            <Route path={providersUrls.orderTest} element={<OrderTest setPageHeader={setPageHeader} />} />
            <Route path={providersUrls.clinicalGeneticTesting} element={<ClinicalGeneticTesting setPageHeader={setPageHeader} />} />
            <Route path={providersUrls.resources} element={<Resources setPageHeader={setPageHeader} />} />
          </Route>

          <Route path={patientsUrls.patients} element={<PatientRoot />}>
            <Route path={patientsUrls.faq} element={<FAQ setPageHeader={setPageHeader} />} />
            <Route path={patientsUrls.howItWorks} element={<HowItWorksPatients setPageHeader={setPageHeader} />} />
            <Route path={patientsUrls.submitSample} element={<SubmitSample setPageHeader={setPageHeader} />} />
          </Route>

          <Route path={galateaDifferenceUrls.galateaDifference} element={<About setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.compliance} element={<Compliance setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.cccps} element={<CCCPS setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.esg} element={<ESG setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.nonDiscrimination} element={<Nondiscrimination setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.events} element={<Events setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.eventsDetail} element={<Event setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.leadership} element={<LeadershipTeam setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.licenses} element={<Licenses setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.news} element={<News setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.newsDetail} element={<PieceOfNews setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.publications} element={<Publications setPageHeader={setPageHeader} />} />
          <Route path={galateaDifferenceUrls.publicationsDetail} element={<Publication setPageHeader={setPageHeader} />} />

          <Route path={legalUrls.cookiePolicy} element={<CookiePolicy setPageHeader={setPageHeader} />} />
          <Route path={legalUrls.dataGovernance} element={<DataGovernance setPageHeader={setPageHeader} />} />
          <Route path={legalUrls.hipaaNotice} element={<HIPAANotice setPageHeader={setPageHeader} />} />
          <Route path={legalUrls.privacyPolicy} element={<PrivacyPolicy setPageHeader={setPageHeader} />} />
          <Route path={legalUrls.researchDataGovernance} element={<ResearchDataGovernance setPageHeader={setPageHeader} />} />
        </Routes>

      </main>

      <Footer showTopBorder={!isMainPage} />

    </Context.Provider>
  );
}

export default App;
