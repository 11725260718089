import styles from 'pages/GalateaDifference/LeadershipTeam/components/TeamMemberListItem/TeamMemberListItem.module.scss';
import { TeamMember as TeamMemberType } from 'api/Leadership/types';
import { Body } from 'components/Typography';

import React from 'react';

interface Props {
  teamMember: TeamMemberType
  onClick: (teamMember: TeamMemberType) => void;
}

const TeamMemberListItem: React.FC<Props> = ({ teamMember, onClick }) => {
  const setTeamMember = () => onClick(teamMember);

  return (
    <div className={styles.teamMemberListItem} onClick={setTeamMember}>
      <div className={styles.teamMemberListItem__photo}>
        <div style={{ backgroundImage: `url(${teamMember.photo})` }} />
      </div>
      <Body variant="S">{teamMember.title}</Body>
      <Body variant="S" className={styles.teamMemberListItem__jobTitle}>{teamMember.jobTitle}</Body>
    </div>
  );
};

export default TeamMemberListItem;
