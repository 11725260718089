import 'pages/ProductCatalog/components/Product/components/NextGenerationSequencing/NextGenerationSequencing.scss';
import { catalogUrls } from 'app/routing';
import { SectionItem } from 'app/types/ProductCatalog';
import ProductBlock from 'pages/ProductCatalog/components/ProductBlock/ProductBlock';
import { Body, Heading } from 'components/Typography';

import React from 'react';

interface Props {
  sectionItems: SectionItem[];
}

const NextGenerationSequencing: React.FC<Props> = ({ sectionItems }) => {
  return (
    <div className="nextGenerationSequencing">

      <div id={sectionItems[0].id} ref={sectionItems[0].ref} className="nextGenerationSequencing__blocks">
        <div className="nextGenerationSequencing__block">
          <Heading variant="H5">Whole Genome Sequencing for Hereditary Risk</Heading>

          <Body variant="M" style={{ marginTop: 24 }}>
            Whole Genome Sequencing (WGS) has revolutionized our understanding of genetic diseases and holds immense potential for identifying hereditary risks.
            WGS analyzes the entire DNA sequence of an individual, encompassing both protein-coding and non-protein-coding regions.
            It offers the most comprehensive genetic analysis currently available in clinical settings.
          </Body>

          <Body variant="M" style={{ marginTop: 17 }}>
            By sequencing an individual&apos;s entire genome, we can identify genetic variants associated with an increased risk of developing certain diseases.
            These variants can be inherited from parents or may be spontaneous mutations.
          </Body>
        </div>

        <div className="nextGenerationSequencing__block">
          <Heading variant="H5">Whole Exome Sequencing for Hereditary Risk</Heading>

          <Body variant="M" style={{ marginTop: 24 }}>
            Whole Exome Sequencing (WES) is a genetic testing method that focuses on sequencing only the protein-coding regions of the genome,
            known as the exome. While less comprehensive than WGS, WES is more cost-effective and often sufficient for identifying disease-causing variants.
          </Body>

          <Heading variant="H5" style={{ marginTop: 18, marginBottom: 18 }}>Limitations of WES:</Heading>

          <ul>
            <li>
              Excludes non-coding regions: WES does not capture genetic variations in regulatory regions
              or other non-coding parts of the genome that may be important for disease development.
            </li>
            <li>
              Limited in certain cases: For some genetic disorders caused by mutations in non-coding regions, WES may not be the most appropriate test.
            </li>
            <li>
              It is not able to identify some structural variants such as copy number variations (CNV) or large insertions and deletions.
            </li>
          </ul>
        </div>
      </div>

      <div id={sectionItems[1].id} ref={sectionItems[1].ref}>
        <Heading variant="H5" style={{ marginTop: 34, marginBottom: 24 }}>Key applications include:</Heading>

        <ul>
          <li>
            Identifying disease-causing mutations: Next generation sequencing can pinpoint the genetic alterations responsible for
            a specific inherited condition.
          </li>
          <li>
            Carrier screening: It can detect if individuals carry recessive genes for diseases that might be passed on to their offspring.
          </li>
          <li>
            Predictive testing: It can identify genetic variants associated with an increased risk of developing certain diseases later in life,
            such as some types of cancer or heart disease.
          </li>
          <li>
            Increased diagnostic yield: By comparing multiple family members, it becomes easier to identify the genetic cause of a disease.
          </li>
          <li>
            Enhanced variant interpretation: Analyzing family members can help determine the inheritance pattern of a variant and assess its pathogenicity.
          </li>
          <li>
            Identification of de novo mutations: Trio analysis is particularly useful for detecting new genetic mutations that occur in the affected offspring.
          </li>
        </ul>
      </div>

      <div id={sectionItems[2].id} ref={sectionItems[2].ref} className="nextGenerationSequencing__analysis">

        <div>
          <Heading variant="H5" style={{ marginBottom: 5 }}>Trio Analysis</Heading>
          <Body variant="M">
            A trio analysis involves sequencing the genomes (or exomes) of both parents and their offspring. By comparing the genomes of the family members,
            it becomes easier to identify de novo mutations (genetic changes that occur for the first time in the affected child) or
            inherited recessive conditions.
          </Body>
        </div>

        <div>
          <Heading variant="H5" style={{ marginBottom: 5 }}>Duo Analysis</Heading>
          <Body variant="M">
            A duo analysis involves sequencing the genomes (or exomes) of the offspring and one parent. While less informative than a trio,
            it can still be valuable for identifying certain types of genetic variants, such as dominant or X-linked conditions.
          </Body>
        </div>

        <div>
          <Heading variant="H5" style={{ marginBottom: 5 }}>Proband Analysis</Heading>
          <Body variant="M">
            A proband analysis involves sequencing the genome (or exome) of the affected individual only.
            This approach is less informative than trio or duo analysis but can still be useful in identifying certain genetic conditions,
            especially those with a high penetrance or a strong family history.
          </Body>
        </div>

      </div>

      <ProductBlock
        style={{ marginTop: 47 }}
        small
        description="Explore our catalog to select the test most appropriate for your patient"
        primaryButton={{ text: 'Testing Catalog', url: catalogUrls.panels }}
      />

    </div>
  );
};

export default NextGenerationSequencing;
