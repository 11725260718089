import 'components/Typography/Body/Body.scss';

import React, { CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  variant?: 'L' | 'M' | 'S';
  style?: CSSProperties;
  className?: string;
}

const Body: React.FC<Props> = ({ children, style, variant = 'S', className }) => {
  const classes = cn('body', `body_${variant}`, className);

  return (
    <p className={classes} style={style}>{children}</p>
  );
};

export default Body;
