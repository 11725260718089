import styles from 'components/SampleCollectionInstructions/SampleCollectionInstructions.module.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { ReactComponent as Image1 } from 'components/SampleCollectionInstructions/assets/img1.svg';
import { ReactComponent as Image2 } from 'components/SampleCollectionInstructions/assets/img2.svg';
import { ReactComponent as Image3 } from 'components/SampleCollectionInstructions/assets/img3.svg';
import { ReactComponent as Image4 } from 'components/SampleCollectionInstructions/assets/img4.svg';
import { ReactComponent as Image5 } from 'components/SampleCollectionInstructions/assets/img5.svg';
import { ReactComponent as Precautions } from 'components/SampleCollectionInstructions/assets/precautions.svg';
import { Body, Heading } from 'components/Typography';

import React from 'react';
import cn from 'classnames';

const SampleCollectionInstructions: React.FC = () => {
  const isPatientVersion = useIsPatientVersion();
  const imageClasses = cn(styles.sampleCollectionInstructions__itemImage, { [styles.sampleCollectionInstructions__itemImage_patients]: isPatientVersion });

  return (
    <>
      <Heading variant="H3">Sample Collection Instructions</Heading>
  
      <div className={styles.sampleCollectionInstructions}>
        <Body variant="L" className={styles.sampleCollectionInstructions__text}>
          This protocol explains how to self-collect samples using ORACOLLECT.DX (OCD-100) tubes
        </Body>
  
        <div className={styles.sampleCollectionInstructions__precautions}>
  
          <div className={styles.sampleCollectionInstructions__precautionsImage}>
            <Precautions />
          </div>
  
          <div>
  
            <Heading variant="H6" className={styles.sampleCollectionInstructions__precautionsTitle}>
              Collection precautions:
            </Heading>
  
            <Body variant="S">
              Donor should not eat, drink, smoke or chew gum for 30 minutes before collecting a buccal sample.
              Ensure the sponge tip does NOT come into contact with any surface prior to collection.
            </Body>
  
          </div>
  
        </div>
  
      </div>
  
      <div className={styles.sampleCollectionInstructions__items}>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={imageClasses}>
            <Image1 />
          </div>
          <Body variant="L">
            Open the package and remove the collector without touching the sponge tip. Place the sponge as far back in the mouth as comfortable
            and rub along the lower gums in a back-and-forth motion. Gently rub the gums 10 times. Avoid rubbing the teeth.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={imageClasses}>
            <Image2 />
          </div>
          <Body variant="L">
            Gently repeat rubbing motion on the opposite side of the mouth along the lower gums for an additional 10 times.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={imageClasses}>
            <Image3 />
          </div>
          <Body variant="L">
            Hold the tube upright to prevent the stabilizing liquid inside the tube from spilling.
            Unscrew the blue cap from the collection tube without touching the sponge.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={imageClasses}>
            <Image4 />
          </div>
          <Body variant="L">
            Turn the cap upside down, insert the sponge into the tube and close the cap tightly.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={imageClasses}>
            <Image5 />
          </div>
          <Body variant="L">
            Invert the capped tube and shake vigorously 10 times.
          </Body>
        </div>
  
      </div>
    </>
  );
};

export default SampleCollectionInstructions;
