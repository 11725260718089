import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  setPathPageView: (path: string) => void;
  setTrackPageView: () => void;
}

const RouteChangeTracker: React.FC<Props> = ({ setPathPageView, setTrackPageView }) => {
  const location = useLocation();
  const prevPathname = useRef<string>();

  useEffect(() => {
    if (prevPathname.current === null || prevPathname.current !== location.pathname) {
      setPathPageView(location.pathname);
      setTrackPageView();
    }

    prevPathname.current = location.pathname;
  }, [ location, setPathPageView, setTrackPageView ]);

  return null;
};

export default RouteChangeTracker;
