import styles from 'components/BorderedLink/BorderedLink.module.scss';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Icon } from 'app/types/Icons';
import { Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  title: string;
  link: string;
  icon: Icon;
}

const BorderedLink: React.FC<Props> = ({ title, link, icon }) => {
  const isPatientVersion = useIsPatientVersion();

  return (
    <Link to={link} className={cn(styles.borderedLink, { [styles.borderedLink_patients]: isPatientVersion })}>
      {getIconByType(icon)}
      <Heading variant="H5">{title}</Heading>

      <div className={styles.borderedLink__arrow}>{getIconByType('chevron-right')}</div>
    </Link>
  );
};

export default BorderedLink;
