import 'pages/ProductCatalog/components/Comparing/Comparing.scss';
import { getProductTypeTitle } from 'app/helpers/catalog';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { catalogUrls, patientsUrls, providersUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import ComparingContent from 'pages/ProductCatalog/components/Comparing/components/ComparingContent/ComparingContent';
import useComparing from 'pages/ProductCatalog/components/Comparing/hooks/useComparing';

import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Comparing: React.FC<Page> = ({ setPageHeader }) => {
  const { panelSlug, subsetSlug } = useParams<{ panelSlug: string, subsetSlug: string }>();
  const { fetchComparing, comparing, isLoading } = useComparing();
  const { subset, testingPanel } = comparing;
  const { productCategory, genesAmount } = testingPanel;
  const isCommon = productCategory === null;
  const isPrs = productCategory === 'PRS';
  const productTypeTitle = useMemo(() => getProductTypeTitle(isPrs), [ isPrs ]);
  const hideGenesSection = useMemo(() => {
    return genesAmount === 0 ? [ 1 ] : [];
  }, [ genesAmount ]);
  const isPatientVersion = useIsPatientVersion();
  const url = isPatientVersion ? patientsUrls.howItWorks : providersUrls.howItWorks;
  const title = `${testingPanel.title} vs ${subset.title}`;

  useEffect(() => {
    const abortController = new AbortController();
    if (panelSlug && subsetSlug) {
      fetchComparing(panelSlug, subsetSlug, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchComparing, panelSlug, subsetSlug ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: catalogUrls.products },
    { text: 'Catalog', link: catalogUrls.panels },
    ...(!isCommon ? [ { text: productTypeTitle, link: catalogUrls.panelsWithSort(productCategory) } ] : []),
    { text: testingPanel.title, link: catalogUrls.panelDetailUrl(testingPanel.slug) },
    { text: 'Comparing' },
  ], [ testingPanel, isCommon, productCategory, productTypeTitle ]);

  const sectionTitles = useMemo(() => [ 'Description', 'Genes', 'Specifications', 'Limitations' ], []);
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles, hideGenesSection);

  const OrderButton = useMemo(() => (
    <Link to={url} className="comparing__howToOrder">
      <Button onClick={() => {}} size="L" icon={getIconByType('shopping-cart-add')}>How to order</Button>
    </Link>
  ), [ url ]);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader
        title={title}
        sidebarItems={sidebarItems}
        breadcrumbItems={breadcrumbItems}
        rightColumn={OrderButton}
      />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, sidebarItems, title, isLoading, OrderButton ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={`Comparing: ${title}`} />
      <PageWithSidebar sidebarItems={sidebarItems}>
        <ComparingContent panel={testingPanel} subset={subset} sectionItems={sectionItems} />
      </PageWithSidebar>
    </>
  );
};

export default Comparing;
