import 'pages/WelcomePage/components/RelentlessCommitment/RelentlessCommitment.scss';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { patientsUrls, providersUrls } from 'app/routing';
import Button from 'components/Button/Button';
import { Body, Heading } from 'components/Typography';
import frame1 from 'pages/WelcomePage/components/RelentlessCommitment/assets/frame1.png';
import frame2 from 'pages/WelcomePage/components/RelentlessCommitment/assets/frame2.png';
import frame3 from 'pages/WelcomePage/components/RelentlessCommitment/assets/frame3.png';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';

const RelentlessCommitment: React.FC = () => {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const isPatientVersion = useIsPatientVersion();
  const url = isPatientVersion ? patientsUrls.howItWorks : providersUrls.howItWorks;

  useEffect(() => {
    const e = document.querySelector('.relentlessCommitment');
    if (inView && e && !e.classList.contains('animated')) {
      e.classList.add('animated');
    }
  }, [ inView ]);

  return (
    <div className={cn('relentlessCommitment', { relentlessCommitment_patients: isPatientVersion })} ref={ref}>

      <div className="relentlessCommitment__content">
        <Heading variant="H1" className="relentlessCommitment__title">{t('welcomePage.relentlessCommitment')}</Heading>

        <Body variant="S" className="relentlessCommitment__body">{t('welcomePage.truthInScience')}</Body>

        <Link to={url} className="relentlessCommitment__button">
          <Button icon={getIconByType('play-circle')} size="L">{t('welcomePage.getStartedToday')}</Button>
        </Link>
      </div>

      <div className="relentlessCommitment__images">
        <img src={frame1} alt="image1" className="relentlessCommitment__image relentlessCommitment__image1" />
        <img src={frame2} alt="image2" className="relentlessCommitment__image relentlessCommitment__image2" />
        <img src={frame3} alt="frame" className="relentlessCommitment__frame" />
      </div>

    </div>
  );
};

export default RelentlessCommitment;
