import { getProductTypeTitle } from 'app/helpers/catalog';
import { catalogUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import CommonPanelContent from 'pages/ProductCatalog/components/Panel/components/PanelContent/CommonPanelContent';
import NGSPanelContent from 'pages/ProductCatalog/components/Panel/components/PanelContent/NGSPanelContent';
import PRSPanelContent from 'pages/ProductCatalog/components/Panel/components/PanelContent/PRSPanelContent';
import PanelMeta from 'pages/ProductCatalog/components/Panel/components/PanelMeta/PanelMeta';
import usePanel from 'pages/ProductCatalog/components/Panel/hooks/usePanel';
import usePanelContent from 'pages/ProductCatalog/components/Panel/hooks/usePanelContent';

import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Panel: React.FC<Page> = ({ setPageHeader }) => {
  const { panelSlug } = useParams<{ panelSlug: string }>();
  const { fetchPanel, panel, isLoading } = usePanel();
  const { productCategory, title } = panel;
  const { sidebarItems, sectionItems } = usePanelContent(panel);
  const isCommon = productCategory === null;
  const isPrs = productCategory === 'PRS';
  const isNgs = productCategory === 'NGS';
  const productTypeTitle = useMemo(() => getProductTypeTitle(isPrs), [ isPrs ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (panelSlug) {
      fetchPanel(panelSlug, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchPanel, panelSlug ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: catalogUrls.products },
    { text: 'Catalog', link: catalogUrls.panels },
    ...(!isCommon ? [ { text: productTypeTitle, link: catalogUrls.panelsWithSort(productCategory) } ] : []),
    { text: title },
  ], [ title, isCommon, productCategory, productTypeTitle ]);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(
        <PageHeader
          title={title}
          sidebarItems={sidebarItems}
          breadcrumbItems={breadcrumbItems}
          rightColumn={<PanelMeta panel={panel} />}
        />,
      );
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, sidebarItems, title, isLoading, panel ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={panel.title} />
      <PageWithSidebar sidebarItems={sidebarItems}>
        {isCommon && <CommonPanelContent sectionItems={sectionItems} panel={panel} />}
        {isPrs && <PRSPanelContent sectionItems={sectionItems} panel={panel} />}
        {isNgs && <NGSPanelContent sectionItems={sectionItems} panel={panel} />}
      </PageWithSidebar>
    </>
  );
};

export default Panel;
