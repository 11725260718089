const testingPanelsUrl = 'testing_panels/';
const testingPanelsListUrl = (sortBy: string | null, search: string) => {
  return [
    `${testingPanelsUrl}?`,
    sortBy && `&category=${sortBy}`,
    search && `&search=${search}`,
  ].join('');
};
const testingPanelsDetailUrl = (slug: string) => `${testingPanelsUrl}${slug}/`;
const comparingUrl = (panelSlug: string, subsetSlug: string) => `${testingPanelsUrl}comparing/${panelSlug}/${subsetSlug}/`;

export default { testingPanelsListUrl, testingPanelsDetailUrl, comparingUrl };
