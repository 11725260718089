import 'pages/WelcomePage/components/Action/Action.scss';
import { ProductCategory } from 'api/Products/types';
import { ReactComponent as Arrow } from 'app/assets/Arrow.svg';
import { grayscale50, mainColor, patients500, providers500 } from 'app/consts/colors';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Body, Hero } from 'components/Typography';

import React, { CSSProperties, FocusEvent, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  title: string;
  text: string;
  link: string;
  type: ProductCategory;
}

const Action: React.FC<Props> = ({ title, text, link, type }) => {
  const isPatientVersion = useIsPatientVersion();
  const arrowClasses = cn('action__arrow', { action__arrow_black: type === 'NGS' });
  let style: CSSProperties;

  if (type === 'PRS') {
    style = {
      backgroundColor: !isPatientVersion ? providers500 : patients500,
      color: grayscale50,
    };
  } else {
    style = {
      backgroundColor: 'transparent',
      color: mainColor,
      border: `1px solid ${mainColor}`,
    };
  }

  const textArray = title.split(' ');
  const firstWord = textArray.slice(0, 1).join(' ');
  const boldText = textArray.slice(1, textArray.length).join(' ');

  // prevent animation on page load event
  const onFirstMouseOver = (e: MouseEvent<HTMLAnchorElement> | FocusEvent<HTMLAnchorElement>) => {
    if (!e.currentTarget.classList.contains('animated')) {
      e.currentTarget.classList.add('animated');
    }
  };

  return (
    <Link to={link} className="action" style={style} onMouseOver={onFirstMouseOver} onFocus={onFirstMouseOver}>

      <Hero style={{ lineHeight: '32px' }}>
        {firstWord}&nbsp;
        <Hero style={{ lineHeight: 'inherit' }} variant="M">
          {boldText}
        </Hero>
      </Hero>

      <Body variant="S">{text}</Body>

      <Arrow className={arrowClasses} />

    </Link>
  );
};

export default Action;
