import styles from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/HealthRiskScoring.module.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { SectionItem } from 'app/types/ProductCatalog';
import { Body, Heading } from 'components/Typography';
import { ReactComponent as GeneticAncestry } from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/genetic_ancestry.svg';
import { ReactComponent as PRS } from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/PRS.svg';
import modelDevelopment from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/model_development.png';
import diverseDatasets from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/diverse_datasets.png';
import training from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/training.png';
import improvement from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/improvement.png';
import clinicalApplication from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/assets/clinical_application.png';
import AaPRSItem from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/components/aaPRSItem/aaPRSItem';

import React from 'react';
import cn from 'classnames';

interface Props {
  sectionItems: SectionItem[];
}

const HealthRiskScoring: React.FC<Props> = ({ sectionItems }) => {
  const isPatientVersion = useIsPatientVersion();
  const iconStyles = cn(styles.healthRiskScoring__icon, { [styles.healthRiskScoring__icon_patients]: isPatientVersion });

  const howItem1Text = 'Galatea employs top-tier models from the PGS Catalog, which are widely recognized and validated in the scientific community. Additionally, Galatea leverages proprietary software to train advanced models, ensuring they are cutting-edge and highly accurate.';
  const howItem2Text = 'A key strength of Galatea\'s approach is the use of large, curated datasets that encompass genetic information from diverse ethnic origins. These datasets include patients from all continents, ensuring a broad and inclusive representation of genetic variability.';
  const howItem3Text = 'By integrating data from these diverse populations, Galatea can train models that account for population-specific genetic variations. This process enhances the accuracy and relevance of the risk scores for individuals from different ancestries.';
  const howItem4Text = 'Galatea continuously updates and refines its models by incorporating new data and research findings. This ensures that their aaPRS remain at the forefront of genetic risk prediction technology.';
  const howItem5Text = 'The validated aaPRS are then used to provide clinicians with accurate and personalized risk assessments, supporting more informed decision-making and better patient outcomes.';

  return (
    <div className={styles.healthRiskScoring}>

      <div id={sectionItems[0].id} ref={sectionItems[0].ref}>
        <Heading variant="H3" style={{ marginBottom: 17 }}>What is a Polygenic Risk Score (PRS)?</Heading>

        <Body variant="M">
          A polygenic risk score (PRS) is a number that represents a person&apos;s genetic risk for a particular trait or disease,
          calculated by combining information from multiple genetic variations across the genome.
          It helps predict the likelihood of developing certain conditions and can guide healthcare decisions and research.
        </Body>

        <Heading variant="H3" style={{ marginTop: 25, marginBottom: 13 }}>What is Genetic Ancestry?</Heading>

        <Body variant="M">
          Genetic ancestry is the tracing of human heritage and lineage through the analysis of DNA sequences.
          It involves examining specific regions of the genome that have been inherited from your ancestors to map out your lineage and ethnic backgrounds.
        </Body>

        <Heading variant="H3" style={{ marginTop: 25, marginBottom: 15 }}>What is ancestry-aware PRS (aaPRS)?</Heading>

        <Body variant="M">
          Ancestry-aware Polygenic Risk Scores (PRS) enhance genetic risk prediction by incorporating information about an individual&apos;s ancestry,
          thus improving accuracy and applicability across diverse populations. Most PRS often rely on data from individuals of predominantly European ancestry,
          leading to biases in risk assessment for those from other backgrounds. By including genetic variants that are relevant to
          different ancestral groups and using statistical methods to account for population structure, ancestry-aware PRS offer more personalized
          and precise risk evaluations. This advancement is crucial for personalized medicine and helps ensure that genetic risk
          assessments are inclusive and relevant to all individuals.
        </Body>

        <div className={cn(styles.healthRiskScoring__graphic, { [styles.healthRiskScoring__graphic_patients]: isPatientVersion })}>

          <div>
            <GeneticAncestry className={iconStyles} />
            <Heading variant="H6">Genetic Ancestry</Heading>
          </div>

          <div className={cn(styles.healthRiskScoring__plus, { [styles.healthRiskScoring__plus_patients]: isPatientVersion })} />

          <div className={styles.healthRiskScoring__prs}>
            <PRS className={iconStyles} />
            <Heading variant="H6">Polygenic Risk Score</Heading>
          </div>

          <div className={cn(styles.healthRiskScoring__equal, { [styles.healthRiskScoring__equal_patients]: isPatientVersion })} />

          <div className={styles.healthRiskScoring__aaprs}>aaPRS</div>

        </div>
      </div>

      <div id={sectionItems[1].id} ref={sectionItems[1].ref} style={{ marginTop: 49 }}>
        <Heading variant="H3">Which are the clinical advantages of using aaPRS?</Heading>

        <Heading variant="H5" style={{ marginTop: 22, marginBottom: 20 }}>
          Ancestry-aware Polygenic Risk Scores (aaPRS) offer clinicians significant advantages in genetic risk prediction and patient care.
          Here are some key benefits of using aaPRS:
        </Heading>

        <ul>
          <li>Enhanced Risk Prediction: aaPRS provides more accurate genetic risk assessments by including ancestry-specific genetic variations.</li>
          <li>Bias Reduction: These scores help mitigate biases inherent in PRS models, which often rely on data from predominantly European populations.</li>
          <li>
            Personalized Medicine: Clinicians can use aaPRS to tailor prevention, diagnosis,
            and treatment strategies to the genetic background of individual patients.
          </li>
          <li>
            Health Disparities: aaPRS reveal how genetic risk factors differ across populations,
            aiding in addressing health disparities and informing public health strategies.
          </li>
          <li>
            Informed Clinical Decisions: The comprehensive risk assessments provided by aaPRS support clinicians
            in making more precise and equitable healthcare decisions.
          </li>
          <li>
            Population Health Insights: aaPRS contribute to a better understanding of genetic influences on health across diverse populations,
            guiding effective public health interventions.
          </li>
        </ul>

      </div>

      <div id={sectionItems[2].id} ref={sectionItems[2].ref} style={{ marginTop: 11 }}>
        <Heading variant="H3">How Galatea develops and validates aaPRS?</Heading>

        <div className={styles.healthRiskScoring__howItems}>

          <AaPRSItem icon={modelDevelopment} title="Model Development" text={howItem1Text} backgroundColor="#FAF8F3" />

          <AaPRSItem icon={diverseDatasets} title={'Divers\nDatasets'} text={howItem2Text} backgroundColor="#F9F3FA" />

          <AaPRSItem icon={training} title={'Training\nand Validation'} text={howItem3Text} backgroundColor="#F3FAFA" />

          <AaPRSItem icon={improvement} title="Continuous Improvement" text={howItem4Text} backgroundColor="#F2F8FD" />

          <AaPRSItem icon={clinicalApplication} title="Clinical Application" text={howItem5Text} backgroundColor="#FAF3F3" />

        </div>
      </div>

    </div>
  );
};

export default HealthRiskScoring;
