import { TestingPanel } from 'api/TestingPanels/types';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { SectionItem, SidebarItem, PageWithSidebarContent } from 'app/types/ProductCatalog';

import { useMemo } from 'react';

const DESCRIPTION = 'Description';
const GENES = 'Genes';
const SPECIFICATIONS = 'Specifications';
const ESTIMATE_PRS = 'Estimate Cardiometabolic PRS';
const ANCESTRY = 'Ancestry';
const ASSAY = 'Assay Information';
const GUIDES = 'Physician’s Guide';
const SUBSETS = 'Subsets';

const usePanelContent = (panel: TestingPanel): PageWithSidebarContent => {
  let sectionItems: SectionItem[];
  const inViewRefs = useInViewRefs();
  const doNotDisplayGenes = panel.genesAmount === 0;
  const doNotDisplayGuides = panel.physiciansGuide.length === 0;

  const sidebarItems: SidebarItem[] = useMemo(() => {
    switch (panel.productCategory) {
      case 'PRS':
        return [
          { text: DESCRIPTION, inView: inViewRefs[0].inView },
          { text: SPECIFICATIONS, inView: inViewRefs[1].inView },
          { text: ESTIMATE_PRS, inView: inViewRefs[2].inView },
          { text: ANCESTRY, inView: inViewRefs[3].inView },
        ];
      case 'NGS':
        return [
          { text: DESCRIPTION, inView: inViewRefs[0].inView },
          { text: SPECIFICATIONS, inView: inViewRefs[1].inView },
          { text: ASSAY, inView: inViewRefs[2].inView },
          { text: SUBSETS, inView: inViewRefs[3].inView },
        ];
      default:
        return [
          { text: DESCRIPTION, inView: inViewRefs[0].inView },
          { text: GENES, inView: inViewRefs[1].inView, hide: doNotDisplayGenes },
          { text: SPECIFICATIONS, inView: inViewRefs[2].inView },
          { text: GUIDES, inView: inViewRefs[3].inView, hide: doNotDisplayGuides },
          { text: ASSAY, inView: inViewRefs[4].inView },
          { text: SUBSETS, inView: inViewRefs[5].inView },
        ];
    }
  }, [ inViewRefs, panel.productCategory, doNotDisplayGenes, doNotDisplayGuides ]);

  switch (panel.productCategory) {
    case 'PRS':
      sectionItems = [
        { id: DESCRIPTION, ref: inViewRefs[0].ref },
        { id: SPECIFICATIONS, ref: inViewRefs[1].ref },
        { id: ESTIMATE_PRS, ref: inViewRefs[2].ref },
        { id: ANCESTRY, ref: inViewRefs[3].ref },
      ];
      break;
    case 'NGS':
      sectionItems = [
        { id: DESCRIPTION, ref: inViewRefs[0].ref },
        { id: SPECIFICATIONS, ref: inViewRefs[1].ref },
        { id: ASSAY, ref: inViewRefs[2].ref },
        { id: SUBSETS, ref: inViewRefs[3].ref },
      ];
      break;
    default:
      sectionItems = [
        { id: DESCRIPTION, ref: inViewRefs[0].ref },
        { id: GENES, ref: inViewRefs[1].ref, hide: doNotDisplayGenes },
        { id: SPECIFICATIONS, ref: inViewRefs[2].ref },
        { id: GUIDES, ref: inViewRefs[3].ref, hide: doNotDisplayGuides },
        { id: ASSAY, ref: inViewRefs[4].ref },
        { id: SUBSETS, ref: inViewRefs[5].ref },
      ];
  }

  return {
    sidebarItems,
    sectionItems,
  };
};

export default usePanelContent;
