import styles from 'components/PageWithSidebar/PageWithSidebar.module.scss';
import { SidebarItem } from 'app/types/ProductCatalog';
import Sidebar from 'components/Sidebar/Sidebar';

import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  sidebarItems: SidebarItem[];
  children: ReactNode;
  className?: string;
}

const PageWithSidebar: React.FC<Props> = ({ sidebarItems, children, className }) => {
  return (
    <div className={cn(styles.pageWithSidebar, className)}>

      <div className={styles.pageWithSidebar__sidebar}>
        <Sidebar sidebarItems={sidebarItems} />
      </div>

      <div className={styles.pageWithSidebar__content}>
        {children}
      </div>

    </div>
  );
};

export default PageWithSidebar;
